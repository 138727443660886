import { createAction } from "@reduxjs/toolkit";
import {
  SET_ALL_USERS,
  SET_ALL_HOUSES,
  SET_ALL_ICONS,
  REMOVE_USER,
  SET_ALL_REGIONS,
  ADD_REGION,
  REMOVE_REGION,
  SET_ALL_ADMIN_USERS,
  UPDATE_ADMIN_USER,
  REMOVE_ADMIN_USER,
  ADD_ADMIN_USER,
  CHANGE_FEATURE_ITEM_SEQUENCE,
  SET_FEATURE_OPTIONS,
  UPDATE_FEATURE_OPTION,
  ADD_FEATURE_OPTION,
  REMOVE_FEATURE_OPTION,
  CHANGE_HOUSE_FEATURE_ITEM_SEQUENCE,
  UPDATE_FEATURE_CATEGORY,
  ADD_HOUSE_FEATURE_OPTION,
  UPDATE_HOUSE_FEATURE_OPTION,
  REMOVE_HOUSE_FEATURE_OPTION,
  UPDATE_ICON,
  UPDATE_CATEGORY_ICON,
  ADD_USER,
  SET_DASHBOARD_DATA
} from "../constants/system";

/* General */
const setDashboardData = createAction(SET_DASHBOARD_DATA);

/* Users */
const setAllUsers = createAction(SET_ALL_USERS);
const removeUser = createAction(REMOVE_USER);
const addUser = createAction(ADD_USER);

/* Admin Users */
const setAllAdminUsers = createAction(SET_ALL_ADMIN_USERS);
const updateAdminUser = createAction(UPDATE_ADMIN_USER);
const removeAdminUser = createAction(REMOVE_ADMIN_USER);
const addAdminUser = createAction(ADD_ADMIN_USER);

/* Houses */
const setAllHouses = createAction(SET_ALL_HOUSES);

/* Regions */
const setAllRegions = createAction(SET_ALL_REGIONS);
const addRegion = createAction(ADD_REGION);
const removeRegion = createAction(REMOVE_REGION);

/* Icons */
const setAllIcons = createAction(SET_ALL_ICONS);
const updateIcon = createAction(UPDATE_ICON);
const updateCategoryIcon = createAction(UPDATE_CATEGORY_ICON);

/* Feature Options/Feature Category Options */
const setFeatureOptions = createAction(SET_FEATURE_OPTIONS);
const updateFeatureOption = createAction(UPDATE_FEATURE_OPTION);
const addFeatureOption = createAction(ADD_FEATURE_OPTION);
const changeFeatureItemSequence = createAction(CHANGE_FEATURE_ITEM_SEQUENCE);
const removeFeatureOption = createAction(REMOVE_FEATURE_OPTION);
const updateFeatureCategory = createAction(UPDATE_FEATURE_CATEGORY);

/* House Feature Options */
const addHouseFeatureOption = createAction(ADD_HOUSE_FEATURE_OPTION);
const updateHouseFeatureOption = createAction(UPDATE_HOUSE_FEATURE_OPTION);
const removeHouseFeatureOption = createAction(REMOVE_HOUSE_FEATURE_OPTION);
const changeHouseFeatureItemSequence = createAction(
  CHANGE_HOUSE_FEATURE_ITEM_SEQUENCE
);

export {
  setAllUsers,
  setAllHouses,
  setAllRegions,
  removeUser,
  addRegion,
  removeRegion,
  setAllAdminUsers,
  updateAdminUser,
  removeAdminUser,
  addAdminUser,
  changeFeatureItemSequence,
  setFeatureOptions,
  updateFeatureOption,
  addFeatureOption,
  removeFeatureOption,
  changeHouseFeatureItemSequence,
  updateFeatureCategory,
  addHouseFeatureOption,
  updateHouseFeatureOption,
  removeHouseFeatureOption,
  setAllIcons,
  updateIcon,
  updateCategoryIcon,
  addUser,
  setDashboardData
};
