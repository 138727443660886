import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { FormWrapper } from "components/FormWrapper";
import { HCButton } from "components/HCButton";
import { makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import { URLField } from "components/URLField";

const useStyles = makeStyles(theme => ({
  root: {},
  submit: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  }
}));

const LinkFormValidationSchema = Yup.object().shape({
  url: Yup.string().url("Invalid URL.").required("URL is required.")
});

function LinkForm({ onSubmit, url }) {
  const classes = useStyles();

  const initialValues = {
    url: url || ""
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={LinkFormValidationSchema}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <FormWrapper onSubmit={handleSubmit} className={classes.root}>
          <URLField
            full
            id="url"
            name="url"
            type="text"
            variant="outlined"
            label="House Owner Website"
          />
          <HCButton
            type="submit"
            variant="contained"
            color="primary"
            className={{ root: classes.submit }}
            loading={isSubmitting}
            disabled={JSON.stringify(initialValues) === JSON.stringify(values)}
          >
            Update URL
          </HCButton>
        </FormWrapper>
      )}
    </Formik>
  );
}

LinkForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  url: PropTypes.string
};

export default LinkForm;
