import React, { useEffect, useState } from "react";
import { PanelProvider } from "components/Panel";
import useRegionDispatch from "hooks/useRegionDispatch";
import { BackButton } from "components/BackButton";
import { DashboardPageHeader } from "components/Dashboard";
import { SkeletonWrapper } from "components/SkeletonWrapper";
import RoutedTabbedSectionSkeleton from "components/TabbedSection/RoutedTabbedSectionSkeleton";
import { RoutedTabbedSection } from "components/TabbedSection";
import useRegionState from "hooks/useRegionState";
import { Typography, makeStyles } from "@material-ui/core";
import { Image } from "components/Image";
import { useRouteMatch } from "react-router-dom";
import PoiArchiveTable from "./components/PoiArchiveTable";

const useStyles = makeStyles(theme => ({
  mainTabImage: {
    width: 138,
    height: 103
  },
  smallTabImage: {
    width: 100
  }
}));

function RegionArchived() {
  const match = useRouteMatch();
  const classes = useStyles();

  const { getRegionArchived } = useRegionDispatch();
  const { archived, region } = useRegionState();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    if (!region.id) return;
    getRegionArchived(region.id)
      .then(() => {
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setError(err);
        setLoading(false);
      });
  }, [getRegionArchived, region.id]);

  if (error) {
    return (
      <Typography variant="body2" color="textSecondary">
        There was an error getting archived POIs for region.
      </Typography>
    );
  }

  return (
    <PanelProvider>
      {region && (
        <BackButton path={`/dashboard/regions/${region.id}`}>
          Back to {region.name}
        </BackButton>
      )}
      <DashboardPageHeader subHeader={region ? region.name : "..."}>
        Archived POIs
      </DashboardPageHeader>
      <SkeletonWrapper skeleton={RoutedTabbedSectionSkeleton} loading={loading}>
        {archived && (
          <RoutedTabbedSection
            tabs={[
              archived.shop,
              archived.dine,
              archived.play,
              archived.spa,
              archived.guest_services
            ].map((cat, i) => ({
              icon: (
                <Image
                  src={cat.icon}
                  alt={cat.name}
                  classes={{ root: classes.mainTabImage }}
                />
              ),
              label: cat.name,
              path: `${match.url}/${cat.key}`,
              index: i
            }))}
          >
            <PoiArchiveTable
              pois={archived.shop}
              stateKey="archived"
              parentCategory="shop"
              isPeople={false}
            />
            <PoiArchiveTable
              pois={archived.dine}
              stateKey="archived"
              parentCategory="dine"
              isPeople={false}
            />
            <RoutedTabbedSection
              tabs={Object.keys(archived.play.values)
                .sort((a, b) =>
                  archived.play.values[a].id > archived.play.values[b].id
                    ? 1
                    : archived.play.values[b].id > archived.play.values[a].id
                    ? -1
                    : 0
                )
                .map((subCatKey, i) => ({
                  icon: (
                    <Image
                      src={archived.play.values[subCatKey].icon}
                      alt={archived.play.values[subCatKey].name}
                      classes={{ root: classes.smallTabImage }}
                    />
                  ),
                  label: archived.play.values[subCatKey].name,
                  path: `${match.url}/play/${archived.play.values[subCatKey].key}`,
                  index: i
                }))}
            >
              {Object.keys(archived.play.values)
                .sort((a, b) =>
                  archived.play.values[a].id > archived.play.values[b].id
                    ? 1
                    : archived.play.values[b].id > archived.play.values[a].id
                    ? -1
                    : 0
                )
                .map((subCatKey, i) => (
                  <PoiArchiveTable
                    key={archived.play.values[subCatKey].id}
                    pois={archived.play.values[subCatKey]}
                    stateKey="archived"
                    parentCategory={"play"}
                  />
                ))}
            </RoutedTabbedSection>

            <PoiArchiveTable
              pois={archived.spa}
              stateKey="archived"
              parentCategory="spa"
            />
            <RoutedTabbedSection
              tabs={Object.keys(archived.guest_services.values)
                .sort((a, b) =>
                  archived.guest_services.values[a].id >
                  archived.guest_services.values[b].id
                    ? 1
                    : archived.guest_services.values[b].id >
                      archived.guest_services.values[a].id
                    ? -1
                    : 0
                )
                .map((subCatKey, i) => ({
                  icon: (
                    <Image
                      src={archived.guest_services.values[subCatKey].icon}
                      alt={archived.guest_services.values[subCatKey].name}
                      classes={{ root: classes.smallTabImage }}
                    />
                  ),
                  label: archived.guest_services.values[subCatKey].name,
                  path: `${match.url}/guestServices/${archived.guest_services.values[subCatKey].key}`,
                  index: i
                }))}
            >
              {Object.keys(archived.guest_services.values)
                .sort((a, b) =>
                  archived.guest_services.values[a].id >
                  archived.guest_services.values[b].id
                    ? 1
                    : archived.guest_services.values[b].id >
                      archived.guest_services.values[a].id
                    ? -1
                    : 0
                )
                .map((subCatKey, i) => (
                  <PoiArchiveTable
                    key={archived.guest_services.values[subCatKey].id}
                    pois={archived.guest_services.values[subCatKey]}
                    stateKey="archived"
                    isPeople={subCatKey === "rentals" ? false : true}
                    parentCategory={"guest_services"}
                  />
                ))}
            </RoutedTabbedSection>
          </RoutedTabbedSection>
        )}
      </SkeletonWrapper>
    </PanelProvider>
  );
}

export default RegionArchived;
