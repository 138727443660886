import React from "react";
import { DashboardPage } from "../../components/Dashboard";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { Helmet } from "react-helmet";

import UsersIndex from "./components/UsersIndex";
import UsersNew from "./components/UsersNew";
import UsersEdit from "./components/UsersEdit";

/**
 * Users: display user views
 */

function Users() {
  const match = useRouteMatch();

  return (
    <DashboardPage>
      <Helmet>
        <title>Users - Home Concierge Super Admin</title>
      </Helmet>
      <Switch>
        <Route path={`${match.url}`} exact component={UsersIndex} />
        <Route path={`${match.url}/new`} exact component={UsersNew} />
        <Route path={`${match.url}/:id/edit`} exact component={UsersEdit} />
      </Switch>
    </DashboardPage>
  );
}

Users.propTypes = {};

export default Users;
