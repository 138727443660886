import React from "react";
import PropTypes from "prop-types";

import { TextField, makeStyles } from "@material-ui/core";
import { useField } from "formik";

const useStyles = makeStyles((theme) => ({
  input: (props) => ({
    width: "auto",
    flexBasis: props.half ? `calc(50% - ${theme.spacing(1) * 2}px)` : "100%",
    margin: theme.spacing(1),
  }),
}));

function InputField({ half, full, ...props }) {
  const [field, meta] = useField(props);
  const classes = useStyles({ half, full });
  const showError = Boolean(meta.error && meta.touched);

  return (
    <TextField
      className={classes.input}
      error={showError}
      helperText={showError ? meta.error : ""}
      {...field}
      {...props}
    />
  );
}

InputField.propTypes = {
  half: PropTypes.bool,
  full: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

InputField.muiName = TextField.muiName;

export default InputField;
