import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { BackButton } from "components/BackButton";
import { DashboardPageHeader } from "components/Dashboard";
import useRegionState from "hooks/useRegionState";
import { SkeletonWrapper } from "components/SkeletonWrapper";
import RoutedTabbedSectionSkeleton from "components/TabbedSection/RoutedTabbedSectionSkeleton";
import useRegionDispatch from "hooks/useRegionDispatch";
import useAlertsDispatch from "hooks/useAlertsDispatch";
import { Typography, makeStyles } from "@material-ui/core";
import { Image } from "components/Image";
import { RoutedTabbedSection } from "components/TabbedSection";
import { DestSelector } from "components/DestSelector";
import { FeatureEditor } from "components/FeatureEditor";

const useStyles = makeStyles((theme) => ({
  mainTabImage: {
    width: 138,
    height: 103,
  },
}));

function RegionEmergency({ match }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const { region, emergency } = useRegionState();
  const {
    getRegionEmergency,
    deleteDestination,
    updateDestinations,
    updateAdminFeature,
    deleteAdminFeaturePage,
  } = useRegionDispatch();

  const { addAlert } = useAlertsDispatch();

  useEffect(() => {
    if (!region.id) return;
    getRegionEmergency(region.id)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setError(err);
        addAlert({
          intent: "error",
          text:
            "There was an error fetching emergency options for this region.",
        });
      });
  }, [addAlert, getRegionEmergency, region.id]);

  const handleDestDelete = (category) => (id) => {
    deleteDestination(region.id, "emergency", category, id).catch((error) => {
      addAlert({
        intent: "error",
        text: "There was an error deleting the destination.",
      });
    });
  };

  const handleDestSubmit = (category) => ({ destinations }, actions) => {
    updateDestinations(
      region.id,
      "emergency",
      category,
      destinations.map((destination) => ({
        ...destination.address,
        id: destination.id,
      })),
      emergency[category].id
    )
      .then(() => {
        addAlert({
          intent: "success",
          text: `Succesfully updated destinations for "${emergency[category].name}"`,
        });
        actions.setSubmitting(false);
      })
      .catch((error) => {
        addAlert({
          intent: "error",
          text: `There was an error updating destinations for "${emergency[category].name}"`,
        });
        actions.setSubmitting(false);
      });
  };

  const handleFeatureUpdate = ({ features, id }, actions) => {
    updateAdminFeature(region.id, "emergency", "warning_siren", features, id)
      .then(() => {
        addAlert({
          intent: "success",
          text: "Successfully updated feature pages.",
        });
        actions.setSubmitting(false);
      })
      .catch((error) => {
        addAlert({
          intent: "error",
          text: "There was an error while updating the feature.",
        });
        console.error(error);
        actions.setSubmitting(false);
      });
  };

  const handleFeaturePageDelete = (id) => {
    deleteAdminFeaturePage(
      region.id,
      "emergency",
      "warning_siren",
      emergency.warning_siren.id,
      id
    )
      .then(() => {
        addAlert({
          intent: "success",
          text: "Successfully deleted feature page.",
        });
      })
      .catch((error) => {
        console.error(error);
        addAlert({
          intent: "error",
          text: "There was an error while deleting feature page.",
        });
      });
  };

  if (error)
    return (
      <Typography variant="body1" color="textSecondary">
        There was an error while fetching emergency options.
      </Typography>
    );

  return (
    <div>
      {region && (
        <BackButton path={`/dashboard/regions/${region.id}`}>
          Back to {region.name}
        </BackButton>
      )}
      <DashboardPageHeader subHeader={region ? region.name : "..."}>
        Emergency
      </DashboardPageHeader>

      <SkeletonWrapper loading={loading} skeleton={RoutedTabbedSectionSkeleton}>
        {emergency && (
          <RoutedTabbedSection
            tabs={[
              emergency.urgent_care,
              emergency.hospitals,
              emergency.warning_siren,
            ].map((cat, i) => ({
              icon: (
                <Image
                  src={cat.icon}
                  alt={cat.name}
                  classes={{ root: classes.mainTabImage }}
                />
              ),
              label: cat.name,
              path: `${match.url}/${cat.key}`,
              index: i,
            }))}
          >
            <DestSelector
              multi
              destinations={emergency.urgent_care.items}
              onSubmit={handleDestSubmit("urgent_care")}
              onDelete={handleDestDelete("urgent_care")}
            />

            <DestSelector
              multi
              destinations={emergency.hospitals.items}
              onSubmit={handleDestSubmit("hospitals")}
              onDelete={handleDestDelete("hospitals")}
            />

            <FeatureEditor
              feature={emergency.warning_siren}
              onSubmit={handleFeatureUpdate}
              onDelete={handleFeaturePageDelete}
            />
          </RoutedTabbedSection>
        )}
      </SkeletonWrapper>
    </div>
  );
}

RegionEmergency.propTypes = {
  match: PropTypes.object.isRequired,
};

export default RegionEmergency;
