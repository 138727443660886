import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";

function MaskedPhoneInput({ inputRef, ...rest }) {
  return (
    <MaskedInput
      {...rest}
      ref={ref => inputRef(ref ? ref.inputElement : null)}
      mask={rawValue => [
        "1",
        " ",
        "(",
        /[2-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      placeholderChar={"\u2000"}
      showMask={false}
    />
  );
}

MaskedPhoneInput.propTypes = {
  inputRef: PropTypes.func.isRequired
};

export default MaskedPhoneInput;
