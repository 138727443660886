import React from "react";
import { Button, makeStyles, CircularProgress } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: "auto",
    position: "relative"
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

function HCButton({ className, loading, children, disabled, ...rest }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.wrapper, className && className.root)}>
      <Button
        disabled={loading || disabled}
        className={clsx(className && className.button)}
        {...rest}
      >
        {children}
      </Button>
      {loading && <CircularProgress size={24} className={classes.progress} />}
    </div>
  );
}

HCButton.propTypes = {};

export default HCButton;
