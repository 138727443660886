import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Container, Typography, Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    paddingTop: 35,
  },
}));

function GeneralErrorComponent({ message }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <Typography gutterBottom variant="body2" color="textSecondary">
          There was an unknown error that occured while loading the page.
        </Typography>
        <Link href="/">Go to home page</Link>
      </Container>
    </div>
  );
}

GeneralErrorComponent.propTypes = {
  message: PropTypes.string,
};

export default GeneralErrorComponent;
