import { useSelector } from "react-redux";

const useSystemState = () => {
  return {
    users: useSelector(state => state.system.users),
    houses: useSelector(state => state.system.houses),
    regions: useSelector(state => state.system.regions),
    adminUsers: useSelector(state => state.system.adminUsers),
    features: useSelector(state => state.system.features),
    icons: useSelector(state => state.system.icons),
    dashboard: useSelector(state => state.system.dashboard)
  };
};

export default useSystemState;
