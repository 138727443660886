import { configureStore } from "@reduxjs/toolkit";
import userReducer from "reducers/user";
import loadingReducer from "reducers/loading";
import alertsReducer from "reducers/alerts";
import systemReducer from "reducers/system";
import regionReducer from "reducers/region";

const store = configureStore({
  reducer: {
    user: userReducer,
    loading: loadingReducer,
    alerts: alertsReducer,
    system: systemReducer,
    region: regionReducer,
  },
});

export default store;
