import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    height: "60vh",
  },
  table: {
    height: "60vh",
    tableLayout: "fixed",
  },
  cell: {
    height: "20px",

    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
