import React from "react";
import PropTypes from "prop-types";

import { Typography, Button, makeStyles } from "@material-ui/core";

import { HCModal } from "components/HCModal";
import { HCButton } from "components/HCButton";
import { FormWrapper } from "components/FormWrapper";

const useStyles = makeStyles((theme) => ({
  ctaWrapper: {
    flexBasis: "100%",
    display: "flex",
  },
}));

function RegionDeleteModal({ onClose, onConfirm, open, loading, data }) {
  const classes = useStyles();

  if (!data) return;
  return (
    <HCModal handleClose={onClose} open={open}>
      <FormWrapper onSubmit={onConfirm}>
        <Typography variant="h4" gutterBottom>
          Are you sure you want to delete the region{" "}
          <strong>"{data.name}"</strong>?
        </Typography>
        <Typography paragraph>
          This will disable this region and remove the{" "}
          <strong>{data.property_count}</strong> homes that are in this region.
        </Typography>
        <div className={classes.ctaWrapper}>
          <HCButton
            loading={loading}
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginRight: "10px", display: "block" }}
          >
            Yes, I am Sure
          </HCButton>
          <Button variant="contained" color="secondary" onClick={onClose}>
            No, go back
          </Button>
        </div>
      </FormWrapper>
    </HCModal>
  );
}

RegionDeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    property_count: PropTypes.number.isRequired,
    timezone: PropTypes.string,
  }),
};

export default RegionDeleteModal;
