import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";
import { CardLink } from "components/CardLink";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function RegionCardLink({ path, title, icon, children }) {
  const classes = useStyles();
  return (
    <CardLink path={path} className={classes.root}>
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {children}
      </Typography>
    </CardLink>
  );
}

RegionCardLink.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
};

export default RegionCardLink;
