import axios from "axios";
import localforage from "localforage";
import { logoutUser } from "../../actions/user";
import store from "../../store";
import { addAlert } from "../../actions/alerts";
class APIWrapper {
  constructor() {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    this.store = store;

    axios.interceptors.response.use(null, error => {
      if (!error || !error.response) {
        return Promise.reject(error);
      }

      if (error.response.data) {
        if (
          error.response.data.error === "token_invalid" ||
          error.response.data.error === "user_not_found" ||
          error.response.status === 401
        ) {
          this.clearStorage().then(() => {
            this.store.dispatch(
              addAlert({
                id: 999,
                intent: "error",
                text: "Please log back in to the application to use it."
              })
            );
            this.store.dispatch(logoutUser());
          });
        }
      }
      return Promise.reject(error);
    });
  }

  setAuth(token) {
    axios.defaults.headers.common["Authorization"] = token;
  }

  async setStorage({ token, ...data }) {
    try {
      await localforage.setItem("token", token);
      await localforage.setItem("userData", data);
      return true;
    } catch (error) {
      throw error;
    }
  }
  async checkStorage() {
    try {
      const token = await localforage.getItem("token");
      const userData = await localforage.getItem("userData");
      if (!token || !userData) {
        throw new Error("No stored token or user data.");
      }
      this.setAuth(token);

      return { ...userData, token };
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async clearStorage() {
    try {
      await localforage.removeItem("token");
      await localforage.removeItem("userData");
      return true;
    } catch (error) {
      throw error;
    }
  }

  async loginUser(email, password) {
    try {
      const { data } = await axios.post("/user/login", { email, password });

      // user must be role of type admin to proceed
      if (data.role !== "admin") {
        throw new Error('User must have role of "Admin".');
      }

      await this.setStorage(data);

      this.setAuth(data.token);

      return data;
    } catch (error) {
      throw error;
    }
  }

  async logoutUser() {
    try {
      const { data } = await axios.get("/user/logout");
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllUsers() {
    try {
      const { data } = await axios.get("/admin/getAllUsers");
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllAdminUsers() {
    try {
      const { data } = await axios.get("/admin/getAdminUsers");
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllHouses() {
    try {
      const { data } = await axios.get("/admin/getAllHouses");
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllRegions() {
    try {
      const { data } = await axios.get("/admin/region");
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getRegion(regionId) {
    try {
      const { data } = await axios.get(`/admin/region/${regionId}`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getLinks(regionId) {
    try {
      const { data } = await axios.get(`/admin/region/${regionId}/links`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateRegion(regionId, { name, description, timezone }) {
    try {
      const { data } = await axios.put("/admin/updateRegion", {
        regionId,
        name,
        description,
        timezone
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async mergeRegion(id, postData) {
	try {
	  postData.id = id;
	  const { data } = await axios.post("/admin/mergeRegion", postData);
	  return data;
	} catch (error) {
	  throw error;
	}
  }

  async updateLink(regionId, key, url) {
    try {
      const { data } = await axios.put(
        `/admin/region/${regionId}/links/${key}`,
        {
          url
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getRegionHomes(regionId) {
    try {
      const { data } = await axios.get(`/admin/region/${regionId}/houses`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateRegionPoiSequences(regionId, featureId, items) {
    try {
      const { data } = await axios.put(
        `/admin/region/${regionId}/pois/sequences`,
        {
          items
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateAirportWebsite(regionId, website) {
    try {
      const { data } = await axios.put(`/admin/region/${regionId}/airport`, {
        website
      });

      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateAirportDirections(regionId, destination) {
    try {
      const { data } = await axios.put(
        `/admin/region/${regionId}/airport-directions`,
        {
          destination
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateBasicPoi(regionId, poiId, values) {
    const { name, website, image } = values;

    const formData = new FormData();

    formData.append("name", name);
    formData.append("website", website);

    // if icon is file, append it to form data. Otherwise, dont update the icon
    if (typeof image === "object" && image instanceof File && image !== null) {
      formData.append("image", image);
    }

    try {
      const { data } = await axios.post(
        `/admin/region/${regionId}/poi-basic/${poiId}`,
        formData
      );
      return data;
    } catch (err) {
      throw err;
    }
  }

  async getRegionHosts(regionId) {
    try {
      const { data } = await axios.get(`/admin/region/${regionId}/hosts`);
      return data;
    } catch (err) {
      throw err;
    }
  }

  async getAllIcons() {
    try {
      const { data } = await axios.get("/admin/icons");
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getUser(id) {
    try {
      const { data } = await axios.get("/admin/getUser", {
        params: { userId: id }
      });

      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteUser(id) {
    try {
      const { data } = await axios.delete("/admin/deleteUser", {
        params: {
          user_id: id
        }
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getHouse(id) {
    try {
      const { data } = await axios.get("/admin/getHouse", {
        params: { houseId: id }
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getHouseHosts(id) {
    try {
      const { data } = await axios.get("/admin/getHouseHosts", {
        params: { houseId: id }
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateHouse(values) {
    try {
      const { data } = await axios.put("/admin/updateHouse", {
        houseId: values.id,
        name: values.houseName,
        phoneNumber: values.homeOwnerPhone,
        email: values.homeOwnerEmail,
        website: values.homeOwnerWebsite,
        houseOwnerName: values.homeOwnerName,
        houseAddress: values.houseAddress.formattedAddress,
        street: values.houseAddress.street,
        city: values.houseAddress.city,
        state: values.houseAddress.street,
        country: values.houseAddress.country,
        zip: values.houseAddress.zip
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateUserData(userData) {
    try {
      const { data } = await axios.put("/admin/updateAccountOwner", {
        userId: userData.id,
        accountStatus:
          userData.accountStatus === "active"
            ? 1
            : userData.accountStatus === "inactive"
            ? 0
            : 0,
        firstName: userData.firstName,
        lastName: userData.lastName,
        phoneNumber: userData.phone
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateAdminUser(userData) {
    try {
      const { data } = await axios.put("/admin/updateAdminUser", {
        userId: userData.id,
        accountStatus:
          userData.accountStatus === "active"
            ? 1
            : userData.accountStatus === "inactive"
            ? 0
            : 0,
        firstName: userData.firstName,
        lastName: userData.lastName,
        phoneNumber: userData.phone
      });

      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateIcon(iconId, icon) {
    const formData = new FormData();

    // if icon is file, append it to form data. Otherwise, dont update the icon
    if (typeof icon === "object" && icon instanceof File && icon !== null) {
      formData.append("icon", icon);
    }
    try {
      const { data } = await axios.post(
        `/admin/icons/icon/${iconId}`,
        formData
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateCategoryIcon(categoryId, icon) {
    const formData = new FormData();

    if (typeof icon === "object" && icon instanceof File && icon !== null) {
      formData.append("icon", icon);
    }

    try {
      const { data } = await axios.post(
        `/admin/icons/category/${categoryId}`,
        formData
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createAdminUser({ email, password, firstName, lastName, phoneNumber }) {
    try {
      const { data } = await axios.post("/admin/createUser", {
        email,
        password,
        firstName,
        lastName,
        phoneNumber
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
  async createRegion(name, description, tz) {
    try {
      const { data } = await axios.post("/admin/createRegion", {
        name,
        description,
        timezone: tz
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async setPOIDefault(regionId, featureId, poiId, isDefault) {
    try {
      const { data } = await axios.post(
        `/admin/region/${regionId}/pois/${featureId}/default`,
        {
          id: poiId,
          isDefault
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updatePeoplePoi(regionId, poiId, poiData) {
    const { image, name, description, website, phone, email } = poiData;

    const formData = new FormData();

    formData.append("name", name);
    formData.append("description", description);
    formData.append("website", website);
    formData.append("phone", phone);
    formData.append("email", email);

    // if icon is file, append it to form data. Otherwise, dont update the icon
    if (typeof image === "object" && image instanceof File && image !== null) {
      formData.append("image", image);
    }

    try {
      const { data } = await axios.post(
        `/admin/region/${regionId}/people-pois/${poiId}`,
        formData
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updatePoiArchive(regionId, poiId, isArchived) {
    try {
      const { data } = await axios.put(
        `/admin/region/${regionId}/pois/${poiId}/archive`,
        {
          is_archived: isArchived
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createGiftUser({
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
    confirmPassword,
    duration
  }) {
    try {
      const { data } = await axios.post(`/admin/user/gift`, {
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        confirmPassword,
        duration
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updatePoi(regionId, poiId, poiData) {
    const {
      image,
      name,
      description,
      address,
      website,
      phone,
      reservations,
      menu,
      directory,
      is_approved,
      is_archived,
      email
    } = poiData;
    const formData = new FormData();

    if (name) formData.append("name", name);
    if (description) formData.append("description", description);
    if (website) formData.append("website", website);
    if (phone) formData.append("phone", phone);
    if (email) formData.append("email", email);

    if (is_approved) formData.append("is_approved", is_approved);
    if (is_archived) formData.append("is_archived", is_archived);
    if (reservations) formData.append("reservation_link", reservations);
    if (menu) formData.append("menu_link", menu);
    if (directory) formData.append("directory_link", directory);

    if (address) {
      if (address.address_line_1)
        formData.append("address_line_1", address.address_line_1);
      if (address.city) formData.append("city", address.city);
      if (address.state) formData.append("state", address.state);
      if (address.country) formData.append("country", address.country);
      if (address.zip) formData.append("zip", address.zip);
      if (address.lat) formData.append("lat", address.lat);
      if (address.lng) formData.append("lng", address.lng);
    }

    // if icon is file, append it to form data. Otherwise, dont update the icon
    if (
      image &&
      typeof image === "object" &&
      image instanceof File &&
      image !== null
    ) {
      formData.append("image", image);
    }

    try {
      const { data } = await axios.post(
        `/admin/region/${regionId}/pois/${poiId}`,
        formData
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async approvePoi(regionId, poiId) {
    try {
      const { data } = await axios.put(
        `/admin/region/${regionId}/pois/${poiId}/approve`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateMoreLink(regionId, featureId, link) {
    try {
      const { data } = await axios.put(
        `/admin/region/${regionId}/pois/more_link/${featureId}`,
        {
          link
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getRegionTvOffice(regionId) {
    try {
      const { data } = await axios.get(`/admin/region/${regionId}/tvOffice`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getRegionEmergency(regionId) {
    try {
      const { data } = await axios.get(`/admin/region/${regionId}/emergency`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createPeoplePoi(regionId, featureId, poiData) {
    const { image, name, description, website, phone, email } = poiData;

    const formData = new FormData();

    if (name) formData.append("name", name);
    if (description) formData.append("description", description);
    formData.append("feature_id", featureId);
    // if icon is file, append it to form data. Otherwise, dont update the icon
    if (typeof image === "object" && image instanceof File && image !== null) {
      formData.append("image", image);
    }

    if (website) formData.append("website", website);
    if (phone) formData.append("phone", phone);
    if (email) formData.append("email", email);

    try {
      const { data } = await axios.post(
        `/admin/region/${regionId}/people-pois`,
        formData
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createPoi(regionId, featureId, poiData) {
    const {
      image,
      name,
      description,
      address,
      website,
      phone,
      reservations,
      menu,
      directory
    } = poiData;
    const formData = new FormData();

    if (name) formData.append("name", name);
    if (description) formData.append("description", description);
    formData.append("feature_id", featureId);
    // if icon is file, append it to form data. Otherwise, dont update the icon
    if (typeof image === "object" && image instanceof File && image !== null) {
      formData.append("image", image);
    }

    if (website) formData.append("website", website);
    if (phone) formData.append("phone", phone);
    if (reservations) formData.append("reservation_link", reservations);
    if (menu) formData.append("menu_link", menu);
    if (directory) formData.append("directory_link", directory);
    if (address) {
      if (address.address_line_1)
        formData.append("address_line_1", address.address_line_1);

      if (address.city) formData.append("city", address.city);

      if (address.state) formData.append("state", address.state);
      if (address.country) formData.append("country", address.country);
      if (address.zip) formData.append("zip", address.zip);
      if (address.lat) formData.append("lat", address.lat);
      if (address.lng) formData.append("lng", address.lng);
    }

    try {
      const { data } = await axios.post(
        `/admin/region/${regionId}/pois`,
        formData
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateMultiImage(regionId, featureId, { desktop, mobile }) {
    const formData = new FormData();

    // if mobile/desktop are file objects, append to upload

    if (
      typeof desktop === "object" &&
      desktop instanceof File &&
      desktop !== null
    ) {
      formData.append("desktop", desktop);
    }

    if (
      typeof mobile === "object" &&
      mobile instanceof File &&
      mobile !== null
    ) {
      formData.append("mobile", mobile);
    }

    try {
      const { data } = await axios.post(
        `/admin/region/${regionId}/image/${featureId}`,
        formData
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateDestinations(regionId, destinations, featureId) {
    try {
      const { data } = await axios.put(
        `/admin/region/${regionId}/destinations`,
        {
          destinations,
          feature_id: featureId
        }
      );

      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteDestination(regionId, destinationId) {
    try {
      const { data } = await axios.delete(
        `/admin/region/${regionId}/destinations/${destinationId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deletePoi(regionId, poiId) {
    try {
      const { data } = await axios.delete(
        `/admin/region/${regionId}/pois/${poiId}`
      );

      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteRegion(id) {
    try {
      const { data } = await axios.delete("/admin/deleteRegion", {
        params: { regionId: id }
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getRegionPois(regionId) {
    try {
      const { data } = await axios.get(`/admin/region/${regionId}/pois`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getFeatureOptions() {
    try {
      const { data } = await axios.get("/admin/features/options");
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateFeatureOption(
    category,
    id,
    { name, description, defaultText, icon }
  ) {
    try {
      const formData = new FormData();

      formData.append("name", name);
      formData.append("description", description);
      formData.append("defaultText", defaultText);

      // if icon is file, append it to form data. Otherwise, dont update the icon
      if (typeof icon === "object" && icon instanceof File && icon !== null) {
        formData.append("icon", icon);
      }

      const { data } = await axios.post(
        `/admin/features/options/${category}/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getRegionGeneral(regionId) {
    try {
      const { data } = await axios.get(`/admin/region/${regionId}/tos`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getRegionAirportDetails(regionId) {
    try {
      const { data } = await axios.get(
        `/admin/region/${regionId}/airport-details`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateRegionGeneral(regionId, generalData) {
    try {
      const { data } = await axios.put(
        `/admin/region/${regionId}/tos`,
        generalData
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateFeatureCategory(subCategoryId, { name, description, icon }) {
    try {
      const formData = new FormData();
      formData.append("id", subCategoryId);
      formData.append("name", name);
      formData.append("description", description);

      // if icon is file, append it to form data. Otherwise, dont update the icon
      if (typeof icon === "object" && icon instanceof File && icon !== null) {
        formData.append("icon", icon);
      }

      const { data } = await axios.post(
        `admin/features/options/updateHouseFeatureCategory`,
        formData
      );

      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateHouseFeatureSequences(category_id, options) {
    try {
      const { data } = await axios.put(
        "/admin/features/options/updateHouseFeatureSequences",
        {
          category_id,
          options
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateFeatureSequences(category, options) {
    try {
      const { data } = await axios.put(
        "/admin/features/options/updateSequences",
        {
          category,
          options
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createFeatureOption(category, values) {
    const { icon, name, description, defaultText } = values;

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("defaultText", defaultText);
    formData.append("icon", icon);

    try {
      const { data } = await axios.post(
        `/admin/features/options/${category}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createHouseFeatureOption(inHouseFeatureId, values) {
    const { icon, name, description, defaultText } = values;

    const formData = new FormData();
    formData.append("inHouseFeatureId", inHouseFeatureId);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("defaultText", defaultText);
    formData.append("icon", icon);

    try {
      const { data } = await axios.post(
        `/admin/features/options/houseFeatures`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateHouseFeatureOption(id, { name, description, defaultText, icon }) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("defaultText", defaultText);
    // if icon is file, append it to form data. Otherwise, dont update the icon
    if (typeof icon === "object" && icon instanceof File && icon !== null) {
      formData.append("icon", icon);
    }

    try {
      const { data } = await axios.post(
        `/admin/features/options/houseFeatures/${id}`,
        formData
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteHouseFeatureOption(id) {
    try {
      const { data } = await axios.delete(
        `/admin/features/options/houseFeatures/${id}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteFeatureOption(category, id) {
    try {
      const { data } = await axios.delete(
        `/admin/features/options/${category}/${id}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateAdminFeature(regionId, adminFeatureId, pages) {
    const formData = new FormData();

    pages.forEach((page, i) => {
      formData.append(`id[${i}]`, page.id || null);
      formData.append(`description[${i}]`, page.description);
      formData.append(`image[${i}]`, page.image);
    });

    try {
      const { data } = await axios.post(
        `/admin/region/${regionId}/adminfeature/${adminFeatureId}`,
        formData
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteAdminFeaturePage(regionId, adminFeatureId, adminFeaturePageId) {
    try {
      const {
        data
      } = await axios.delete(
        `/admin/region/${regionId}/adminfeature/${adminFeatureId}`,
        { params: { id: adminFeaturePageId } }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getRegionGuestServices(regionId) {
    try {
      const { data } = await axios.get(
        `/admin/region/${regionId}/guest-services`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getRegionSuggestions(regionId) {
    try {
      const { data } = await axios.get(`/admin/region/${regionId}/suggestions`);
      return data;
    } catch (err) {
      throw err;
    }
  }

  async getRegionArchived(regionId) {
    try {
      const { data } = await axios.get(
        `/admin/region/${regionId}/pois/archived`
      );
      return data;
    } catch (err) {
      throw err;
    }
  }

  async getDashboardData() {
    try {
      const { data } = await axios.get(`/admin/dashboard`);
      return data;
    } catch (error) {
      throw error;
    }
  }
}

const api = new APIWrapper();

export default api;
