import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import CssBaseline from "@material-ui/core/CssBaseline";

import HCLogo from "../../assets/HC_Logo.svg";

import DashboardHeader from "./DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";
import DashboardContent from "./DashboardContent";
import dashboardRoutes from "./dashboardRoutes";
import {
  Button,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Popper,
  Grow
} from "@material-ui/core";
import useUserDispatch from "../../hooks/useUserDispatch";
import useUserState from "../../hooks/useUserState";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  toolbar: theme.mixins.toolbar,
  headerInner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  headerTitle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  headerImg: {
    height: "45px",
    width: "auto"
  },
  headerAccount: {}
}));

export default function DashboardWrapper({ children }) {
  const classes = useStyles();
  const [openHeaderMenu, setOpenHeaderMenu] = useState(false);
  const anchorRef = useRef(null);

  const { logoutUser } = useUserDispatch();
  const { email } = useUserState();
  const handleHeaderMenuOpen = () => {
    setOpenHeaderMenu(true);
  };

  const handleHeaderMenuClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenHeaderMenu(false);
  };

  const handleUserLogout = () => {
    logoutUser();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <DashboardHeader>
        <div className={classes.headerInner}>
          <div className={classes.headerTitle}>
            <img
              src={HCLogo}
              alt="Home Concierge Super Admin"
              className={classes.headerImg}
            />
          </div>
          <div className={classes.headerAccount}>
            <Button
              aria-haspopup="true"
              onClick={handleHeaderMenuOpen}
              ref={anchorRef}
              variant="contained"
            >
              {email}
            </Button>
            <Popper
              open={openHeaderMenu}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom"
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={handleHeaderMenuClose}
                      mouseEvent={"onMouseDown"}
                      touchEvent={"onTouchEnd"}
                    >
                      <MenuList>
                        <MenuItem onClick={handleUserLogout}>Logout</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </div>
      </DashboardHeader>
      <DashboardSidebar
        items={dashboardRoutes.filter(route => route.location === "main")}
        subItems={dashboardRoutes.filter(route => route.location === "sub")}
      />
      <DashboardContent>{children}</DashboardContent>
    </div>
  );
}
