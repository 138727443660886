import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BackButton } from "components/BackButton";
import { DashboardPageHeader } from "components/Dashboard";
import useRegionState from "hooks/useRegionState";
import { SkeletonWrapper } from "components/SkeletonWrapper";
import { RoutedTabbedSection } from "components/TabbedSection";
import { Typography, makeStyles } from "@material-ui/core";
import useAlertsDispatch from "hooks/useAlertsDispatch";
import useRegionDispatch from "hooks/useRegionDispatch";
import { Image } from "components/Image";
import { DestSelector } from "components/DestSelector";
import RoutedTabbedSectionSkeleton from "components/TabbedSection/RoutedTabbedSectionSkeleton";
import PeoplePoiTable from "../components/PeoplePoiTable";
import { PanelProvider } from "components/Panel";
import { PoisTable } from "../RegionPois/components";
import { FeatureEditor } from "components/FeatureEditor";

const useStyles = makeStyles(thene => ({
  mainTabImage: {
    width: 138,
    height: 103
  }
}));

function RegionGuestServices({ match }) {
  const classes = useStyles();
  const { region, guest_services } = useRegionState();
  const {
    getRegionGuestServices,
    updateDestinations,
    deleteDestination,
    moveRegionItemSequence,
    commitRegionItemSequences,
    changePoiDefault,
    updateAdminFeature,
    deleteAdminFeaturePage
  } = useRegionDispatch();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const { addAlert } = useAlertsDispatch();

  useEffect(() => {
    if (!region.id) return;
    getRegionGuestServices(region.id)
      .then(() => {
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        setError(err);
      });
  }, [getRegionGuestServices, region.id]);

  if (error)
    return (
      <Typography variant="body2" color="textSecondary">
        There was an error while recieving guest services info for the region.
      </Typography>
    );

  const handleDestSubmit = category => ({ destinations }, actions) => {
    updateDestinations(
      region.id,
      "guest_services",
      category,
      destinations.map(destination => ({
        ...destination.address,
        id: destination.id
      })),
      guest_services[category].id
    )
      .then(() => {
        addAlert({
          intent: "success",
          text: `Succesfully updated destinations for "${guest_services[category].name}"`
        });
        actions.setSubmitting(false);
      })
      .catch(error => {
        addAlert({
          intent: "error",
          text: `There was an error updating destinations for "${guest_services[category].name}"`
        });
        actions.setSubmitting(false);
      });
  };
  const handleDestDelete = category => id => {
    deleteDestination(region.id, "guest_services", category, id).catch(
      error => {
        addAlert({
          intent: "error",
          text: "There was an error deleting the destination."
        });
      }
    );
  };

  const handlePeopleTableDrop = category => {
    return () => {
      commitRegionItemSequences(
        region.id,
        "guest_services",
        category,
        guest_services[category].id
      );
    };
  };

  const handlePeopleTableMove = category => {
    return (draggedItem, droppedItem) => {
      moveRegionItemSequence(
        "guest_services",
        category,
        draggedItem.id,
        droppedItem.sequence
      );
    };
  };

  const handleSetDefault = category => (event, id) => {
    const isDefault = Boolean(event.target.checked);

    changePoiDefault(
      region.id,
      "guest_services",
      guest_services[category].id,
      id,
      isDefault
    );
  };

  const handleFeatureUpdate = ({ features, id }, actions) => {
    updateAdminFeature(region.id, "guest_services", "sunscreen", features, id)
      .then(() => {
        addAlert({
          intent: "success",
          text: "Successfully updated feature pages."
        });
        actions.setSubmitting(false);
      })
      .catch(error => {
        addAlert({
          intent: "error",
          text: "There was an error while updating the feature."
        });
        console.error(error);
        actions.setSubmitting(false);
      });
  };

  const handleFeaturePageDelete = id => {
    deleteAdminFeaturePage(
      region.id,
      "guest_services",
      "sunscreen",
      guest_services.sunscreen.id,
      id
    )
      .then(() => {
        addAlert({
          intent: "success",
          text: "Successfully deleted feature page."
        });
      })
      .catch(error => {
        console.error(error);
        addAlert({
          intent: "error",
          text: "There was an error while deleting feature page."
        });
      });
  };

  return (
    <PanelProvider>
      {region && (
        <BackButton path={`/dashboard/regions/${region.id}`}>
          Back to {region.name}
        </BackButton>
      )}
      <DashboardPageHeader subHeader={region ? region.name : "..."}>
        Guest Services
      </DashboardPageHeader>

      <SkeletonWrapper loading={loading} skeleton={RoutedTabbedSectionSkeleton}>
        {guest_services && (
          <RoutedTabbedSection
            tabs={[
              guest_services.banks,
              guest_services.atms,
              guest_services.gas_stations,
              guest_services.farmers_market,
              guest_services.private_chefs,
              guest_services.private_tours,
              guest_services.personal_shopper,
              guest_services.massage_therapist,
              guest_services.rentals,
              guest_services.sunscreen
            ].map((cat, i) => ({
              icon: (
                <Image
                  src={cat.icon}
                  alt={cat.name}
                  classes={{ root: classes.mainTabImage }}
                />
              ),
              label: cat.name,
              path: `${match.url}/${cat.key}`,
              index: i
            }))}
          >
            <DestSelector
              multi
              destinations={guest_services.banks.items}
              onSubmit={handleDestSubmit("banks")}
              onDelete={handleDestDelete("banks")}
            />

            <DestSelector
              multi
              destinations={guest_services.atms.items}
              onSubmit={handleDestSubmit("atms")}
              onDelete={handleDestDelete("atms")}
            />

            <DestSelector
              multi
              destinations={guest_services.gas_stations.items}
              onSubmit={handleDestSubmit("gas_stations")}
              onDelete={handleDestDelete("gas_stations")}
            />

            <DestSelector
              multi
              destinations={guest_services.farmers_market.items}
              onSubmit={handleDestSubmit("farmers_market")}
              onDelete={handleDestDelete("farmers_market")}
            />
            <PeoplePoiTable
              pois={guest_services.private_chefs}
              onCheck={handleSetDefault("private_chefs")}
              onDrop={handlePeopleTableDrop("private_chefs")}
              onMove={handlePeopleTableMove("private_chefs")}
              stateKey="guest_services"
              category="private_chefs"
              categoryLabel="Private Chef"
            />

            <PeoplePoiTable
              pois={guest_services.private_tours}
              onCheck={handleSetDefault("private_tours")}
              onDrop={handlePeopleTableDrop("private_tours")}
              onMove={handlePeopleTableMove("private_tours")}
              stateKey="guest_services"
              category="private_tours"
              categoryLabel="Private Tours"
            />

            <PeoplePoiTable
              pois={guest_services.personal_shopper}
              onCheck={handleSetDefault("personal_shopper")}
              onDrop={handlePeopleTableDrop("personal_shopper")}
              onMove={handlePeopleTableMove("personal_shopper")}
              stateKey="guest_services"
              category="personal_shopper"
              categoryLabel="Personal Shopper"
            />

            <PeoplePoiTable
              pois={guest_services.massage_therapist}
              onCheck={handleSetDefault("massage_therapist")}
              onDrop={handlePeopleTableDrop("massage_therapist")}
              onMove={handlePeopleTableMove("massage_therapist")}
              stateKey="guest_services"
              category="massage_therapist"
              categoryLabel="Massage Therapist"
            />

            <PoisTable
              pois={guest_services.rentals}
              stateKey="guest_services"
              onCheck={handleSetDefault("rentals")}
              parentCategory="rentals"
              onDrop={handlePeopleTableDrop("rentals")}
              onMove={handlePeopleTableMove("rentals")}
            />
            <FeatureEditor
              feature={guest_services.sunscreen}
              onSubmit={handleFeatureUpdate}
              onDelete={handleFeaturePageDelete}
            />
          </RoutedTabbedSection>
        )}
      </SkeletonWrapper>
    </PanelProvider>
  );
}

RegionGuestServices.propTypes = {
  match: PropTypes.object.isRequired
};

export default RegionGuestServices;
