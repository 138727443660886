import React, { forwardRef } from "react";
import Skeleton from "@material-ui/lab/Skeleton";

const TableSkeleton = props => (
  <Skeleton
    animation="wave"
    variant="rect"
    width={"100%"}
    height={"60vh"}
    {...props}
  />
);

const SearchSkeleton = props => (
  <Skeleton
    animation="wave"
    variant="text"
    width={"100%"}
    height="64px"
    {...props}
  />
);

const PaginationSkeleton = props => (
  <Skeleton
    animation="wave"
    variant="text"
    height="52px"
    width="100%"
    {...props}
  />
);

const HomesTableSkeleton = forwardRef((props, ref) => {
  return (
    <div ref={ref} {...props}>
      <SearchSkeleton></SearchSkeleton>
      <TableSkeleton />
      <PaginationSkeleton></PaginationSkeleton>
    </div>
  );
});

HomesTableSkeleton.propTypes = {};

export default HomesTableSkeleton;
