import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core";

import { DashboardPageHeader } from "components/Dashboard";
import { UserDataForm } from "components/UserDataForm";
import { TabbedSection } from "components/TabbedSection";

import api from "utilities/API";

import useAlertsDispatch from "hooks/useAlertsDispatch";
import useSystemDispatch from "hooks/useSystemDispatch";
import { BackButton } from "components/BackButton";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
  root: {},
}));

function AdminUsersEdit({ match }) {
  const classes = useStyles();

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { addAlert } = useAlertsDispatch();

  const { updateAdminUser } = useSystemDispatch();

  // whenever id param changes, request and store the user data
  useEffect(() => {
    setLoading(true);
    const getUser = (id) => {
      api
        .getUser(id)
        .then(({ data }) => {
          setUserData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.warn(error);
          setLoading(false);
        });
    };

    getUser(match.params.id);
  }, [match.params.id]);

  // handle updating admin user data (also updates local redux system state)
  const handleAdminUserUpdate = (data) => {
    return (values, actions) => {
      updateAdminUser({ id: data.id, ...values })
        .then((admin) => {
          setUserData({
            ...userData,
            first_name: admin.first_name,
            last_name: admin.last_name,
            phone_number: admin.phone_number,
            email: admin.email,
            is_active: admin.is_active,
          });
          addAlert({
            intent: "success",
            text: `Succesfully updated user "${admin.first_name} ${admin.last_name}"`,
          });
          actions.setSubmitting(false);
        })
        .catch((err) => {
          console.warn(err);
          actions.setSubmitting(false);
        });
    };
  };

  return (
    <div className={classes.root}>
      <BackButton path={"/dashboard/admin"}>Back To Admin Users</BackButton>
      <DashboardPageHeader subHeader="User">
        {loading ? "..." : `${userData.email}`}
      </DashboardPageHeader>
      <TabbedSection tabs={[{ label: "Info", index: 0 }]}>
        {!loading && (
          <UserDataForm data={userData} handleUpdate={handleAdminUserUpdate} />
        )}
      </TabbedSection>
    </div>
  );
}

AdminUsersEdit.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default AdminUsersEdit;
