import React from "react";
import PropTypes from "prop-types";
import { BackButton } from "components/BackButton";
import { DashboardPageHeader } from "components/Dashboard";
import RegionEditForm from "./components/RegionEditForm";
import useAlertsDispatch from "hooks/useAlertsDispatch";
import useRegionState from "hooks/useRegionState";
import useRegionDispatch from "hooks/useRegionDispatch";
import { Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
}));

function RegionData({ match, history }) {
  const classes = useStyles();
  const { region } = useRegionState();

  const { updateRegion } = useRegionDispatch();
  const { addAlert } = useAlertsDispatch();

  const handleUpdate = ({ name, description, timezone }, actions) => {
    updateRegion(region.id, { name, description, timezone })
      .then((updatedRegion) => {
        addAlert({
          intent: "success",
          text: `Updated region "${updatedRegion.name}"`,
        });
        actions.setSubmitting(false);
        history.push({ pathname: `/dashboard/regions/${updatedRegion.id}` });
      })
      .catch((error) => {
        console.error(error);
        addAlert({
          intent: "error",
          text: error.message || "Unknown error while updating region.",
        });
      });
  };

  return (
    <div>
      {region && (
        <BackButton path={`/dashboard/regions/${region.id}`}>
          Back to {region.name}
        </BackButton>
      )}
      <DashboardPageHeader subHeader={region ? region.name : "..."}>
        Edit region
      </DashboardPageHeader>
      <Paper variant="outlined" className={classes.paper}>
        {region.id && (
          <RegionEditForm
            values={{
              name: region.name,
              description: region.description,
              timezone: region.timezone,
            }}
            onSubmit={handleUpdate}
          />
        )}
      </Paper>
    </div>
  );
}

RegionData.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default RegionData;
