import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core";

import { DashboardPageHeader } from "components/Dashboard";
import { PanelProvider } from "components/Panel";
import { RoutedTabbedSection } from "components/TabbedSection";
import { Image } from "components/Image";
import useSystemState from "hooks/useSystemState";
import IconsList from "./IconsList";
import useSystemDispatch from "hooks/useSystemDispatch";
import { SkeletonWrapper } from "components/SkeletonWrapper";
import IconsTabbedSectionSkeleton from "./IconsTabbedSectionSkeleton";

const useStyles = makeStyles(theme => ({
  root: {},
  tabImageRoot: {
    width: 138,
    height: 103
  }
}));

const TabIcon = ({ src, alt }) => {
  const classes = useStyles();
  return (
    <Image
      src={src}
      alt={alt}
      classes={{ root: classes.tabImageRoot, image: classes.tabImage }}
    />
  );
};

function IconsRoot({ match }) {
  const classes = useStyles();
  const { icons } = useSystemState();
  const { getAllIcons } = useSystemDispatch();
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState(null);
  useEffect(() => {
    getAllIcons()
      .then(() => setLoading(false))
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [getAllIcons]);

  if (error) {
    console.error(error);
    return <p>{error.message}</p>;
  }

  return (
    <div className={classes.root}>
      <DashboardPageHeader>Icons</DashboardPageHeader>
      <PanelProvider>
        <SkeletonWrapper
          skeleton={IconsTabbedSectionSkeleton}
          loading={loading}
        >
          <RoutedTabbedSection
            tabs={icons
              .slice()
              .sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0))
              .map((iconCategory, i) => ({
                icon: (
                  <TabIcon
                    src={iconCategory.image_url}
                    alt={iconCategory.title}
                  />
                ),
                label: iconCategory.title,
                path: `${match.url}/${iconCategory.id}`,
                index: i
              }))}
          >
            {icons
              .slice()
              .sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0))
              .map(iconCategory => (
                <IconsList key={iconCategory.id} iconCategory={iconCategory} />
              ))}
          </RoutedTabbedSection>
        </SkeletonWrapper>
      </PanelProvider>
    </div>
  );
}

IconsRoot.propTypes = {
  match: PropTypes.object.isRequired
};

export default IconsRoot;
