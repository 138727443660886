import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.dark,
    textDecoration: "none",
    transition: `0.3s color ${theme.transitions.easing.easeOut}`,
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.primary.main,
    },
  },
}));

function UserLinkCell({ to, firstName, lastName, id }) {
  const classes = useStyles();
  return (
    <Link to={to} className={classes.root}>
      {firstName} {lastName} <strong>({id})</strong>
    </Link>
  );
}

UserLinkCell.propTypes = {
  to: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

export default UserLinkCell;
