import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { RegionRoot } from ".";
import { Route } from "react-router-dom";
import RegionPending from "../RegionPending";
import RegionHomes from "./RegionHomes";
import RegionHosts from "./RegionHosts";
import { FallbackSwitch } from "components/FallbackSwitch";
import RegionData from "../RegionData/RegionData";
import RegionLinks from "../RegionLinks";
import RegionGuestServices from "../RegionGuestServices";
import RegionEmergency from "../RegionEmergency";
import RegionTvWifiOffice from "../RegionTvWifiOffice";
import RegionAirportDetails from "../RegionAirportDetails";
import RegionGeneral from "../RegionGeneral";
import RegionPois from "../RegionPois";
import useRegionDispatch from "hooks/useRegionDispatch";
import RegionArchived from "../RegionArchived";
import RegionDefaults from "../RegionDefaults";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function RegionView({ match }) {
  const classes = useStyles();
  const regionId = Number(match.params.id);
  const { getRegion } = useRegionDispatch();

  const [, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    getRegion(regionId)
      .then((region) => {
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [getRegion, regionId]);

  if (error) return <p>{String(error)}</p>;

  return (
    <div className={classes.root}>
      <FallbackSwitch fallback={() => <p>NOT FOUND</p>}>
        <Route path={`${match.url}`} exact component={RegionRoot} />
        <Route path={`${match.url}/pending`} component={RegionPending} />
        <Route path={`${match.url}/pois`} component={RegionPois} />
        <Route path={`${match.url}/homes`} component={RegionHomes} />
        <Route path={`${match.url}/hosts`} component={RegionHosts} />
        <Route path={`${match.url}/edit`} component={RegionData} />
        <Route path={`${match.url}/links`} component={RegionLinks} />
        <Route
          path={`${match.url}/guest-services`}
          component={RegionGuestServices}
        />

        <Route path={`${match.url}/emergency`} component={RegionEmergency} />
        <Route
          path={`${match.url}/tv-wifi-office`}
          component={RegionTvWifiOffice}
        />
        <Route
          path={`${match.url}/airport-details`}
          component={RegionAirportDetails}
        />
		<Route
		  path={`${match.url}/defaults`}
		  component={RegionDefaults}
		/>
        <Route path={`${match.url}/archived`} component={RegionArchived} />

        <Route path={`${match.url}/general`} component={RegionGeneral} />
      </FallbackSwitch>
    </div>
  );
}

RegionView.propTypes = {
  match: PropTypes.object.isRequired,
};

export default RegionView;
