import React from "react";
import PropTypes from "prop-types";
import { DashboardPageHeader } from "components/Dashboard";
import { BackButton } from "components/BackButton";
import { makeStyles } from "@material-ui/core";
import RegionCardLink from "./RegionCardLink";
import useRegionState from "hooks/useRegionState";

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexBasis: "33%",
      padding: theme.spacing(2),
      minWidth: 330,
    },
  },
}));

function RegionRoot({ match }) {
  const { region } = useRegionState();
  const classes = useStyles();
  return (
    <div>
      <BackButton path={"/dashboard/regions"}>Back To Regions</BackButton>
      <DashboardPageHeader subHeader="Region">
        {region ? region.name : "..."}
      </DashboardPageHeader>
      <div className={classes.itemContainer}>
        <RegionCardLink path={`${match.url}/edit`} title="Region Data">
          View and update the metadata for the region
        </RegionCardLink>
        <RegionCardLink title="Homes" path={`${match.url}/homes`}>
          View the homes created in the region.
        </RegionCardLink>

        <RegionCardLink path={`${match.url}/hosts`} title="Hosts">
          View users who are added as a host to any homes in the region.
        </RegionCardLink>

        <RegionCardLink path={`${match.url}/pending`} title={"Suggestions"}>
          View user-submitted POI suggestions for this region.
        </RegionCardLink>
        <RegionCardLink title="Points Of Interest" path={`${match.url}/pois`}>
          Manage the region's point-of-interests.
        </RegionCardLink>

        <RegionCardLink path={`${match.url}/links`} title="Home Links">
          Manage the region's Weather & Local News links
        </RegionCardLink>

        <RegionCardLink
          path={`${match.url}/tv-wifi-office`}
          title="TV Wifi Office"
        >
          Manage TV Wifi Office options for the region
        </RegionCardLink>
        <RegionCardLink path={`${match.url}/emergency`} title="Emergency">
          Manage Emergency options for the region
        </RegionCardLink>
        <RegionCardLink
          path={`${match.url}/guest-services`}
          title="Guest Services"
        >
          Manage Guest Services options for the region
        </RegionCardLink>
        <RegionCardLink
          path={`${match.url}/airport-details`}
          title="Airport Details"
        >
          Manage Airport Details options for the region
        </RegionCardLink>

		<RegionCardLink
		  path={`${match.url}/defaults`}
		  title="Defaults"
		>
		  Manage Default options for the region
		</RegionCardLink>

        <RegionCardLink path={`${match.url}/general`} title="General">
          Manage general region settings, such as Privacy Policy or contact
          email.
        </RegionCardLink>
        <RegionCardLink path={`${match.url}/archived`} title="Archived POIs">
          View or re-enable archived points of interest.
        </RegionCardLink>
      </div>
    </div>
  );
}

RegionRoot.propTypes = {
  match: PropTypes.object.isRequired,
};

export default RegionRoot;
