import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core";

import * as Yup from "yup";
import clsx from "clsx";
import { Formik } from "formik";

import { FormWrapper } from "components/FormWrapper";
import { InputField } from "components/InputField";
import { InputEditor } from "components/InputEditor";
import { HCButton } from "components/HCButton";
import { ImageUpload } from "components/ImageUpload";

import useAlertsDispatch from "hooks/useAlertsDispatch";

const FeatureNewFormValidationSchema = Yup.object().shape({
  icon: Yup.string().required("You must upload an icon for the option."),
  name: Yup.string().required("You must give the option a name."),
  description: Yup.string(),
  defaultText: Yup.string(),
});

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  root: {},
}));

function FeatureNewForm({ onSubmit, className }) {
  const classes = useStyles();
  const initialValues = {
    icon: "",
    name: "",
    description: "",
    defaultText: "",
  };

  const { addAlert } = useAlertsDispatch();

  const handleImageUploadError = (file, message) => {
    addAlert({
      intent: "error",
      text: message,
    });
  };

  return (
    <div className={clsx(classes.root, className && className)}>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={FeatureNewFormValidationSchema}
      >
        {({ handleSubmit, isSubmitting }) => (
          <FormWrapper onSubmit={handleSubmit}>
            <ImageUpload
              name="icon"
              id="icon"
              label="Icon"
              onError={handleImageUploadError}
            />
            <InputField
              full
              name="name"
              id="name"
              variant="outlined"
              label="Name of option"
            />
            <InputField
              full
              multiline
              rows={4}
              name="description"
              id="description"
              variant="outlined"
              label="Option description"
            />
            <InputEditor
              name="defaultText"
              id="defaultText"
              variant="outlined"
              label="Default Text"
            />
            <HCButton
              loading={isSubmitting}
              variant="contained"
              type="submit"
              color="primary"
              className={{ root: classes.submit }}
              fullWidth
            >
              Create option
            </HCButton>
          </FormWrapper>
        )}
      </Formik>
    </div>
  );
}

FeatureNewForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default FeatureNewForm;
