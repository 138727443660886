import React, { useState } from "react";
// import PropTypes from "prop-types";
import { DraggableTable } from "components/DraggableTable";
import { makeStyles } from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import { useRouteMatch, Link, useHistory } from "react-router-dom";
import DefaultCheckbox from "./DefaultCheckbox";
import { Image } from "components/Image";
import { ViewToggler } from "components/ViewToggler";
import { HCButton } from "components/HCButton";
import { RoutedPanel } from "components/Panel";
import PoiEditPanel from "./PoiEditPanel";
import useRegionDispatch from "hooks/useRegionDispatch";
import useRegionState from "hooks/useRegionState";
import useAlertsDispatch from "hooks/useAlertsDispatch";
import PoiNewPanel from "./PoiNewPanel";
import { HCModal } from "components/HCModal";
import PoiDeleteModal from "./PoiDeleteModal";
import ArchiveIcon from "@material-ui/icons/Archive";
import PoiArchiveModal from "./PoiArchiveModal";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import PoiLinkPanel from "./PoiLinkPanel";

const useStyles = makeStyles(theme => ({
  table: {},
  poiImage: {
    width: 200,
    height: 112.5
  },
  viewTogglerWrapper: {},
  newBtn: {},
  viewToggler: {
    width: "auto",
    textAlign: "right",
    marginBottom: theme.spacing(2),
    float: "right"
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2)
  },
  buttonActions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    "&  > div": {
      marginTop: theme.spacing(2),
      "&:first-of-type": {
        marginTop: 0
      }
    }
  }
}));

function PoisTable({
  onDrop,
  onMove,
  onCheck,
  pois,
  maxChecked = 12,
  parentCategory,
  categoryLabel,
  stateKey = "poi"
}) {
  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();
  const [view, setView] = useState("list");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);

  const [queuedArchiveData, setQueuedArchiveData] = useState();
  const [queuedDeleteData, setQueuedDeleteData] = useState();

  const {
    updatePoi,
    createPoi,
    deletePoi,
    archivePoi,
    updateMoreLink
  } = useRegionDispatch();
  const { region } = useRegionState();

  const { addAlert } = useAlertsDispatch();

  const handleCheck = (event, id) => {
    onCheck(event, id);
  };
  const defaultItems = pois.items.filter(({ is_default }) =>
    Boolean(is_default)
  );

  const poiRows = pois.items
    .map(
      ({
        id,
        name,
        address_line_1,
        address_line_2,
        city,
        state,
        is_default,
        image_url,
        sequence_no,
        phone_number,
        website
      }) => {
        const parsedPhoneNumber = parsePhoneNumberFromString(
          phone_number || "",
          "US"
        );

        return {
          image: (
            <Image
              src={image_url}
              alt={name}
              classes={{ root: classes.poiImage }}
            />
          ),
          id,
          phone_number: parsedPhoneNumber
            ? parsedPhoneNumber.formatNational()
            : null,
          website,
          name,
          sequence: sequence_no,
          address: `${address_line_1 || ""} ${address_line_2 || ""} ${
            city || ""
          } ${state || ""}`,
          isDefault: (
            <DefaultCheckbox
              id={id}
              value={Boolean(is_default)}
              onCheck={handleCheck}
              disabled={
                defaultItems.length + 1 > maxChecked &&
                Boolean(is_default) === false
              }
            />
          )
        };
      }
    )
    .sort((a, b) => (a.sequence > b.sequence ? 1 : -1));

  const closePanel = () => history.push({ pathname: match.url });

  const handlePoiUpdate = (
    parentCategory,
    id,
    {
      image,
      name,
      description,
      address,
      phone,
      website,
      reservations,
      menu,
      directory
    },
    actions
  ) => {
    updatePoi(region.id, stateKey, parentCategory, pois.key, id, {
      image,
      name,
      description,
      address,
      phone,
      website,
      reservations,
      menu,
      directory
    })
      .then(() => {
        actions.setSubmitting(false);
        history.push({ pathname: match.url });
        addAlert({ intent: "success", text: `Succesfully updated "${name}"` });
      })
      .catch(error => {
        console.error(error);
        actions.setSubmitting(false);
        addAlert({
          intent: "error",
          text:
            error?.response?.data?.message ||
            "There was an unknown error while updating POI."
        });
      });
  };

  const handlePoiMoreLinkUpdate = (featureId, data, actions) => {
    updateMoreLink(
      region.id,
      stateKey,
      featureId,
      parentCategory,
      pois.key,
      data.link
    )
      .then(() => {
        actions.setSubmitting(false);
        addAlert({
          intent: "success",
          text: `Succesfully updated MORE link for "${pois.name}"`
        });
        history.push({ pathname: match.url });
      })
      .catch(err => {
        actions.setSubmitting(false);
        addAlert({
          intent: "success",
          text:
            err.response?.data?.message ||
            "There was an unknown error while updating MORE link."
        });
      });
  };

  const handlePoiNew = (
    parentCategory,
    id,
    {
      image,
      name,
      description,
      address,
      phone,
      website,
      reservations,
      menu,
      directory
    },
    actions
  ) => {
    createPoi(region.id, stateKey, pois.id, parentCategory, pois.key, id, {
      image,
      name,
      description,
      address,
      phone,
      website,
      reservations,
      menu,
      directory
    })
      .then(() => {
        actions.setSubmitting(false);
        history.push({ pathname: match.url });
        addAlert({
          intent: "success",
          text: `Succesfully created POI "${name}"`
        });
      })
      .catch(error => {
        console.error(error);
        actions.setSubmitting(false);
        addAlert({
          intent: "success",
          text:
            error.response?.data?.message ||
            "There was an unknown error while updating POI."
        });
      });
  };

  const handleDeleteClick = (evt, data) => {
    setQueuedDeleteData(null);
    setQueuedDeleteData(data);
    setShowDeleteModal(true);
  };

  const handleArchiveClick = (evt, data) => {
    setQueuedArchiveData(null);
    setQueuedArchiveData(data);
    setShowArchiveModal(true);
  };

  const handleDeleteConfirm = (data, actions) => {
    deletePoi(region.id, stateKey, parentCategory, pois.key, data.id)
      .then(() => {
        actions.setSubmitting(false);
        setShowDeleteModal(false);
        setQueuedDeleteData();
        addAlert({
          intent: "success",
          text: `Succesfully deleted POI "${data.name}" (ID #${data.id}).`
        });
      })
      .catch(error => {
        actions.setSubmitting(false);
        console.error(error);
        addAlert({
          intent: "error",
          text:
            error.response?.data?.message ||
            "There was an unknown error while updating POI."
        });
      });
  };

  const handleArchiveConfirm = (data, actions) => {
    const {
      id,
      image,
      name,
      description,
      address,
      phone,
      website,
      reservations
    } = data;
    archivePoi(region.id, stateKey, parentCategory, pois.key, id, {
      image,
      name,
      description,
      address,
      phone,
      website,
      reservations
    })
      .then(() => {
        actions.setSubmitting(false);
        setShowArchiveModal(false);
        setQueuedArchiveData(null);
        addAlert({
          intent: "success",
          text: `Succesfully archived POI "${data.name}" (ID #${data.id}).`
        });
      })
      .catch(err => {
        actions.setSubmitting(false);
        console.error(err);
        addAlert({
          intent: "error",
          text:
            err.response?.data?.message ||
            "There was an unknown error while archiving POI."
        });
      });
  };

  return (
    <>
      <div className={classes.actions}>
        <div className={classes.buttonActions}>
          <HCButton
            variant="outlined"
            color="primary"
            component={Link}
            to={`${match.url}/link`}
            className={classes.newBtn}
          >
            Edit MORE Link
          </HCButton>
          <HCButton
            variant="contained"
            color="primary"
            component={Link}
            to={`${match.url}/new`}
            className={classes.newBtn}
          >
            Create New POI Option
          </HCButton>
        </div>
        <div className={classes.togglerAction}>
          <ViewToggler
            view={view}
            onChange={(event, newView) =>
              newView !== null ? setView(newView) : null
            }
            className={classes.viewToggler}
          />
        </div>
      </div>
      <DraggableTable
        className={classes.table}
        onDrop={onDrop}
        onMove={onMove}
        size="small"
        headers={[
          ...(view === "detail"
            ? [
                {
                  label: "Image",
                  dataKey: "image"
                }
              ]
            : []),
          {
            label: "ID",
            dataKey: "id"
          },
          {
            label: "Name",
            dataKey: "name"
          },
          {
            label: "Phone",
            dataKey: "phone_number"
          },
          {
            label: "Website",
            dataKey: "website"
          },
          {
            label: "Address",
            dataKey: "address"
          },
          {
            label: "Default",
            dataKey: "isDefault"
          }
        ]}
        rows={poiRows}
        actions={[
          { Icon: EditIcon, link: ({ id }) => `${match.url}/${id}` },
          { Icon: DeleteIcon, onClick: handleDeleteClick },
          { Icon: ArchiveIcon, onClick: handleArchiveClick }
        ]}
      />

      <RoutedPanel path={`${match.url}/:id(\\d+)`} onClose={closePanel}>
        <PoiEditPanel
          parentCategory={parentCategory}
          onUpdate={handlePoiUpdate.bind(undefined, parentCategory)}
          pois={pois}
        />
      </RoutedPanel>

      <RoutedPanel path={`${match.url}/new`} onClose={closePanel}>
        <PoiNewPanel
          parentCategory={parentCategory}
          onNew={handlePoiNew.bind(undefined, parentCategory)}
          pois={pois}
        />
      </RoutedPanel>

      <RoutedPanel path={`${match.url}/link`} onClose={closePanel}>
        <PoiLinkPanel
          onUpdate={handlePoiMoreLinkUpdate.bind(undefined, pois.id)}
          pois={pois}
        />
      </RoutedPanel>

      <HCModal
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
      >
        <PoiDeleteModal
          data={queuedDeleteData}
          onClose={() => setShowDeleteModal(false)}
          onSubmit={handleDeleteConfirm}
        />
      </HCModal>

      <HCModal
        open={showArchiveModal}
        handleClose={() => setShowArchiveModal(false)}
      >
        <PoiArchiveModal
          data={queuedArchiveData}
          onClose={() => setShowArchiveModal(false)}
          onSubmit={handleArchiveConfirm}
        />
      </HCModal>
    </>
  );
}

PoisTable.propTypes = {};

export default PoisTable;
