import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "rgba(0, 0, 0, 0.25)",
  },
  image: {
    height: "100%",
    width: "100%",
    objectPosition: "center",
    objectFit: "cover",
    display: "block",
  },
  fallback: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    padding: theme.spacing(1),
  },
}));

function Image({ src, classes: passedClasses = {}, alt, ...props }) {
  const classes = useStyles();
  const [error, setError] = useState(false);

  const handleImageError = (evt) => {
    setError(true);
  };

  return (
    <div
      className={clsx(classes.root, passedClasses.root && passedClasses.root)}
    >
      {error || !src ? (
        <div className={classes.fallback}>
          <Typography variant="body2" align="center">
            Image Not Found
          </Typography>
        </div>
      ) : (
        <img
          src={src}
          alt={alt}
          onError={handleImageError}
          className={clsx(
            classes.image,
            passedClasses.image && passedClasses.image
          )}
          {...props}
        />
      )}
    </div>
  );
}

Image.propTypes = {
  src: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    image: PropTypes.string,
  }),
  alt: PropTypes.string.isRequired,
};

export default Image;
