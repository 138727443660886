import { createReducer } from "@reduxjs/toolkit";
import { setLoading } from "../actions/loading";
const initialState = {
  storage: { loading: true },
  login: {},
  getAllHouses: {},
  getAllUsers: {},
  getAllRegions: {},
  deleteUser: {},
  deleteRegion: {},
  getAllAdminUsers: {},
  deleteAdminUser: {},
};

const loadingReducer = createReducer(initialState, {
  [setLoading]: (state, { payload: { requestName, requestStatus } }) => {
    return {
      ...state,
      [requestName]: {
        loading: requestStatus === "REQUEST" ? true : false,
        status: requestStatus,
      },
    };
  },
});

export { initialState };

export default loadingReducer;
