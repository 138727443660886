import React, { useState } from "react";
import PropTypes from "prop-types";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import { makeStyles } from "@material-ui/core";

import { useRouteMatch, useHistory, Link } from "react-router-dom";

import { HCButton } from "components/HCButton";
import { DraggableTable } from "components/DraggableTable";
import { RoutedPanel } from "components/Panel";

import FeatureEdit from "./FeatureEdit";
import FeatureCategoryEdit from "./FeatureCategoryEdit";
import FeatureNew from "./FeatureNew";
import FeatureDeleteModal from "./FeatureDeleteModal";

import useAlertsDispatch from "hooks/useAlertsDispatch";
import useSystemDispatch from "hooks/useSystemDispatch";

const useStyles = makeStyles((theme) => ({
  root: {},
  table: { maxWidth: 768 },
  optionsCta: {
    marginBottom: theme.spacing(4),
    "& > *": {
      marginTop: theme.spacing(2),
      "&:first-of-type": {
        marginTop: 0,
      },
    },
  },
  optionsContainer: {
    display: "flex",
    "& > *": {
      marginLeft: "20px",
      "&:first-of-type": {
        marginLeft: "0px",
      },
    },
  },
}));

function HouseFeatureOptionsSection({
  options,
  categoryLabel = "Feature",
  category,
  categoryData,
  onDrop,
  onMove,
}) {
  const match = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [queuedDeleteData, setQueuedDeleteData] = useState({});

  const { addAlert } = useAlertsDispatch();
  const {
    deleteHouseFeatureOption,
    createHouseFeatureOption,
    updateHouseFeatureOption,
  } = useSystemDispatch();
  // add category to options array, sort by sequence
  const optionsFormatted = options
    .map((option) => ({
      ...option,
      category: categoryLabel,
    }))
    .sort((a, b) => (a.sequence > b.sequence ? 1 : -1));

  const closePanel = () => {
    history.push({ pathname: match.url });
  };

  const handleShowDeletePrompt = (evt, data) => {
    setShowDeleteModal(true);
    setQueuedDeleteData(data);
  };

  const handleConfirmDelete = (evt, actions) => {
    evt.preventDefault();
    const { id } = queuedDeleteData;
    deleteHouseFeatureOption(category, id)
      .then((option) => {
        actions.setSubmitting(false);
        setShowDeleteModal(false);

        addAlert({
          intent: "success",
          text: `Succesfully deleted option "${option.title}"`,
        });
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addAlert({
          intent: "error",
          text: error.message,
        });
      });
  };

  const handleNew = (values, actions) => {
    createHouseFeatureOption(categoryData.id, category, values)
      .then((option) => {
        actions.setSubmitting(false);
        addAlert({
          intent: "success",
          text: `Succesfully created feature option "${option.title}"`,
        });
        closePanel();
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addAlert({ intent: "error", text: error.message });
      });
  };

  const handleUpdate = (values, actions, option) => {
    updateHouseFeatureOption(category, option.id, values)
      .then((option) => {
        addAlert({
          intent: "success",
          text: `Succesfully updated feature option "${option.title}"`,
        });
        actions.setSubmitting(false);
        closePanel();
      })
      .catch((error) => {
        console.warn(error);
        addAlert({
          intent: "error",
          text: error.message,
        });
        actions.setSubmitting(false);
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.optionsCta}>
        <HCButton
          variant="outlined"
          color="primary"
          component={Link}
          to={`${match.url}/edit`}
        >
          Edit {categoryLabel} category
        </HCButton>
        <HCButton
          variant="contained"
          color="primary"
          component={Link}
          to={`${match.url}/new`}
        >
          Create New {categoryLabel} Option
        </HCButton>
      </div>

      <DraggableTable
        className={classes.table}
        onDrop={onDrop}
        onMove={onMove}
        headers={[
          {
            label: "Icon",
            dataKey: "image_url",
            image: true,
          },

          {
            label: "Name",
            dataKey: "name",
          },
          {
            label: "Category",
            dataKey: "category",
          },
        ]}
        rows={optionsFormatted}
        actions={[
          { Icon: EditIcon, link: ({ id }) => `${match.url}/${id}` },
          { Icon: DeleteIcon, onClick: handleShowDeletePrompt },
        ]}
      />
      <RoutedPanel path={`${match.url}/new`} onClose={closePanel}>
        <FeatureNew onNew={handleNew} categoryLabel={categoryLabel} />
      </RoutedPanel>

      <RoutedPanel path={`${match.url}/edit`} onClose={closePanel}>
        {({ handleClose }) => (
          <FeatureCategoryEdit
            onClose={handleClose}
            category={category}
            categoryData={categoryData}
          />
        )}
      </RoutedPanel>

      <RoutedPanel path={`${match.url}/:id(\\d+)`} onClose={closePanel}>
        <FeatureEdit
          onUpdate={handleUpdate}
          categoryLabel={categoryLabel}
          options={options}
        />
      </RoutedPanel>

      <FeatureDeleteModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
        data={queuedDeleteData}
      />
    </div>
  );
}

HouseFeatureOptionsSection.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      image_url: PropTypes.string,
      description: PropTypes.string,
      default_text: PropTypes.string,
      is_active: PropTypes.number,
    })
  ).isRequired,
  categoryLabel: PropTypes.string,
  category: PropTypes.string.isRequired,
  onDrop: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
};

export default HouseFeatureOptionsSection;
