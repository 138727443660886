import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import { PanelHeader } from "components/Panel";
import BasicPoiForm from "./BasicPoiForm";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function BasicPoiEditPanel({ pois, onSubmit, categoryLabel }) {
  const classes = useStyles();
  const match = useRouteMatch();
  const poiId = Number(match.params.id);

  const poi = poiId ? pois.find(({ id }) => id === poiId) : null;

  return (
    <div className={classes.root}>
      <PanelHeader
        header={poi.name}
        subHeader={categoryLabel}
        icon={pois.icon}
      />
      <BasicPoiForm
        data={poi}
        onSubmit={onSubmit.bind(undefined, poiId)}
        cta={"Update POI"}
      />
    </div>
  );
}

BasicPoiEditPanel.propTypes = {
  pois: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
};

export default BasicPoiEditPanel;
