import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, makeStyles, Paper } from "@material-ui/core";
import { Route, Link, useLocation, Redirect, Switch } from "react-router-dom";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {},
  paper: {
    padding: theme.spacing(4),
  },
  scrollButtons: {
    background: "#eee",
  },
}));

function RoutedTabbedSection({ tabs, children }) {
  const classes = useStyles();
  const location = useLocation();
  const [tabPage, setTabPage] = useState(
    tabs.find(({ path }) => location.pathname === path)
      ? tabs.find(({ path }) => location.pathname === path).index
      : tabs.find(({ path }) => location.pathname.includes(path + "/"))
      ? tabs.find(({ path }) => location.pathname.includes(path + "/")).index
      : 0
  );

  useEffect(() => {
    setTabPage(
      tabs.find(({ path }) => location.pathname === path)
        ? tabs.find(({ path }) => location.pathname === path).index
        : tabs.find(({ path }) => location.pathname.includes(path + "/"))
        ? tabs.find(({ path }) => location.pathname.includes(path + "/")).index
        : 0
    );
  }, [location, tabs]);

  const handleTabChange = (_, newTab) => {
    setTabPage(newTab);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={tabPage}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        scrollButtons="auto"
        variant="scrollable"
        classes={{ scrollButtons: classes.scrollButtons }}
      >
        {tabs.map(({ label, icon, path }, i) => (
          <Tab key={i} label={label} icon={icon} component={Link} to={path} />
        ))}
      </Tabs>
      <Paper variant="outlined" className={classes.paper}>
        <Switch>
          {tabs.map(({ path, index }, i) =>
			children && children[0] ? (
              <Route key={path} path={path} render={() => children[index]} />
            ) : null
          )}

		  {tabs && tabs[0] && (
			<Route render={() => <Redirect to={tabs[0].path} />} />
		  )}
        </Switch>
      </Paper>
    </div>
  );
}

RoutedTabbedSection.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

export default RoutedTabbedSection;
