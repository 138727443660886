import React from "react";
import PropTypes from "prop-types";
import { PanelHeader } from "components/Panel";
import PoiLinkForm from "./PoiLinkForm";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "550px"
  }
}));

function PoiLinkPanel({ onUpdate, pois }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <PanelHeader
        header={`Edit "${pois.name}" MORE Link`}
        subHeader={pois.name}
        icon={pois.icon}
      />
      <PoiLinkForm onSubmit={onUpdate} link={pois.more_url} />
    </div>
  );
}

PoiLinkPanel.propTypes = {
  pois: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    icon: PropTypes.string,
    description: PropTypes.string,
    key: PropTypes.string,
    url: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        address_line_1: PropTypes.string,
        address_line_2: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.number,
        image_url: PropTypes.string,
        is_approved: PropTypes.number,
        is_default: PropTypes.number,
        name: PropTypes.string,
        sequence_no: PropTypes.number,
        state: PropTypes.string
      })
    )
  })
};

export default PoiLinkPanel;
