import React, { useContext } from "react";
import PropTypes from "prop-types";

import ReactDOM from "react-dom";
import PanelContext from "./PanelContext";
import PanelContent from "./PanelContent";

function Panel({ children, in: inProp, onClose, lock = false }) {
  const modalNode = useContext(PanelContext);

  return modalNode
    ? ReactDOM.createPortal(
        <PanelContent lock={lock} onClose={onClose}>
          {children}
        </PanelContent>,
        modalNode
      )
    : null;
}

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  in: PropTypes.bool,
  onClose: PropTypes.func
};

export default Panel;
