import React from "react";
import { Formik } from "formik";
import { FormWrapper } from "../FormWrapper";
import { InputField } from "../InputField";
import { makeStyles } from "@material-ui/core";
import HCButton from "../HCButton/HCButton";
import useLoadingState from "../../hooks/useLoadingState";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: theme.spacing(2),
  },
}));

const LoginFormValidationSchema = Yup.object().shape({
  email: Yup.string().required("You must enter an email."),
  password: Yup.string().required("You must enter an password."),
});

function LoginForm({ onLogin }) {
  const classes = useStyles();

  const { login } = useLoadingState();

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={LoginFormValidationSchema}
      onSubmit={onLogin}
    >
      {({ handleSubmit, errors, values }) => (
        <FormWrapper onSubmit={handleSubmit}>
          <InputField
            full
            name="email"
            id="email"
            variant="outlined"
            label="Email"
            type="email"
          />
          <InputField
            full
            name="password"
            id="password"
            variant="outlined"
            label="Password"
            type="password"
          />
          <HCButton
            loading={login.loading}
            variant="contained"
            type="submit"
            color="primary"
            className={{ root: classes.submit }}
            fullWidth
          >
            Log In
          </HCButton>
        </FormWrapper>
      )}
    </Formik>
  );
}

LoginForm.propTypes = {};

export default LoginForm;
