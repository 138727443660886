import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    display: "flex"
  }
}));

function TableControls({ children }) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}

TableControls.propTypes = {};

export default TableControls;
