import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core";

import { HomesTable, HomesTableSkeleton } from "components/HomesTable";
import { SkeletonWrapper } from "components/SkeletonWrapper";
import { DashboardPageHeader } from "components/Dashboard";

import useLoadingState from "hooks/useLoadingState";
import useSystemState from "hooks/useSystemState";
import useSystemDispatch from "hooks/useSystemDispatch";
import UserLinkCell from "components/UsersTable/UserLinkCell";
import RegionLinkCell from "components/UsersTable/RegionLinkCell";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function HomesIndex() {
  const classes = useStyles();

  const { getAllHouses } = useSystemDispatch();
  const { getAllHouses: getAllHousesLoading } = useLoadingState();
  const { houses } = useSystemState();

  useEffect(() => {
    if (!houses) {
      getAllHouses();
    }
  }, [getAllHouses, houses]);

  const houseRows = !houses
    ? []
    : houses.map(({ id, region, name, created_at, user }) => ({
        id,
        region: (
          <RegionLinkCell
            name={region.name}
            id={region.id}
            to={`/dashboard/regions/${region.id}`}
          />
        ),
        propertyName: name,
        user: (
          <UserLinkCell
            firstName={user.first_name}
            lastName={user.last_name}
            id={user.id}
            to={`/dashboard/users/${user.id}/edit`}
          />
        ),
        createdAt: created_at,
      }));

  return (
    <div className={classes.root}>
      <DashboardPageHeader>Homes</DashboardPageHeader>
      <SkeletonWrapper
        skeleton={HomesTableSkeleton}
        loading={getAllHousesLoading.loading}
      >
        <HomesTable houses={houseRows} />
      </SkeletonWrapper>
    </div>
  );
}

HomesIndex.propTypes = {};

export default HomesIndex;
