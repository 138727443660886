import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BackButton } from "components/BackButton";
import { DashboardPageHeader } from "components/Dashboard";
import { HomesTable, HomesTableSkeleton } from "components/HomesTable";
import { SkeletonWrapper } from "components/SkeletonWrapper";
import RegionLinkCell from "components/UsersTable/RegionLinkCell";
import UserLinkCell from "components/UsersTable/UserLinkCell";
import useRegionState from "hooks/useRegionState";
import useRegionDispatch from "hooks/useRegionDispatch";

function RegionHomes({ match }) {
  const { region, homes } = useRegionState();
  const { getRegionHomes } = useRegionDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    if (!region.id) return;
    getRegionHomes(region.id)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [getRegionHomes, region.id]);

  const houseRows = !homes
    ? []
    : homes.map(({ id, region, name, created_at, user }) => ({
        id,
        region: (
          <RegionLinkCell
            name={region.name}
            id={region.id}
            to={`/dashboard/regions/${region.id}`}
          />
        ),
        propertyName: name,
        user: (
          <UserLinkCell
            firstName={user.first_name}
            lastName={user.last_name}
            id={user.id}
            to={`/dashboard/users/${user.id}/edit`}
          />
        ),
        createdAt: created_at,
      }));

  if (error) return <p>Error getting region homes</p>;
  return (
    <div>
      {region && (
        <BackButton path={`/dashboard/regions/${region.id}`}>
          Back to {region.name}
        </BackButton>
      )}
      <DashboardPageHeader subHeader={region ? region.name : "..."}>
        Homes
      </DashboardPageHeader>
      <SkeletonWrapper skeleton={HomesTableSkeleton} loading={loading}>
        <HomesTable houses={houseRows} onDelete={() => {}} onEdit={() => {}} />
      </SkeletonWrapper>
    </div>
  );
}

RegionHomes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default RegionHomes;
