import React from "react";
import PropTypes from "prop-types";

import { Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import { FeaturesRoot } from "./components";

import { DashboardPage } from "components/Dashboard";

function Features({ match }) {
  return (
    <DashboardPage>
      <Helmet>
        <title>Features - Home Concierge Super Admin</title>
      </Helmet>
      <Switch>
        <Route path={`${match.url}`} component={FeaturesRoot} />
      </Switch>
    </DashboardPage>
  );
}

Features.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default Features;
