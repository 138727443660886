import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormWrapper } from "components/FormWrapper";
import { Typography, Button } from "@material-ui/core";
import { HCButton } from "components/HCButton";

function PoiDeleteModal({ data, onClose, onSubmit }) {
  const [isSubmitting, setSubmitting] = useState(false);

  if (!data) return null;

  const handleDelete = (evt) => {
    evt.preventDefault();
    setSubmitting(true);
    if (onSubmit) onSubmit(data, { setSubmitting });
  };

  return (
    <FormWrapper onSubmit={handleDelete}>
      <Typography variant="h4" gutterBottom>
        Are you <strong>sure</strong> you want to permanently delete{" "}
        <strong>{data.name}</strong>?
      </Typography>
      <Typography paragraph>
        This will remove this POI from ALL houses for the region (even if they
        are in the 'selected' column of a home).
      </Typography>
      <HCButton
        type="submit"
        loading={isSubmitting}
        variant="contained"
        color="primary"
        style={{ marginRight: "10px" }}
      >
        Yes, delete the POI
      </HCButton>
      <Button variant="contained" onClick={onClose}>
        No, go back
      </Button>
    </FormWrapper>
  );
}

PoiDeleteModal.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PoiDeleteModal;
