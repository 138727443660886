import React from "react";
import PropTypes from "prop-types";
import { PanelHeader } from "components/Panel";
import PoiDataForm from "./PoiDataForm";

function PoiNewPanel({ onNew, pois, parentCategory }) {
  const hasReservation =
    parentCategory === "dine" ||
    parentCategory === "play" ||
    parentCategory === "spa"
      ? true
      : false;

  const hasMenu = parentCategory === "dine" || parentCategory === "spa";

  const hasDirectory = parentCategory === "shop";

  if (!pois.id) return null;

  return (
    <div>
      <PanelHeader
        header={`Create new "${pois.name}" POI`}
        subHeader={pois.name}
        icon={pois.icon}
      />
      <PoiDataForm
        onSubmit={onNew}
        withReservation={hasReservation}
        withMenu={hasMenu}
        withDirectory={hasDirectory}
        cta="Create POI"
      />
    </div>
  );
}

PoiNewPanel.propTypes = {
  parentCategory: PropTypes.string.isRequired,
  pois: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    icon: PropTypes.string,
    description: PropTypes.string,
    key: PropTypes.string,
    url: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        address_line_1: PropTypes.string,
        address_line_2: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.number,
        image_url: PropTypes.string,
        is_approved: PropTypes.number,
        is_default: PropTypes.number,
        name: PropTypes.string,
        sequence_no: PropTypes.number,
        state: PropTypes.string
      })
    )
  })
};

export default PoiNewPanel;
