import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core";

import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";

import { FormWrapper } from "components/FormWrapper";
import { InputField } from "components/InputField";
import { HCButton } from "components/HCButton";
import { ImageUpload } from "components/ImageUpload";

import useAlertsDispatch from "hooks/useAlertsDispatch";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  root: {},
}));

const FeatureCategoryEditFormValidationSchema = Yup.object().shape({
  name: Yup.string().required("You must enter a name for the option."),
  description: Yup.string(),
  defaultText: Yup.string(),
  icon: Yup.mixed().required("You must upload an icon for the category."),
});

function FeatureCategoryEditForm({
  onSubmit,
  name = "",
  description = "",
  icon,
  isActive,
  className,
}) {
  const classes = useStyles();
  const { addAlert } = useAlertsDispatch();
  const initialValues = {
    name: name || "",
    description: description || "",
    icon: icon || "",
    isActive: isActive || "",
  };

  const handleImageUploadError = (file, message) => {
    addAlert({
      intent: "error",
      text: message,
    });
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={FeatureCategoryEditFormValidationSchema}
      >
        {({ isSubmitting, handleSubmit }) => (
          <FormWrapper onSubmit={handleSubmit}>
            <ImageUpload
              name="icon"
              id="icon"
              label="Icon"
              onError={handleImageUploadError}
            />
            <InputField
              full
              name="name"
              id="name"
              variant="outlined"
              label="Name of option"
            />
            <InputField
              full
              multiline
              rows={4}
              name="description"
              id="description"
              variant="outlined"
              label="Description"
            />

            <HCButton
              loading={isSubmitting}
              variant="contained"
              type="submit"
              color="primary"
              className={{ root: classes.submit }}
              fullWidth
            >
              Update Category
            </HCButton>
          </FormWrapper>
        )}
      </Formik>
    </div>
  );
}

FeatureCategoryEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  defaultText: PropTypes.string,
  isActive: PropTypes.number,
  className: PropTypes.string,
};

export default FeatureCategoryEditForm;
