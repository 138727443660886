import React from "react";
import ReactDOM from "react-dom";

import "./index.css";

import { Provider } from "react-redux";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";

import * as serviceWorker from "./serviceWorker";
import store from "./store";
import theme from "./theme";

import App from "./App";
import { ThemeProvider } from "@material-ui/core";
import { DnDScrollProvider } from "components/DnDScroll";

ReactDOM.render(
  <Provider store={store}>
    <DndProvider backend={Backend}>
      <DnDScrollProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </DnDScrollProvider>
    </DndProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
