import React from "react";
import PropTypes from "prop-types";

import { Helmet } from "react-helmet";
import { Switch, Route } from "react-router-dom";

import { DashboardPage } from "components/Dashboard";
import { IconsRoot } from "./components";
function Icons({ match }) {
  return (
    <DashboardPage>
      <Helmet>
        <title>Icons - Home Concierge Super Admin</title>
      </Helmet>
      <Switch>
        <Route path={`${match.url}`} component={IconsRoot} />
      </Switch>
    </DashboardPage>
  );
}

Icons.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Icons;
