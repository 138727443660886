import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { useDrop } from "react-dnd";
import DnDScrollContext from "./DnDScrollContext";

const useBoxStyles = makeStyles((theme) => ({
  zone: ({ position }) => ({
    position: "fixed",
    left: 0,
    width: "100%",
    ...(position === "top" ? { top: 0 } : { bottom: 0 }),

    height: "50px",
    zIndex: 999999999,
  }),
}));

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },

  scroll: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
}));

const DnDScrollBox = ({ position = "bottom" }) => {
  const classes = useBoxStyles({ position });
  const boxRef = useRef();

  const [, drop] = useDrop({
    accept: "TABLE_ROW",
    hover: (item, monitor) => {
      if (position === "top") {
        window.scrollBy(0, -5);
      }
      if (position === "bottom") {
        window.scrollBy(0, 5);
      }
    },
  });
  drop(boxRef);
  return <div ref={boxRef} className={classes.zone} />;
};

DnDScrollBox.propTypes = {
  position: PropTypes.oneOf(["bottom", "top"]),
};

function DnDScrollProvider({ children }) {
  const classes = useStyles();
  const [active, setActive] = useState(false);

  return (
    <DnDScrollContext.Provider value={{ active, setActive }}>
      <div className={classes.root}>
        <div className={classes.content}>{children}</div>
        {active && (
          <>
            <DnDScrollBox position="top" />
            <DnDScrollBox position="bottom" />
          </>
        )}
      </div>
    </DnDScrollContext.Provider>
  );
}

DnDScrollProvider.propTypes = {
  children: PropTypes.any,
};

export default DnDScrollProvider;
