import React from "react";
import PropTypes from "prop-types";

import { DashboardPage } from "components/Dashboard";

import { Route, Switch } from "react-router-dom";

import { AdminUsersIndex, AdminUsersEdit, AdminUsersNew } from "./components";

import { Helmet } from "react-helmet";

function AdminUsers({ match }) {
  return (
    <DashboardPage>
      <Helmet>
        <title>Admin Users - Home Concierge Super Admin</title>
      </Helmet>
      <Switch>
        <Route path={`${match.url}`} exact component={AdminUsersIndex} />
        <Route path={`${match.url}/new`} exact component={AdminUsersNew} />
        <Route
          path={`${match.url}/:id/edit`}
          exact
          component={AdminUsersEdit}
        />
      </Switch>
    </DashboardPage>
  );
}

AdminUsers.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default AdminUsers;
