import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Paper, Fab } from "@material-ui/core";
import { InputEditor } from "components/InputEditor";
import { FullImageUpload } from "components/ImageUpload";
import RemoveIcon from "@material-ui/icons/Remove";
import useAlertsDispatch from "hooks/useAlertsDispatch";
const useStyles = makeStyles((theme) => ({
  form: {
    background: theme.palette.grey[100],
    maxWidth: 768,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(3),
    "&:first-of-type": {
      marginTop: 0,
    },
  },
  header: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    background: theme.palette.grey[200],
  },
  fields: {
    padding: theme.spacing(2),
  },
  imageUploader: {},
}));

function FeaturePageForm({ page, name, onRemove }) {
  const classes = useStyles();

  const { addAlert } = useAlertsDispatch();

  const handleImageError = (file, error) => {
    addAlert({
      intent: "error",
      text: error,
    });
  };

  return (
    <Paper className={classes.form} variant="outlined">
      <div className={classes.header}>
        <Fab
          variant="extended"
          onClick={onRemove}
          color="secondary"
          size="medium"
        >
          <RemoveIcon style={{ marginRight: 5 }} />
          Remove Page
        </Fab>
      </div>
      <div className={classes.fields}>
        <FullImageUpload
          name={`${name}.image`}
          id="image"
          label="Image"
          onError={handleImageError}
        />
        <InputEditor full name={`${name}.description`} />
      </div>
    </Paper>
  );
}

FeaturePageForm.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    image_url: PropTypes.string,
  }),
  name: PropTypes.string,
};

export default FeaturePageForm;
