import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core";

import FeatureCategoryEditForm from "./FeatureCategoryEditForm";

import { PanelHeader } from "components/Panel";

import useSystemDispatch from "hooks/useSystemDispatch";
import useAlertsDispatch from "hooks/useAlertsDispatch";

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {
    marginTop: theme.spacing(2),
  },
}));

function FeatureCategoryEdit({ category, onClose, categoryData }) {
  const classes = useStyles();
  const categoryId = categoryData.id;
  const { updateFeatureCategory } = useSystemDispatch();
  const { addAlert } = useAlertsDispatch();

  const handleSubmit = (values, actions) => {
    updateFeatureCategory(category, categoryId, values)
      .then((category) => {
        addAlert({
          intent: "success",
          text: `Succesfully updated feature category "${category.title}"`,
        });
        onClose();
        actions.setSubmitting(false);
      })
      .catch((error) => {
        console.warn(error);
        addAlert({
          intent: "error",
          text: error.message,
        });
        actions.setSubmitting(false);
      });
  };

  if (!categoryData) return <p>NO FEATURE DATA</p>;
  return (
    <div className={classes.root}>
      <PanelHeader
        header={categoryData.title}
        subHeader={"House Features"}
        icon={categoryData.image_url}
      />
      <FeatureCategoryEditForm
        name={categoryData.title}
        description={categoryData.description}
        icon={categoryData.image_url}
        onSubmit={handleSubmit}
        className={classes.form}
      />
    </div>
  );
}

FeatureCategoryEdit.propTypes = {
  category: PropTypes.string.isRequired,
  categoryData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    description: PropTypes.string,
    default_text: PropTypes.string,
    is_active: PropTypes.number,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FeatureCategoryEdit;
