import React from "react";
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Link
} from "@material-ui/core";

import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "0px 15px",
    width: "30%",
    minWidth: "300px",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(3),
      width: "100%"
    }
  },
  statItemHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1)
  },
  statItemHeaderIcon: {
    marginRight: "0.5em",
    color: theme.palette.text.secondary
  },
  stateItemContent: {
    alignItems: "flex-end",
    display: "flex"
  },
  stateItemNoun: {
    fontWeight: "500",
    marginBottom: "4px",
    marginLeft: "5px"
  }
}));

function StatItem({ title, icon: Icon, value, noun, path }) {
  const classes = useStyles();
  return (
    <Card className={classes.root} variant="outlined">
      <Link
        underline="none"
        color="textPrimary"
        component={RouterLink}
        to={path}
      >
        <CardActionArea>
          <CardContent>
            <div className={classes.statItemHeader}>
              <Icon className={classes.statItemHeaderIcon} />
              <Typography variant="h5" color="textSecondary">
                {title}
              </Typography>
            </div>
            <div className={classes.stateItemContent}>
              <Typography variant="h3">{value}</Typography>
              <Typography
                variant="h6"
                className={classes.stateItemNoun}
                color="textSecondary"
              >
                {noun}
              </Typography>
            </div>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
}

StatItem.propTypes = {};

export default StatItem;
