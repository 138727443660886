import React from "react";
import { makeStyles } from "@material-ui/core";

import { useEffect } from "react";

import { UsersTable, UsersTableSkeleton } from "../../../components/UsersTable";
import { DashboardPageHeader } from "../../../components/Dashboard";
import { SkeletonWrapper } from "../../../components/SkeletonWrapper";

import useSystemState from "../../../hooks/useSystemState";
import useSystemDispatch from "../../../hooks/useSystemDispatch";
import useAlertsDispatch from "../../../hooks/useAlertsDispatch";
import useLoadingState from "../../../hooks/useLoadingState";
import computeSubscription from "utilities/computeSubscription";

const useStyles = makeStyles((theme) => ({
  searchField: {
    maxWidth: "320px",
    width: "100%",
  },
  root: {},
}));

function UsersIndex() {
  const classes = useStyles();

  const { getAllUsers, deleteUser } = useSystemDispatch();
  const { users } = useSystemState();
  const { getAllUsers: getAllUsersLoading } = useLoadingState();
  const { addAlert } = useAlertsDispatch();

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers, users.length]);

  // handles delete from user table
  const handleUserDelete = (user, closeModal) => {
    deleteUser(user.id)
      .then(() => {
        addAlert({
          intent: "success",
          text: `Succesfully deleted user "${user.firstName} ${user.lastName}"`,
        });
        closeModal();
      })
      .catch((error) => {
        console.warn(error);
        addAlert({
          intent: "error",
          text: `There was an error while deleting user "${user.firstName} ${user.lastName}". Please try again later.`,
        });
      });
  };

  const usersRows = users.map(
    ({ id, email, firstName, lastName, created_at, plan_id }) => {
      const subscription = computeSubscription(plan_id);
      return {
        id,
        email,
        firstName,
        lastName,
        created_at,
        plan: subscription
          ? `${subscription.name} (${subscription.cycle})`
          : null,
      };
    }
  );

  return (
    <div className={classes.root}>
      <DashboardPageHeader
        cta={{ path: "/dashboard/users/new", text: "Create Gift Account" }}
      >
        Users
      </DashboardPageHeader>

      <SkeletonWrapper
        skeleton={UsersTableSkeleton}
        loading={getAllUsersLoading.loading}
      >
        <UsersTable users={usersRows} onDelete={handleUserDelete} />
      </SkeletonWrapper>
    </div>
  );
}

UsersIndex.propTypes = {};

export default UsersIndex;
