import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { FormWrapper } from "components/FormWrapper";
import { AddressField } from "components/AddressField";
import { HCButton } from "components/HCButton";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  submit: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  }
}));

const SingleDestinationFormValidationSchema = Yup.object().shape({
  destination: Yup.object()
    .shape({
      formattedAddress: Yup.mixed(),
      lat: Yup.mixed(),
      lng: Yup.mixed()
    })
    .test(
      "is-valid-addr",
      "This address does not look valid.",
      value =>
        value.address_line_1 ||
        (value.lat && value.lng && value.formattedAddress)
    )
});

function SingleDestinationForm({ destination = {}, onSubmit }) {
  const classes = useStyles();

  const initialValues = {
    destination: {
      formattedAddress: `${destination.address_line_1 || ""}${
        destination.address_line_2 ? ", " + destination.address_line_2 : ""
      }${destination.city ? ", " + destination.city : ""}${
        destination.state ? ", " + destination.state : ""
      } ${destination.zip || ""} ${
        destination.country ? ", " + destination.country : ""
      }`,
      address_line_1: destination.address_line_1 || "",
      address_line_2: destination.address_line_2 || "",
      city: destination.city || "",
      country: destination.country || "",
      lat: destination.latitude || "",
      lng: destination.longitude || "",
      place_id: destination.place_id || "",
      state: destination.state || "",
      zip: destination.zip || ""
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={SingleDestinationFormValidationSchema}
    >
      {({ values, isSubmitting, handleSubmit }) => (
        <FormWrapper onSubmit={handleSubmit}>
          <AddressField name="destination" placeholder="Address" />
          <HCButton
            type="submit"
            variant="contained"
            color="primary"
            className={{ root: classes.submit }}
            loading={isSubmitting}
            disabled={JSON.stringify(initialValues) === JSON.stringify(values)}
          >
            Update User Data
          </HCButton>
        </FormWrapper>
      )}
    </Formik>
  );
}

SingleDestinationForm.propTypes = {
  destination: PropTypes.shape({
    address_line_1: PropTypes.string,
    address_line_2: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    place_id: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string
  })
};

export default SingleDestinationForm;
