import React from "react";
import PropTypes from "prop-types";
import { FieldArray, Formik } from "formik";
import { makeStyles, Fab } from "@material-ui/core";
import { FormWrapper } from "components/FormWrapper";
import DestSelectorField from "./DestSelectorField";
import { HCButton } from "components/HCButton";
import * as Yup from "yup";

import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
  root: {},
  repeaterRoot: {
    width: "100%",
    textAlign: "center"
  },
  repeaterAddBtn: {
    marginTop: theme.spacing(1)
  }
}));

const DestSelectorValidationSchema = Yup.object().shape({
  destinations: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().nullable(),
        address: Yup.object()
          .shape({
            formattedAddress: Yup.string()
          })
          .test("is-valid-addr", "This address does not look valid.", value => {
            return value.formattedAddress && value.address_line_1;
          })
      })
    )
    .required("OK!")
});

const formatDestinations = destinations =>
  destinations.map(destination => ({
    id: destination.id,
    address: {
      ...destination,
      lat: destination.latitude,
      lng: destination.longitude,
      formattedAddress: `${destination.address_line_1 || ""}${
        destination.address_line_2 ? ", " + destination.address_line_2 : ""
      }${destination.city ? ", " + destination.city : ""}${
        destination.state ? ", " + destination.state : ""
      } ${destination.zip || ""} ${
        destination.country ? ", " + destination.country : ""
      }`
    }
  }));

function DestSelector({ destinations, onSubmit, onDelete }) {
  const classes = useStyles();
  const initialValues = {
    destinations: formatDestinations(destinations)
  };

  const handleDestDelete = (arrayHelpers, index, destination) => {
    return () => {
      if (destination.id === null) {
        arrayHelpers.remove(index);
        return;
      }
      onDelete(destination.id);
    };
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={DestSelectorValidationSchema}
        enableReinitialize={true}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <FormWrapper onSubmit={handleSubmit} className={classes.form}>
            <FieldArray
              name="destinations"
              render={arrayHelpers => (
                <div className={classes.repeaterRoot}>
                  {values.destinations.map((destination, index) => (
                    <DestSelectorField
                      full
                      key={destination.id ? destination.id : `index-${index}`}
                      name={`destinations.${index}.address`}
                      variant="outlined"
                      onDelete={handleDestDelete(
                        arrayHelpers,
                        index,
                        destination
                      )}
                    />
                  ))}
                  <Fab
                    variant="extended"
                    className={classes.repeaterAddBtn}
                    size="small"
                    onClick={() =>
                      arrayHelpers.push({
                        id: null,
                        address: { formattedAddress: "" }
                      })
                    }
                  >
                    <AddIcon className={classes.addIcon} />
                    Add new Destination
                  </Fab>
                </div>
              )}
            />
            <HCButton
              variant="contained"
              type="submit"
              color="primary"
              loading={isSubmitting}
              disabled={
                JSON.stringify(values.destinations) ===
                JSON.stringify(formatDestinations(destinations))
              }
            >
              Update Destinations
            </HCButton>
          </FormWrapper>
        )}
      </Formik>
    </div>
  );
}

DestSelector.propTypes = {
  destinations: PropTypes.arrayOf(
    PropTypes.shape({
      address_line_1: PropTypes.string,
      street: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      zip: PropTypes.string,
      lat: PropTypes.string,
      lng: PropTypes.string
    })
  ),
  onSubmit: PropTypes.func.isRequired
};

export default DestSelector;
