import HomeIcon from "@material-ui/icons/Home";
import MapIcon from "@material-ui/icons/Map";
import PersonIcon from "@material-ui/icons/Person";
import NoteIcon from "@material-ui/icons/Note";
import HouseIcon from "@material-ui/icons/House";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import ImageIcon from "@material-ui/icons/Image";

import Users from "views/Users";
import AdminUsers from "views/AdminUsers";
import Splash from "views/Splash";
import Homes from "views/Homes";
import Regions from "views/Regions";
import Features from "views/Features";
import Icons from "views/Icons";

export default [
  {
    text: "Dashboard",
    key: "dashboard",
    path: "/dashboard",
    icon: HomeIcon,
    component: Splash,
    exact: true,
    isPrivate: true,
    location: "main",
  },
  {
    text: "Regions",
    key: "regions",
    path: "/dashboard/regions",
    icon: MapIcon,
    component: Regions,
    isPrivate: true,
    location: "main",
  },
  {
    text: "Users",
    key: "users",
    path: "/dashboard/users",
    icon: PersonIcon,
    component: Users,
    isPrivate: true,
    location: "main",
  },
  {
    text: "Homes",
    key: "homes",
    path: "/dashboard/homes",
    icon: HouseIcon,
    component: Homes,
    isPrivate: true,
    location: "main",
  },
  {
    text: "Admin Users",
    key: "adminUsers",
    path: "/dashboard/admin",
    icon: SupervisorAccountIcon,
    component: AdminUsers,
    isPrivate: true,
    location: "sub",
  },
  {
    text: "Features",
    key: "features",
    path: "/dashboard/features",
    icon: NoteIcon,
    component: Features,
    isPrivate: true,
    location: "main",
  },
  {
    text: "Icons",
    key: "icons",
    path: "/dashboard/icons",
    icon: ImageIcon,
    component: Icons,
    isPrivate: true,
    location: "main",
  },
];
