import { useSelector } from "react-redux";

const useRegionState = () => {
  return {
    region: useSelector((state) => state.region),
    homes: useSelector((state) => state.region.homes),
    hosts: useSelector((state) => state.region.hosts),
    links: useSelector((state) => state.region.links),
    pois: useSelector((state) => state.region.pois),
    tvOffice: useSelector((state) => state.region.tvOffice),
    emergency: useSelector((state) => state.region.emergency),
    general: useSelector((state) => state.region.general),
    airport_details: useSelector((state) => state.region.airport_details),
    guest_services: useSelector((state) => state.region.guest_services),
    suggestions: useSelector((state) => state.region.suggestions),
    archived: useSelector((state) => state.region.archived),
  };
};

export default useRegionState;
