import React from "react";
import PropTypes from "prop-types";
import { Modal, Fade, Backdrop, Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "768px",
    margin: "0 auto"
  },
  modalOuter: {},
  modalInner: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

function HCModal({ open, handleClose, lock, children }) {
  const classes = useStyles();
  return (
    <Modal
      disableAutoFocus
      className={classes.root}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      disableBackdropClick={lock}
    >
      <Fade in={open}>
        <Paper className={classes.modalInner}>{children}</Paper>
      </Fade>
    </Modal>
  );
}

HCModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
};

export default HCModal;
