import React from "react";
import PropTypes from "prop-types";

import { Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

import { DashboardPage } from "components/Dashboard";

import { RegionsIndex, RegionsNew } from "./components";
import RegionView from "./components/RegionView";

function Regions({ match }) {
  return (
    <DashboardPage>
      <Helmet>
        <title>Regions - Home Concierge Super Admin</title>
      </Helmet>
      <Switch>
        <Route path={`${match.url}`} exact component={RegionsIndex} />
        <Route path={`${match.url}/new`} exact component={RegionsNew} />
        <Route path={`${match.url}/:id`} component={RegionView} />
      </Switch>
    </DashboardPage>
  );
}

Regions.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
};

export default Regions;
