import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { FormWrapper } from "components/FormWrapper";
import { InputField } from "components/InputField";
import { MenuItem, makeStyles } from "@material-ui/core";
import { SelectField } from "components/SelectField";
import { HCButton } from "components/HCButton";
import momentTZ from "moment-timezone";

import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
}));

const RegionNewFormValidationSchema = Yup.object().shape({
  name: Yup.string().required("You must enter a name for the region."),
  description: Yup.string(),
  timezone: Yup.string().required("You must select a timezone for the region."),
});

function RegionNewForm({ onSubmit }) {
  const classes = useStyles();

  const initialValues = {
    name: "",
    description: "",
    timezone: "",
  };

  const timeZonesList = useMemo(() => momentTZ.tz.zonesForCountry("US"), []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={RegionNewFormValidationSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <FormWrapper onSubmit={handleSubmit}>
          <InputField
            full
            name="name"
            id="name"
            variant="outlined"
            label="Name of region"
          />
          <InputField
            full
            multiline
            rows={4}
            name="description"
            id="description"
            variant="outlined"
            label="Description of region"
          />
          <SelectField
            full
            id="timezone"
            name="timezone"
            label="Region timezone"
          >
            {timeZonesList.map((tz) => (
              <MenuItem key={tz} value={tz}>
                {tz}
              </MenuItem>
            ))}
          </SelectField>
          <HCButton
            type="submit"
            variant="contained"
            color="primary"
            className={{ root: classes.submit }}
            loading={isSubmitting}
          >
            Create Region
          </HCButton>
        </FormWrapper>
      )}
    </Formik>
  );
}

RegionNewForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default RegionNewForm;
