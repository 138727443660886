import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BackButton } from "components/BackButton";
import { DashboardPageHeader } from "components/Dashboard";
import useRegionState from "hooks/useRegionState";
import useRegionDispatch from "hooks/useRegionDispatch";
import { SkeletonWrapper } from "components/SkeletonWrapper";
import RoutedTabbedSectionSkeleton from "components/TabbedSection/RoutedTabbedSectionSkeleton";
import { RoutedTabbedSection } from "components/TabbedSection";
import { Image } from "components/Image";
import { makeStyles, Typography } from "@material-ui/core";
import useAlertsDispatch from "hooks/useAlertsDispatch";
import SingleDestinationForm from "../components/SingleDestinationForm";
import BasicPoiTable from "../components/BasicPoiTable";
import { PanelProvider } from "components/Panel";
import LinkForm from "../components/LinkForm";

const useStyles = makeStyles(theme => ({
  mainTabImage: {
    width: 138,
    height: 103
  }
}));

function RegionAirportDetails({ match }) {
  const classes = useStyles();

  const { region, airport_details } = useRegionState();
  const {
    getRegionAirportDetails,

    moveRegionItemSequence,
    commitRegionItemSequences,
    updateAirportWebsite,
    updateAirportDirections,
    updateTerminalMapUrl,
    updateRentalMapUrl
  } = useRegionDispatch();

  const { addAlert } = useAlertsDispatch();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!region.id) return;
    getRegionAirportDetails(region.id)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [getRegionAirportDetails, region.id]);

  const handleUpdateTerminalMapUrl = (values, actions) => {
    updateTerminalMapUrl(region.id, values.url)
      .then(() => {
        addAlert({
          intent: "success",
          text: "Succesfully updated "
        });
        actions.setSubmitting(false);
      })
      .catch(err => {
        console.error(err);
        addAlert({
          intent: "error",
          text: "There was an error while updating airport details."
        });
        actions.setSubmitting();
      });
  };

  const handleUpdateRentalMapUrl = (values, actions) => {
    updateRentalMapUrl(region.id, values.url)
      .then(() => {
        addAlert({
          intent: "success",
          text: "Succesfully updated"
        });
        actions.setSubmitting();
      })
      .catch(err => {
        console.error(err);
        addAlert({
          intent: "error",
          text: "There was an error while updating rental map url."
        });
        actions.setSubmitting();
      });
  };

  const handleUpdateAirportWebsite = (values, actions) => {
    updateAirportWebsite(region.id, values.url)
      .then(() => {
        addAlert({
          intent: "success",
          text: "Succesfully updated airport website."
        });
        actions.setSubmitting(false);
      })
      .catch(err => {
        console.error(err);
        addAlert({
          intent: "error",
          text: "There was an error updating airport website"
        });
        actions.setSubmitting(false);
      });
  };

  const handleUpdateAirportDirections = (values, actions) => {
    updateAirportDirections(region.id, values.destination)
      .then(() => {
        addAlert({
          intent: "success",
          text: "Successfully updated airport directions."
        });
        actions.setSubmitting(false);
      })
      .catch(err => {
        addAlert({
          intent: "error",
          text: "There was an error while updating airport directions."
        });
        actions.setSubmitting(false);
      });
  };

  const handleFeatureTableDrop = category => {
    return () => {
      commitRegionItemSequences(
        region.id,
        "airport_details",
        category,
        airport_details[category].id
      );
    };
  };

  const handleFeatureTableMove = category => {
    return (draggedItem, droppedItem) => {
      moveRegionItemSequence(
        "airport_details",
        category,
        draggedItem.id,
        droppedItem.sequence
      );
    };
  };

  if (error)
    return (
      <Typography variant="body1" color="textSecondary">
        There was an error getting the airport details for the region.
      </Typography>
    );

  return (
    <PanelProvider>
      {region && (
        <BackButton path={`/dashboard/regions/${region.id}`}>
          Back to {region.name}
        </BackButton>
      )}
      <DashboardPageHeader subHeader={region ? region.name : "..."}>
        Airport Details
      </DashboardPageHeader>

      <SkeletonWrapper skeleton={RoutedTabbedSectionSkeleton} loading={loading}>
        {airport_details && (
          <RoutedTabbedSection
            tabs={[
              airport_details.terminal_map || null,
              airport_details.airport_website || null,
              airport_details.car_rentals || null,
              airport_details.airport_directions || null,
              airport_details.taxis || null,
              airport_details.rental_map || null,
              airport_details.private_cars || null
            ]
              .filter(val => val !== null)
              .map((cat, i) => ({
                icon: (
                  <Image
                    src={cat.icon}
                    alt={cat.name}
                    classes={{ root: classes.mainTabImage }}
                  />
                ),
                label: cat.name,
                path: `${match.url}/${cat.key}`,
                index: i
              }))}
          >
            <LinkForm
              url={airport_details.terminal_map.url}
              onSubmit={handleUpdateTerminalMapUrl}
            />

            <LinkForm
              url={airport_details.airport_website.url}
              onSubmit={handleUpdateAirportWebsite}
            />
            <BasicPoiTable
              pois={airport_details.car_rentals}
              onDrop={handleFeatureTableDrop("car_rentals")}
              onMove={handleFeatureTableMove("car_rentals")}
              stateKey="airport_details"
              category="car_rentals"
              categoryLabel="Car Rentals"
            />

            <SingleDestinationForm
              destination={
                airport_details.airport_directions.address || undefined
              }
              onSubmit={handleUpdateAirportDirections}
            />
            <BasicPoiTable
              pois={airport_details.taxis}
              onDrop={handleFeatureTableDrop("taxis")}
              onMove={handleFeatureTableMove("taxis")}
              stateKey="airport_details"
              category="taxis"
              categoryLabel="Taxis"
            />
            <LinkForm
              url={airport_details.rental_map.url}
              onSubmit={handleUpdateRentalMapUrl}
            />
            <BasicPoiTable
              pois={airport_details.private_cars}
              onDrop={handleFeatureTableDrop("private_cars")}
              onMove={handleFeatureTableMove("private_cars")}
              stateKey="airport_details"
              category="private_cars"
              categoryLabel="Private Cars"
            />
          </RoutedTabbedSection>
        )}
      </SkeletonWrapper>
    </PanelProvider>
  );
}

RegionAirportDetails.propTypes = {
  match: PropTypes.object.isRequired
};

export default RegionAirportDetails;
