import React from "react";
import PropTypes from "prop-types";

import { ClickAwayListener, Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    height: "100%",
    width: "100%",

    top: 0,
    left: 0,
    zIndex: theme.zIndex.modal,
    "&::after": {
      content: "''",
      display: "block",
      position: "relative",
      backgroundColor: "black",
      height: "100%",
      width: "100%",
      top: 0,
      left: 0,
      opacity: 0.3
    },

    "&.panelSlide-enter-active": {
      "&::after": {
        opacity: 0.3,
        transition: `opacity 200ms ${theme.transitions.easing.easeOut}`
      },
      "& > div": {
        transform: "translateX(0%)",
        transition: `transform 300ms ${theme.transitions.easing.easeOut}`
      }
    },

    "&.panelSlide-enter": {
      "&::after": {
        opacity: 0
      },
      "& > div": {
        transform: "translateX(100%)"
      }
    },

    "&.panelSlide-exit-active": {
      "&::after": {
        opacity: 0,
        transition: `opacity 200ms ${theme.transitions.easing.easeOut}`
      },
      "& > div": {
        transform: "translateX(100%)",
        transition: `transform 300ms ${theme.transitions.easing.easeOut}`
      }
    },
    "&.panelSlide-exit": {
      "&::after": {
        opacity: 0.3
      },
      "& > div": {
        transform: "translateX(0%)"
      }
    }
  },
  paper: {
    height: "100vh",
    width: "auto",
    maxWidth: "900px",
    position: "absolute",
    right: 0,
    overflowY: "scroll",
    zIndex: theme.zIndex.modal + 1,
    padding: theme.spacing(4)
  }
}));

function PanelContent({ onClose, children, lock = false }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ClickAwayListener
        onClickAway={onClose}
        mouseEvent={lock ? false : "onMouseDown"}
        touchEvent={lock ? false : "onTouchEnd"}
      >
        <Paper className={classes.paper}>{children}</Paper>
      </ClickAwayListener>
    </div>
  );
}

PanelContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
};

export default PanelContent;
