import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../actions/loading";
import { loginUser, logoutUser } from "../actions/user";
import api from "../utilities/API";

const useUserDispatch = () => {
  const dispatch = useDispatch();

  return {
    loginUser: useCallback(
      async (email, password) => {
        // set loading for login
        dispatch(
          setLoading({ requestName: "login", requestStatus: "REQUEST" })
        );
        try {
          // attempt to login user
          const userData = await api.loginUser(email, password);
          // set user object and success flag

          dispatch(loginUser(userData));
          dispatch(
            setLoading({ requestName: "login", requestStatus: "SUCCESS" })
          );
          return userData;
        } catch (err) {
          // if err, set loading as failed and throw error
          dispatch(
            setLoading({ requestName: "login", requestStatus: "FAILED" })
          );

          if (err.response) {
            if (err.response.status === 401)
              throw new Error("Invalid username/password.");
          }

          if (err.message) {
            console.warn(err.message);
            throw new Error(err.message);
          }

          throw new Error(
            "There was an unknown error while logging in. Please try again."
          );
        }
      },
      [dispatch]
    ),
    logoutUser: useCallback(async () => {
      dispatch(logoutUser());
      try {
        await api.clearStorage();
        await api.logoutUser();
      } catch (error) {
        throw error;
      }
    }, [dispatch]),
    checkAuth: useCallback(async () => {
      dispatch(
        setLoading({ requestName: "storage", requestStatus: "REQUEST" })
      );
      try {
        const userData = await api.checkStorage();
        dispatch(loginUser(userData));
        dispatch(
          setLoading({ requestName: "storage", requestStatus: "SUCCESS" })
        );
        return userData;
      } catch (error) {
        dispatch(
          setLoading({ requestName: "storage", requestStatus: "FAILED" })
        );
        throw error;
      }
    }, [dispatch]),
  };
};

export default useUserDispatch;
