import React from "react";

import AlertItem from "./AlertItem";
import useAlertsState from "../../hooks/useAlertsState";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    zIndex: theme.zIndex.snackbar,
    padding: theme.spacing(5),
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "flex-end",
    bottom: 0,
    right: 0,
    width: "auto",
  },
}));

function Alerts() {
  const classes = useStyles();
  const { alerts } = useAlertsState();

  if (!alerts || alerts.length === 0) return null;

  return (
    <div className={classes.root}>
      {alerts.map(({ id, intent, text }) => (
        <AlertItem key={id} id={id} intent={intent}>
          {text}
        </AlertItem>
      ))}
    </div>
  );
}

Alerts.propTypes = {};

export default Alerts;
