import React from "react";
import { Formik } from "formik";
import { FormWrapper } from "components/FormWrapper";
import PoiImageUploader from "../RegionPois/components/PoiImageUploader";
import { makeStyles } from "@material-ui/core";
import useAlertsDispatch from "hooks/useAlertsDispatch";
import { InputField } from "components/InputField";
import { HCButton } from "components/HCButton";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(4),
  },
  submit: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  imageUploader: {
    marginBottom: theme.spacing(2),
  },
}));

const BasicPoiFormValidationSchema = Yup.object().shape({
  image: Yup.mixed()
    .nullable()
    .required("You must upload an image for the POI.")
    .test(
      "is-valid-img",
      "This image does not look valid",
      (value) =>
        ("File" in window && value instanceof File) || typeof value === "string"
    ),
  name: Yup.string().required("Name is required"),
  website: Yup.string().url("Invaid URL").required("You must enter a URL."),
});

function BasicPoiForm({ data = {}, cta = "Save", onSubmit }) {
  const classes = useStyles();
  const { addAlert } = useAlertsDispatch();

  const initialValues = {
    image: data.image_url || "",
    name: data.name || "",
    website: data.website || "",
  };

  const handleImageError = (error) => {
    addAlert({ intent: "error", text: error });
  };
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={BasicPoiFormValidationSchema}
    >
      {({ isSubmitting, values, handleSubmit }) => (
        <FormWrapper onSubmit={handleSubmit} className={classes.form}>
          <PoiImageUploader
            id="image"
            name="image"
            className={classes.imageUploader}
            onError={handleImageError}
          />
          <InputField
            full
            id="name"
            name="name"
            variant="outlined"
            label="Name"
          />

          <InputField
            full
            id="website"
            name="website"
            variant="outlined"
            label="Website"
          />
          <HCButton
            type="submit"
            variant="contained"
            color="primary"
            className={{ root: classes.submit }}
            loading={isSubmitting}
          >
            {cta}
          </HCButton>
        </FormWrapper>
      )}
    </Formik>
  );
}

export default BasicPoiForm;
