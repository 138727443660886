import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { FormWrapper } from "components/FormWrapper";
import { URLField } from "components/URLField";
import { HCButton } from "components/HCButton";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing(4)
  },
  submit: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  }
}));

function PoiLinkForm({ onSubmit, link }) {
  const classes = useStyles();
  const initialValues = {
    link
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {({ isSubmitting, handleSubmit, values }) => (
        <FormWrapper onSubmit={handleSubmit} className={classes.form}>
          <URLField
            full
            name="link"
            id="link"
            variant="outlined"
            label="Link"
          />
          <HCButton
            type="submit"
            variant="contained"
            color="primary"
            className={{ button: classes.submit }}
            loading={isSubmitting}
            disabled={initialValues.link === values.link}
          >
            Update Link
          </HCButton>
        </FormWrapper>
      )}
    </Formik>
  );
}

PoiLinkForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  link: PropTypes.string
};

export default PoiLinkForm;
