import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BackButton } from "components/BackButton";
import { DashboardPageHeader } from "components/Dashboard";
import { UsersTable, UsersTableSkeleton } from "components/UsersTable";
import { SkeletonWrapper } from "components/SkeletonWrapper";
import computeSubscription from "utilities/computeSubscription";
import useRegionState from "hooks/useRegionState";
import useRegionDispatch from "hooks/useRegionDispatch";
import useSystemDispatch from "hooks/useSystemDispatch";
import useAlertsDispatch from "hooks/useAlertsDispatch";

function RegionHosts({ match }) {
  const { region, hosts } = useRegionState();
  const { getRegionHosts } = useRegionDispatch();
  const { deleteUser } = useSystemDispatch();
  const { addAlert } = useAlertsDispatch();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    if (!region.id) return;
    getRegionHosts(region.id)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [getRegionHosts, region.id]);

  const handleUserDelete = (user, closeModal) => {
    deleteUser(user.id)
      .then(() => {
        addAlert({
          intent: "success",
          text: `Succesfully deleted user "${user.firstName} ${user.lastName}"`,
        });
        closeModal();
      })
      .catch((error) => {
        console.warn(error);
        addAlert({
          intent: "error",
          text: `There was an error while deleting user "${user.firstName} ${user.lastName}". Please try again later.`,
        });
      });
  };

  if (error) return <p>Error getting hosts for region</p>;
  const hostRows = !hosts
    ? []
    : hosts.map(({ id, plan_id, first_name, last_name, email, createdAt }) => {
        const subscription = computeSubscription(plan_id);
        return {
          id: id,
          firstName: first_name,
          lastName: last_name,
          email,
          created_at: createdAt,
          plan: subscription
            ? `${subscription.name} (${subscription.cycle})`
            : null,
        };
      });

  return (
    <div>
      {region && (
        <BackButton path={`/dashboard/regions/${region.id}`}>
          Back to {region.name}
        </BackButton>
      )}
      <DashboardPageHeader subHeader={region ? region.name : "..."}>
        Hosts
      </DashboardPageHeader>
      <SkeletonWrapper skeleton={UsersTableSkeleton} loading={loading}>
        <UsersTable users={hostRows} onDelete={handleUserDelete} />
      </SkeletonWrapper>
    </div>
  );
}

RegionHosts.propTypes = {
  match: PropTypes.object.isRequired,
};

export default RegionHosts;
