import React, { useState } from "react";
import PropTypes from "prop-types";
import { Paper, Typography, Button, makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import HCModal from "../HCModal/HCModal";
import useLoadingState from "../../hooks/useLoadingState";

import { SearchField } from "../SearchField";
import useSearchQuery from "../../hooks/useSearchQuery";
import PaginatedTable from "../PaginatedTable/PaginatedTable";
import TableControls from "../TableControls";
import { FormWrapper } from "../FormWrapper";
import HCButton from "../HCButton/HCButton";

const useStyles = makeStyles((theme) => ({
  searchField: {
    maxWidth: "320px",
    width: "100%",
  },
}));

function UsersTable({ users, onDelete }) {
  const classes = useStyles();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [queuedDeleteData, setQueuedDeleteData] = useState({});

  const {
    getAllUsers: getAllUsersLoading,
    deleteUser: deleteUserLoading,
  } = useLoadingState();

  const [query, handleQueryChange, items] = useSearchQuery(users);

  const handleDeletePrompt = (evt, row) => {
    setQueuedDeleteData(row);
    setShowDeleteModal(true);
  };

  const handleDeletePromptClose = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteUser = (evt) => {
    evt.preventDefault();
    onDelete(queuedDeleteData, () => setShowDeleteModal(false));
  };

  if (getAllUsersLoading.loading || !users) return null;

  return (
    <>
      <Paper variant="outlined">
        <TableControls>
          <SearchField
            onChange={handleQueryChange}
            value={query}
            placeholder="Search users by email or name"
            className={classes.searchField}
          />
        </TableControls>

        <PaginatedTable
          query={query}
          headers={[
            {
              label: "User ID",
              dataKey: "id",
            },
            { label: "Email", dataKey: "email" },
            { label: "First Name", dataKey: "firstName" },
            { label: "Last Name", dataKey: "lastName" },
            { label: "Plan", dataKey: "plan" },
            { label: "Created at", dataKey: "created_at", date: true },
          ]}
          rows={items}
          actions={[
            { Icon: EditIcon, link: ({ id }) => `/dashboard/users/${id}/edit` },
            { Icon: DeleteIcon, onClick: handleDeletePrompt },
          ]}
          order="desc"
          orderBy="created_at"
        />
      </Paper>
      <HCModal
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
      >
        <FormWrapper onSubmit={handleDeleteUser}>
          <Typography variant="h4" gutterBottom>
            Are you <strong>sure</strong> you want to permanently delete{" "}
            <strong>
              {queuedDeleteData.firstName} {queuedDeleteData.lastName} (
              {queuedDeleteData.email})
            </strong>
            ?
          </Typography>
          <Typography paragraph>
            All of the users data and houses will be removed from our system,
            and will not be recoverable.
          </Typography>
          <HCButton
            loading={deleteUserLoading.loading}
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginRight: "10px" }}
          >
            Yes, I am Sure
          </HCButton>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeletePromptClose}
          >
            No, go back
          </Button>
        </FormWrapper>
      </HCModal>
    </>
  );
}

UsersTable.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      accountStatus: PropTypes.number,
      accountType: PropTypes.string,
      email: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phoneNumber: PropTypes.string,
      userType: PropTypes.string,
    })
  ),
  onDelete: PropTypes.func.isRequired,
};

export default UsersTable;
