import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Checkbox } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function DefaultCheckbox({ id, value, onCheck, disabled }) {
  const classes = useStyles();

  const handleChange = (event) => {
    if (onCheck) {
      onCheck(event, id);
    }
  };

  return (
    <div className={classes.root}>
      <Checkbox
        checked={value}
        onChange={handleChange}
        inputProps={{ "aria-label": "primary checkbox" }}
        disabled={disabled}
      />
    </div>
  );
}

DefaultCheckbox.propTypes = {
  id: PropTypes.number,
  value: PropTypes.bool.isRequired,
  onCheck: PropTypes.func,
  disabled: PropTypes.bool,
};

export default DefaultCheckbox;
