import React from "react";
import {
  Select,
  makeStyles,
  FormHelperText,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { useField } from "formik";

const useStyles = makeStyles(theme => ({
  select: props => ({
    width: "100%"
  }),
  formControl: props => ({
    width: "auto",
    flexBasis: props.half ? `calc(50% - ${theme.spacing(1) * 2}px)` : "100%",
    margin: theme.spacing(1)
  })
}));

function SelectField({ children, full, half, ...rest }) {
  const [field, meta] = useField(rest);
  const classes = useStyles({ full, half });
  const showError = Boolean(meta.error && meta.touched);
  return (
    <FormControl
      variant="outlined"
      error={showError}
      className={classes.formControl}
    >
      <InputLabel id={rest.id}>{rest.label}</InputLabel>
      <Select className={classes.select} {...field} {...rest}>
        {children}
      </Select>
      {showError && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
}

SelectField.propTypes = {};

export default SelectField;
