import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { FormWrapper } from "../FormWrapper";
import { InputField } from "../InputField";
import { AddressField } from "../AddressField";
import HCButton from "../HCButton/HCButton";
import { makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import * as Yup from "yup";
import PhoneField from "components/PhoneField";
import { URLField } from "components/URLField";

const useStyles = makeStyles(theme => ({
  submit: {},
  form: {
    marginTop: theme.spacing(4)
  },
  textSection: {
    marginTop: theme.spacing(2),
    "&:first-of-type": {
      marginTop: 0
    }
  }
}));

const HouseDataFormValidationSchema = Yup.object().shape({
  houseName: Yup.string().required("You must have a name for the house."),
  houseAddress: Yup.object()
    .required("You must enter a valid house address")
    .nullable(),
  homeOwnerWebsite: Yup.string().url("Invalid URL.")
});

function HouseDataForm({ data, handleUpdate }) {
  const initialValues = {
    houseName: data.houseName || "",
    houseAddress: {
      formattedAddress: data.address.fullAddress || "",
      street: data.address.street,
      city: data.address.city,
      state: data.address.state,
      country: data.address.country,
      zip: data.address.zip,
      lat: data.address.coordinates.lat,
      lng: data.address.coordinates.lng
    },
    homeOwnerName: data.ownerName || "",
    homeOwnerPhone: data.phone || "",
    homeOwnerEmail: data.email || "",
    homeOwnerWebsite: data.website || ""
  };

  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={HouseDataFormValidationSchema}
      onSubmit={handleUpdate}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <>
          <div className={classes.textSection}>
            <Typography variant="body1" color="textSecondary">
              Region:
            </Typography>
            <Typography
              variant="h5"
              color="primary"
              component={Link}
              to={`/dashboard/regions/${data.region_id}/`}
            >
              <strong>#{data.region_id}</strong>
            </Typography>
          </div>
          <div className={classes.textSection}>
            <Typography variant="body1" color="textSecondary">
              Owner:
            </Typography>
            <Typography
              variant="h5"
              color="primary"
              component={Link}
              to={`/dashboard/users/${data.user.id}/edit`}
            >
              <strong>{`${data.user.first_name} ${data.user.last_name} (${data.user.email})`}</strong>
            </Typography>
          </div>
          <FormWrapper onSubmit={handleSubmit} className={classes.form}>
            <InputField
              full
              name="houseName"
              id="houseName"
              variant="outlined"
              label="House Name"
            />
            <AddressField name="houseAddress" placeholder="House Address" />
            <InputField
              half
              name="homeOwnerName"
              id="homeOwnerName"
              variant="outlined"
              label="House Owner Name"
            />
            <PhoneField
              half
              type="tel"
              name="homeOwnerPhone"
              id="homeOwnerPhone"
              variant="outlined"
              label="House Owner Phone"
            />
            <InputField
              half
              name="homeOwnerEmail"
              id="homeOwnerEmail"
              variant="outlined"
              label="House Owner Email"
            />
            <URLField
              half
              name="homeOwnerWebsite"
              id="homeOwnerWebsite"
              variant="outlined"
              label="House Owner Website"
            />
            <HCButton
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              loading={isSubmitting}
              disabled={
                JSON.stringify(initialValues) === JSON.stringify(values)
              }
            >
              Update House Data
            </HCButton>
          </FormWrapper>
        </>
      )}
    </Formik>
  );
}

HouseDataForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    region_id: PropTypes.number.isRequired,
    email: PropTypes.string,
    houseName: PropTypes.string,
    isPublished: PropTypes.number,
    ownerName: PropTypes.string,
    phone: PropTypes.string,
    website: PropTypes.string,
    user: PropTypes.shape({
      email: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired
    }).isRequired
  }),
  handleUpdate: PropTypes.func.isRequired
};

export default HouseDataForm;
