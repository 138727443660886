import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core";

import RegionDeleteModal from "./RegionDeleteModal";

import { DashboardPageHeader } from "components/Dashboard";
import { SkeletonWrapper } from "components/SkeletonWrapper";
import { RegionsTable, RegionsTableSkeleton } from "components/RegionsTable";

import useAlertsDispatch from "hooks/useAlertsDispatch";
import useSystemDispatch from "hooks/useSystemDispatch";
import useSystemState from "hooks/useSystemState";
import useLoadingState from "hooks/useLoadingState";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function RegionsIndex() {
  const classes = useStyles();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [queuedDeleteData, setQueuedDeleteData] = useState(null);
  const { getAllRegions, deleteRegion } = useSystemDispatch();
  const { addAlert } = useAlertsDispatch();

  const { regions } = useSystemState();
  const {
    getAllRegions: getAllRegionsLoading,
    deleteRegion: deleteRegionLoading,
  } = useLoadingState();

  useEffect(() => {
    getAllRegions();
  }, [getAllRegions]);

  const handleDeletePrompt = (evt, data) => {
    setQueuedDeleteData(data);
    setShowDeletePrompt(true);
  };

  const handleDeleteConfirm = (evt) => {
    evt.preventDefault();
    deleteRegion(queuedDeleteData.id)
      .then(() => {
        addAlert({
          intent: "success",
          text: `Succesfully deleted region "${queuedDeleteData.name}".`,
        });
        setShowDeletePrompt(false);
        setQueuedDeleteData(null);
      })
      .catch((error) => {
        console.info(error);
        addAlert({
          intent: "error",
          text: `There was an unknown errror while deleting region "${queuedDeleteData.name}".`,
        });
        setShowDeletePrompt(false);
        setQueuedDeleteData(null);
      });
  };

  const regionsRows = !regions
    ? []
    : regions.map(({ id, name, timezone, property_count, created_at }) => ({
        id,
        name,
        timezone,
        property_count,
        created_at,
      }));

  return (
    <div className={classes.root}>
      <DashboardPageHeader
        cta={{ path: "/dashboard/regions/new", text: "Create Region" }}
      >
        Regions
      </DashboardPageHeader>

      <SkeletonWrapper
        skeleton={RegionsTableSkeleton}
        loading={getAllRegionsLoading.loading}
      >
        <RegionsTable
          regions={regionsRows}
          onDelete={handleDeletePrompt}
          onEdit={() => {}}
        />
      </SkeletonWrapper>
      {showDeletePrompt && (
        <RegionDeleteModal
          open={showDeletePrompt}
          onClose={() => setShowDeletePrompt(false)}
          data={queuedDeleteData}
          onConfirm={handleDeleteConfirm}
          loading={deleteRegionLoading.loading}
        />
      )}
    </div>
  );
}

RegionsIndex.propTypes = {};

export default RegionsIndex;
