import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import PanelContext from "./PanelContext";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {},
  panelContainer: {}
}));

function PanelProvider({ children }) {
  const classes = useStyles();
  const panelRef = useRef();

  const [context, setContext] = useState();

  useEffect(() => {
    setContext(panelRef.current);
  }, []);

  return (
    <div className={classes.root}>
      <PanelContext.Provider value={context}>{children}</PanelContext.Provider>
      <div ref={panelRef} className={classes.panelContainer} />
    </div>
  );
}

PanelProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
};

export default PanelProvider;
