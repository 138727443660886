import {
  SET_REGION_DATA,
  UPDATE_REGION_DATA,
  SET_REGION_HOMES,
  SET_REGION_HOSTS,
  SET_REGION_LINKS,
  UPDATE_REGION_LINK,
  SET_REGION_POIS,
  SET_POI_DEFAULT_STATE,
  MOVE_POI_SEQUENCE,
  MOVE_SUB_POI_SEQUENCE,
  SET_POI_SUB_DEFAULT_STATE,
  UPDATE_POI,
  ADD_POI,
  REMOVE_POI,
  SET_REGION_TV_OFFICE,
  UPDATE_DESTINATIONS,
  REMOVE_DESTINATION,
  UPDATE_MULTI_IMAGE,
  SET_REGION_EMERGENCY,
  UPDATE_ADMIN_FEATURE,
  REMOVE_ADMIN_FEATURE_PAGE,
  SET_REGION_GENERAL,
  UPDATE_REGION_GENERAL,
  SET_REGION_AIRPORT_DETAILS,
  MOVE_REGION_ITEM_SEQUENCE,
  UPDATE_AIRPORT_WEBSITE,
  UPDATE_BASIC_POI,
  REMOVE_BASIC_POI,
  ADD_BASIC_POI,
  SET_REGION_GUEST_SERVICES,
  UPDATE_PEOPLE_POI,
  ADD_PEOPLE_POI,
  REMOVE_PEOPLE_POI,
  SET_REGION_SUGGESTIONS,
  SET_REGION_ARCHIVED,
  UPDATE_MORE_LINK,
  UPDATE_TERMINAL_MAP_URL,
  UPDATE_RENTAL_MAP_URL
} from "constants/region";

import { createAction } from "@reduxjs/toolkit";

const setRegionData = createAction(SET_REGION_DATA);
const updateRegionData = createAction(UPDATE_REGION_DATA);

const setRegionHomes = createAction(SET_REGION_HOMES);
const setRegionHosts = createAction(SET_REGION_HOSTS);

const setRegionLinks = createAction(SET_REGION_LINKS);
const updateRegionLink = createAction(UPDATE_REGION_LINK);

const setRegionPois = createAction(SET_REGION_POIS);
const setPoiDefault = createAction(SET_POI_DEFAULT_STATE);
const setPoiSubDefault = createAction(SET_POI_SUB_DEFAULT_STATE);
const movePoiSequence = createAction(MOVE_POI_SEQUENCE);
const moveSubPoiSequence = createAction(MOVE_SUB_POI_SEQUENCE);

const updatePoi = createAction(UPDATE_POI);
const addPoi = createAction(ADD_POI);
const removePoi = createAction(REMOVE_POI);

const setRegionTvOffice = createAction(SET_REGION_TV_OFFICE);

const updateDestinations = createAction(UPDATE_DESTINATIONS);
const removeDestination = createAction(REMOVE_DESTINATION);

const updateMultiImage = createAction(UPDATE_MULTI_IMAGE);

const setRegionEmergency = createAction(SET_REGION_EMERGENCY);

const updateAdminFeature = createAction(UPDATE_ADMIN_FEATURE);
const removeAdminFeaturePage = createAction(REMOVE_ADMIN_FEATURE_PAGE);

const setRegionGeneral = createAction(SET_REGION_GENERAL);
const updateRegionGeneral = createAction(UPDATE_REGION_GENERAL);

const setRegionAirportDetails = createAction(SET_REGION_AIRPORT_DETAILS);

const moveRegionItemSequence = createAction(MOVE_REGION_ITEM_SEQUENCE);

const updateTerminalMapUrl = createAction(UPDATE_TERMINAL_MAP_URL);

const updateRentalMapUrl = createAction(UPDATE_RENTAL_MAP_URL);

const updateAirportWebsite = createAction(UPDATE_AIRPORT_WEBSITE);

const updateBasicPoi = createAction(UPDATE_BASIC_POI);

const removeBasicPoi = createAction(REMOVE_BASIC_POI);

const addBasicPoi = createAction(ADD_BASIC_POI);

const setRegionGuestServices = createAction(SET_REGION_GUEST_SERVICES);

const updatePeoplePoi = createAction(UPDATE_PEOPLE_POI);
const addPeoplePoi = createAction(ADD_PEOPLE_POI);
const removePeoplePoi = createAction(REMOVE_PEOPLE_POI);

const setRegionSuggestions = createAction(SET_REGION_SUGGESTIONS);

const setRegionArchived = createAction(SET_REGION_ARCHIVED);

const updateMoreLink = createAction(UPDATE_MORE_LINK);

export {
  setRegionData,
  updateRegionData,
  setRegionHomes,
  setRegionHosts,
  setRegionLinks,
  updateRegionLink,
  setRegionPois,
  setPoiDefault,
  movePoiSequence,
  moveSubPoiSequence,
  setPoiSubDefault,
  updatePoi,
  addPoi,
  removePoi,
  setRegionTvOffice,
  updateDestinations,
  removeDestination,
  updateMultiImage,
  setRegionEmergency,
  updateAdminFeature,
  removeAdminFeaturePage,
  setRegionGeneral,
  updateRegionGeneral,
  setRegionAirportDetails,
  moveRegionItemSequence,
  updateAirportWebsite,
  updateTerminalMapUrl,
  updateBasicPoi,
  removeBasicPoi,
  addBasicPoi,
  setRegionGuestServices,
  updatePeoplePoi,
  addPeoplePoi,
  removePeoplePoi,
  setRegionSuggestions,
  setRegionArchived,
  updateMoreLink,
  updateRentalMapUrl
};
