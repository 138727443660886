import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import StatItem from "./StatItem";
import RoomIcon from "@material-ui/icons/Room";
import HouseIcon from "@material-ui/icons/House";
import GroupIcon from "@material-ui/icons/Group";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    maxWidth: "100%",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    }
  }
}));

function StatGrid({ dashboard }) {
  const { account_owners_count, homes_count, pois_count } = dashboard;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <StatItem
        title="Account Owners"
        noun={"users"}
        icon={GroupIcon}
        value={account_owners_count}
        path="/dashboard/users"
      />
      <StatItem
        title="Homes"
        noun={"homes"}
        icon={HouseIcon}
        value={homes_count}
        path="/dashboard/homes"
      />
      <StatItem
        title="Points Of Interest"
        noun={"POIs"}
        icon={RoomIcon}
        value={pois_count}
        path="/dashboard/regions"
      />
    </div>
  );
}

StatGrid.propTypes = {
  dashboard: PropTypes.shape({
    account_owners_count: PropTypes.number,
    homes_count: PropTypes.number,
    pois_count: PropTypes.number
  })
};

export default StatGrid;
