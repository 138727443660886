import React, { useState } from "react";
import PropTypes from "prop-types";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import { useRouteMatch, useHistory } from "react-router-dom";
import { Image } from "components/Image";
import PaginatedTable from "components/PaginatedTable/PaginatedTable";
import TableControls from "components/TableControls";
import { makeStyles } from "@material-ui/core";
import { RoutedPanel } from "components/Panel";
import { HCModal } from "components/HCModal";
import PoiSuggestionsPanel from "./PoiSuggestionsPanel";
import PoiSuggestionDeleteModal from "./PoiSuggestionDeleteModal";
import useRegionDispatch from "hooks/useRegionDispatch";
import useRegionState from "hooks/useRegionState";
import useAlertsDispatch from "hooks/useAlertsDispatch";

const useStyles = makeStyles(theme => ({
  poiImage: {},
  root: {},
  actions: {},
  searchField: {}
}));

function PoiSuggestionsTable({
  pois,
  parentCategory,
  stateKey = "poi",
  type = "general"
}) {
  const match = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();

  const [queuedDeleteData, setQueuedDeleteData] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { deletePoi, approvePoi } = useRegionDispatch();
  const { addAlert } = useAlertsDispatch();
  const { region } = useRegionState();

  const handleDeleteClick = (evt, data) => {
    setQueuedDeleteData(null);
    setQueuedDeleteData(data);
    setShowDeleteModal(true);
  };

  const handleApprove = category => (
    id,
    { image, name, description, address, phone, website, reservations },
    actions
  ) => {
    approvePoi(region.id, stateKey, parentCategory, pois.key, id, {
      image,
      name,
      description,
      address,
      phone,
      website,
      reservations,
      is_approved: 1
    })
      .then(() => {
        actions.setSubmitting(false);
        history.push({ pathname: match.url });
        addAlert({
          intent: "success",
          text: `Succesfully approved "${name}"`
        });
      })
      .catch(error => {
        console.error(error);
        actions.setSubmitting(false);
        addAlert({
          intent: "error",
          text:
            error?.response?.data?.message ||
            "There was an unknown error while approving POI."
        });
      });
  };

  const handleDeleteConfirm = (data, actions) => {
    deletePoi(region.id, stateKey, parentCategory, pois.key, data.id)
      .then(() => {
        actions.setSubmitting(false);
        setShowDeleteModal(false);
        setQueuedDeleteData();
        addAlert({
          intent: "success",
          text: `Succesfully deleted POI "${data.name}" (ID #${data.id}).`
        });
      })
      .catch(error => {
        actions.setSubmitting(false);
        console.error(error);
        addAlert({
          intent: "error",
          text:
            error.response?.data?.message ||
            "There was an unknown error while updating POI."
        });
      });
  };

  const closePanel = () => {
    history.push({ pathname: match.url });
  };

  const poiRows = pois.items
    .map(
      ({
        id,
        name,
        address_line_1,
        address_line_2,
        city,
        state,
        image_url,
        created_at
      }) => ({
        image: (
          <Image
            src={image_url}
            alt={name}
            classes={{ root: classes.poiImage }}
          />
        ),
        id,
        name,
        created_at,
        address: address_line_1
      })
    )
    .sort((a, b) => (a.sequence > b.sequence ? 1 : -1));

  return (
    <div className={classes.root}>
      <TableControls>
        {/* <SearchField
          value={query}
          placeholder="Search POIs by name or address"
          className={classes.searchField}
        /> */}
      </TableControls>

      <PaginatedTable
        // query={query}
        headers={[
          {
            label: "ID",
            dataKey: "id"
          },
          { label: "Name", dataKey: "name" },
          { label: "Address", dataKey: "address" },
          { label: "Created at", dataKey: "created_at", date: true }
        ]}
        rows={poiRows}
        actions={[
          { Icon: DeleteIcon, onClick: handleDeleteClick },
          { Icon: EditIcon, link: ({ id }) => `${match.url}/${id}` }
        ]}
        order="desc"
        orderBy="created_at"
      />
      <RoutedPanel path={`${match.url}/:id(\\d+)`} onClose={closePanel}>
        <PoiSuggestionsPanel
          type={type}
          onApprove={handleApprove(parentCategory)}
          pois={pois}
        />
      </RoutedPanel>

      <HCModal
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
      >
        <PoiSuggestionDeleteModal
          data={queuedDeleteData}
          onClose={() => setShowDeleteModal(false)}
          onSubmit={handleDeleteConfirm}
        />
      </HCModal>
    </div>
  );
}

PoiSuggestionsTable.propTypes = {
  onApprove: PropTypes.func.isRequired,
  pois: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    icon: PropTypes.string,
    key: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        address_line_1: PropTypes.string,
        address_line_2: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.number,
        image_url: PropTypes.string,
        is_approved: PropTypes.number,
        is_default: PropTypes.number,
        name: PropTypes.string,
        sequence_no: PropTypes.number,
        state: PropTypes.string
      })
    )
  }),
  parentCategory: PropTypes.string.isRequired,
  stateKey: PropTypes.string.isRequired
};

export default PoiSuggestionsTable;
