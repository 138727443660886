import React from "react";
import PropTypes from "prop-types";

import {
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  makeStyles,
} from "@material-ui/core";
import DraggableTableRow from "./DraggableTableRow";

const useStyles = makeStyles((theme) => ({
  root: {},
  table: {},
  cell: {
    height: "20px",
  },

  cellImage: {
    maxWidth: 175,
  },
}));

function DraggableTable({
  headers,
  rows,
  actions,
  query,
  onDrop,
  onMove,
  size = "medium",
}) {
  // current page for paginated table

  // current rows per page setting for paginated table

  // whenever the query changes, reset the paginated table page back to page 0

  const classes = useStyles();
  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      className={classes.root}
    >
      <Table
        stickyHeader
        size={size}
        className={classes.table}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            {headers.map(({ label, dataKey }) => (
              <TableCell key={dataKey} className={classes.cell}>
                {label}
              </TableCell>
            ))}
            {actions && <TableCell className={classes.cell}>Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <DraggableTableRow
              key={row.id}
              headers={headers}
              row={row}
              actions={actions}
              onDrop={onDrop}
              onMove={onMove}
            />
          ))}
          {actions && <TableRow></TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

DraggableTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      Icon: PropTypes.object.isRequired,
      onClick: PropTypes.func,
      link: PropTypes.func,
    })
  ),
  query: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["small", "medium"]),
};

export default DraggableTable;
