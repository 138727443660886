import React, { useEffect, useState } from "react";

import { DashboardPageHeader, DashboardPage } from "components/Dashboard";
import { StatGrid } from "components/StatGrid";
import { Helmet } from "react-helmet";
import useSystemDispatch from "hooks/useSystemDispatch";
import useSystemState from "hooks/useSystemState";
import { SkeletonWrapper } from "components/SkeletonWrapper";
import SplashSkeleton from "./SplashSkeleton";
import { Typography } from "@material-ui/core";

function Splash() {
  const { getDashboardData } = useSystemDispatch();
  const { dashboard } = useSystemState();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    getDashboardData()
      .then(() => {
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setError(err);
        setLoading(false);
      });
  }, [getDashboardData]);

  if (error) {
    return (
      <Typography variant="body2" color="textSecondary">
        There was an error getting dashboard data.
      </Typography>
    );
  }

  return (
    <DashboardPage>
      <Helmet>
        <title>Dashboard - Home Concierge Super Admin</title>
      </Helmet>

      <DashboardPageHeader>Dashboard</DashboardPageHeader>
      <SkeletonWrapper skeleton={SplashSkeleton} loading={loading}>
        <StatGrid dashboard={dashboard} />
      </SkeletonWrapper>
    </DashboardPage>
  );
}

Splash.propTypes = {};

export default Splash;
