export default (items, itemId, newSequence) => {
  const draggedItem = items.find(({ id }) => id === itemId);

  return items.map((item) => {
    if (itemId === item.id) {
      return {
        ...item,
        sequence_no: newSequence,
      };
    }

    // if the current sequence is the same as the items new sequence
    if (newSequence === item.sequence_no) {
      //if the original dragged item was ahead of the new position,
      // shift the item +1
      if (draggedItem.sequence_no > newSequence) {
        return {
          ...item,
          sequence_no: item.sequence_no + 1,
        };
      }
      //if the original dragged item was below the new position,
      // shift the item -1
      return {
        ...item,
        sequence_no: item.sequence_no - 1,
      };
    }

    if (item.sequence_no > newSequence) {
      if (draggedItem.sequence_no < item.sequence_no) {
        return item;
      } else {
        return {
          ...item,
          sequence_no: item.sequence_no + 1,
        };
      }
    } else {
      //if the original dragged item was below the new position,
      // shift the item -1
      if (draggedItem.sequence_no < item.sequence_no) {
        return {
          ...item,
          sequence_no: item.sequence_no - 1,
        };
      } else {
        return item;
      }
    }
  });
};
