import React, { useState } from "react";
import PropTypes from "prop-types";

import { Typography, Button, makeStyles } from "@material-ui/core";

import { HCModal } from "components/HCModal";
import { HCButton } from "components/HCButton";
import { FormWrapper } from "components/FormWrapper";

const useStyles = makeStyles((theme) => ({
  ctaWrapper: {
    flexBasis: "100%",
    display: "flex",
  },
}));

function FeatureDeleteModal({ onClose, onConfirm, open, loading, data }) {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  if (!data) return;

  const handleSubmit = (evt) => {
    setIsSubmitting(true);
    onConfirm(evt, { setSubmitting: (value) => setIsSubmitting(value) });
  };

  return (
    <HCModal open={open} handleClose={onClose}>
      <FormWrapper onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom>
          Are you sure you want to delete the feature option "
          <strong>"{data.name}"</strong>?
        </Typography>
        <Typography paragraph>
          This will <strong>permanentely delete</strong> this feature item from
          all existing homes.
        </Typography>
        <div className={classes.ctaWrapper}>
          <HCButton
            loading={isSubmitting}
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginRight: "10px", display: "block" }}
          >
            Yes, I am Sure
          </HCButton>
          <Button variant="contained" color="secondary" onClick={onClose}>
            No, go back
          </Button>
        </div>
      </FormWrapper>
    </HCModal>
  );
}

FeatureDeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
};

export default FeatureDeleteModal;
