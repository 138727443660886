import { useSelector } from "react-redux";

const useLoadingState = () => {
  return {
    storage: useSelector(state => state.loading.storage),
    login: useSelector(state => state.loading.login),
    getAllUsers: useSelector(state => state.loading.getAllUsers),
    getAllHouses: useSelector(state => state.loading.getAllHouses),
    deleteUser: useSelector(state => state.loading.deleteUser),
    getAllRegions: useSelector(state => state.loading.getAllRegions),
    deleteRegion: useSelector(state => state.loading.deleteRegion),
    getAllAdminUsers: useSelector(state => state.loading.getAllAdminUsers),
    deleteAdminUser: useSelector(state => state.loading.deleteAdminUser)
  };
};

export default useLoadingState;
