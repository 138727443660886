const computeSubscription = (plan_id) => {
  let subscriptionName, subscriptionCycle;
  if (!plan_id) return null;
  switch (true) {
    case plan_id.includes("hcho_plan_1"): {
      const cycleChar = plan_id.substr(plan_id.length - 1);
      subscriptionName = "My Home";
      if (cycleChar === "a") {
        subscriptionCycle = "Annual";
      }
      if (cycleChar === "m") {
        subscriptionCycle = "Monthly";
      }
      break;
    }

    case plan_id.includes("hcho_plan_2"): {
      const cycleChar = plan_id.substr(plan_id.length - 1);
      subscriptionName = "Multiple Homes";
      if (cycleChar === "a") {
        subscriptionCycle = "Annual";
      }
      if (cycleChar === "m") {
        subscriptionCycle = "Monthly";
      }
      break;
    }

    case plan_id === "hcho_plan_comp": {
      subscriptionName = "Complimentary";
      subscriptionCycle = "Indefinate";
      break;
    }
    default: {
      break;
    }
  }

  return {
    cycle: subscriptionCycle,
    name: subscriptionName,
  };
};

export default computeSubscription;
