import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core";

import { useRouteMatch } from "react-router-dom";

import FeatureOptionsSection from "./FeatureOptionsSection";
import HouseFeatureOptionsSection from "./HouseFeatureOptionsSection";

import { DashboardPageHeader } from "components/Dashboard";
import { PanelProvider } from "components/Panel";
import { RoutedTabbedSection } from "components/TabbedSection";

import useSystemDispatch from "hooks/useSystemDispatch";
import useSystemState from "hooks/useSystemState";
import { useState } from "react";
import { Image } from "components/Image";
import { SkeletonWrapper } from "components/SkeletonWrapper";
import FeaturesTabbedSectionSkeleton from "./FeaturesTabbedSectionSkeleton";

const useStyles = makeStyles(theme => ({
  root: {},
  mainTabImage: {
    width: 138,
    height: 103
  },
  smallTabImage: {
    width: 100
  }
}));

function FeaturesRoot() {
  const classes = useStyles();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { features } = useSystemState();
  const {
    commitFeatureSequences,
    commitHouseFeatureSequences,
    moveFeatureSequence,
    moveHouseFeatureSequence,
    getFeatureOptions
  } = useSystemDispatch();

  // fetch feature options on mount
  useEffect(() => {
    getFeatureOptions()
      .then(() => setLoading(false))
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [getFeatureOptions]);

  // handle when music/emergency/tvoffice table drops an item
  const handleFeatureTableDrop = category => {
    return () => {
      // make request to commit sequences in backend
      commitFeatureSequences(category);
    };
  };

  // handle when houseFeatures table drops an item
  const handleHouseFeatureTableDrop = category => {
    return () => {
      // make request to commit sequences in backend
      commitHouseFeatureSequences(category);
    };
  };

  // handle when a music/emergency/tvoffice table item is moved to a different sequence
  const handleFeatureTableMove = category => {
    return (draggedItem, droppedItem) => {
      moveFeatureSequence(category, {
        id: draggedItem.id,
        sequence: droppedItem.sequence
      });
    };
  };

  // handle when a house feature table item is moved to a different sequence
  const handleHouseFeatureTableMove = category => {
    return (draggedItem, droppedItem) => {
      moveHouseFeatureSequence(category, {
        id: draggedItem.id,
        sequence: droppedItem.sequence
      });
    };
  };

  if (error)
    return (
      <p>There was an error receiving feature options. Please try again.</p>
    );

  return (
    <div className={classes.root}>
      <DashboardPageHeader>Features</DashboardPageHeader>
      <PanelProvider>
        <SkeletonWrapper
          skeleton={FeaturesTabbedSectionSkeleton}
          loading={loading}
        >
          {!loading && (
            <RoutedTabbedSection
              tabs={Object.keys(features)
                .sort((a, b) =>
                  features[a].id > features[b].id
                    ? 1
                    : features[b].id > features[a].id
                    ? -1
                    : 0
                )
                .map((featureCategory, i) => ({
                  icon: (
                    <Image
                      src={features[featureCategory].image_url}
                      alt={features[featureCategory].title}
                      classes={{ root: classes.mainTabImage }}
                    />
                  ),
                  label: features[featureCategory].title,
                  path: `${match.url}/${featureCategory}`,
                  index: i
                }))}
            >
              <FeatureOptionsSection
                onDrop={handleFeatureTableDrop("music")}
                onMove={handleFeatureTableMove("music")}
                options={features.music.options}
                category={"music"}
                categoryLabel="Music"
              />
              <FeatureOptionsSection
                onDrop={handleFeatureTableDrop("emergency")}
                onMove={handleFeatureTableMove("emergency")}
                options={features.emergency.options}
                category={"emergency"}
                categoryLabel="Emergency"
              />
              <FeatureOptionsSection
                onDrop={handleFeatureTableDrop("tvOffice")}
                onMove={handleFeatureTableMove("tvOffice")}
                options={features.tvOffice.options}
                category={"tvOffice"}
                categoryLabel="TV Office"
              />

              <RoutedTabbedSection
                tabs={Object.keys(features.houseFeatures.values)
                  .sort((a, b) =>
                    features.houseFeatures.values[a].id >
                    features.houseFeatures.values[b].id
                      ? 1
                      : features.houseFeatures.values[b].id >
                        features.houseFeatures.values[a].id
                      ? -1
                      : 0
                  )
                  .map((hfCategory, i) => ({
                    icon: (
                      <Image
                        src={
                          features.houseFeatures.values[hfCategory].image_url
                        }
                        alt={features.houseFeatures.values[hfCategory].title}
                        classes={{ root: classes.smallTabImage }}
                      />
                    ),
                    label: features.houseFeatures.values[hfCategory].title,
                    path: `${match.url}/houseFeatures/${hfCategory}`,
                    index: i
                  }))}
              >
                {Object.keys(features.houseFeatures.values)
                  .sort((a, b) =>
                    features.houseFeatures.values[a].id >
                    features.houseFeatures.values[b].id
                      ? 1
                      : features.houseFeatures.values[b].id >
                        features.houseFeatures.values[a].id
                      ? -1
                      : 0
                  )
                  .map(hfCategory => (
                    <HouseFeatureOptionsSection
                      key={hfCategory}
                      onDrop={handleHouseFeatureTableDrop(hfCategory)}
                      onMove={handleHouseFeatureTableMove(hfCategory)}
                      options={
                        features.houseFeatures.values[hfCategory].options
                      }
                      category={hfCategory}
                      categoryLabel={
                        features.houseFeatures.values[hfCategory].title
                      }
                      categoryData={features.houseFeatures.values[hfCategory]}
                    />
                  ))}
              </RoutedTabbedSection>
            </RoutedTabbedSection>
          )}
        </SkeletonWrapper>
      </PanelProvider>
    </div>
  );
}

FeaturesRoot.propTypes = {};

export default FeaturesRoot;
