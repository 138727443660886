import { createReducer } from "@reduxjs/toolkit";
import {
  setRegionData,
  setRegionHomes,
  setRegionHosts,
  setRegionLinks,
  updateRegionLink,
  setRegionPois,
  setPoiDefault,
  movePoiSequence,
  moveSubPoiSequence,
  setPoiSubDefault,
  updateRegionData,
  updatePoi,
  addPoi,
  removePoi,
  setRegionTvOffice,
  updateDestinations,
  removeDestination,
  updateMultiImage,
  setRegionEmergency,
  updateAdminFeature,
  removeAdminFeaturePage,
  setRegionGeneral,
  updateRegionGeneral,
  setRegionAirportDetails,
  moveRegionItemSequence,
  updateAirportWebsite,
  updateBasicPoi,
  removeBasicPoi,
  addBasicPoi,
  setRegionGuestServices,
  updatePeoplePoi,
  addPeoplePoi,
  removePeoplePoi,
  setRegionSuggestions,
  setRegionArchived,
  updateMoreLink,
  updateTerminalMapUrl,
  updateRentalMapUrl
} from "actions/region";
import sequenceMover from "utilities/sequenceMover";

const initialState = {};

const regionReducer = createReducer(initialState, {
  [setRegionData]: (state, { payload }) => {
    const { id, name, description, timezone, defaults } = payload;
    return {
      ...state,
      id,
      name,
      description,
      timezone,
	  defaults
    };
  },
  [updateRegionData]: (state, { payload }) => {
    const { name, description, timezone } = payload;
    return {
      ...state,
      name,
      description,
      timezone
    };
  },
  [setRegionHomes]: (state, { payload }) => {
    return {
      ...state,
      homes: payload
    };
  },
  [setRegionHosts]: (state, { payload }) => {
    return {
      ...state,
      hosts: payload
    };
  },
  [setRegionLinks]: (state, { payload }) => {
    return {
      ...state,
      links: payload
    };
  },
  [updateRegionLink]: (state, { payload }) => {
    return {
      ...state,
      links: {
        ...state.links,
        [payload.key]: {
          ...state.links[payload.key],
          url: payload.url
        }
      }
    };
  },
  [setRegionPois]: (state, { payload }) => {
    return {
      ...state,
      pois: payload
    };
  },
  [setPoiDefault]: (state, { payload }) => {
    const category = Object.keys(state[payload.key]).find(
      poiKey => state[payload.key][poiKey].id === payload.featureId,
      null
    );

    if (!category) return state;
    return {
      ...state,
      [payload.key]: {
        ...state[payload.key],
        [category]: {
          ...state[payload.key][category],
          items: state[payload.key][category].items.map(item =>
            item.id === payload.id
              ? {
                  ...item,
                  is_default: payload.is_default
                }
              : item
          )
        }
      }
    };
  },
  [setPoiSubDefault]: (state, { payload }) => {
    const category = Object.keys(state.pois).find(
      poiKey => state.pois[poiKey].id === payload.featureId,
      null
    );

    const subCategory = Object.keys(state.pois[category].values).find(
      subKey => state.pois[category].values[subKey].id === payload.subFeatureId
    );

    if (!category) return state;
    return {
      ...state,
      pois: {
        ...state.pois,
        [category]: {
          ...state.pois[category],
          values: {
            ...state.pois[category].values,
            [subCategory]: {
              ...state.pois[category].values[subCategory],
              items: state.pois[category].values[subCategory].items.map(item =>
                item.id === payload.id
                  ? {
                      ...item,
                      is_default: payload.is_default
                    }
                  : item
              )
            }
          }
        }
      }
    };
  },
  [movePoiSequence]: (state, { payload }) => {
    const draggedItem = state.pois[payload.category].items.find(
      ({ id }) => id === payload.id
    );

    return {
      ...state,
      pois: {
        ...state.pois,
        [payload.category]: {
          ...state.pois[payload.category],
          items: state.pois[payload.category].items.map(item => {
            if (payload.id === item.id) {
              return {
                ...item,
                sequence_no: payload.sequence
              };
            }

            // if the current sequence is the same as the items new sequence
            if (payload.sequence === item.sequence_no) {
              //if the original dragged item was ahead of the new position,
              // shift the item +1
              if (draggedItem.sequence_no > payload.sequence) {
                return {
                  ...item,
                  sequence_no: item.sequence_no + 1
                };
              }
              //if the original dragged item was below the new position,
              // shift the item -1
              return {
                ...item,
                sequence_no: item.sequence_no - 1
              };
            }

            if (item.sequence_no > payload.sequence) {
              if (draggedItem.sequence_no < item.sequence_no) {
                return item;
              } else {
                return {
                  ...item,
                  sequence_no: item.sequence_no + 1
                };
              }
            } else {
              //if the original dragged item was below the new position,
              // shift the item -1
              if (draggedItem.sequence_no < item.sequence_no) {
                return {
                  ...item,
                  sequence_no: item.sequence_no - 1
                };
              } else {
                return item;
              }
            }
          })
        }
      }
    };
  },
  [moveSubPoiSequence]: (state, { payload }) => {
    const draggedItem = state.pois[payload.category].values[
      payload.subCategory
    ].items.find(({ id }) => id === payload.id);

    return {
      ...state,
      pois: {
        ...state.pois,
        [payload.category]: {
          ...state.pois[payload.category],
          values: {
            ...state.pois[payload.category].values,
            [payload.subCategory]: {
              ...state.pois[payload.category].values[payload.subCategory],
              items: state.pois[payload.category].values[
                payload.subCategory
              ].items.map(item => {
                if (payload.id === item.id) {
                  return {
                    ...item,
                    sequence_no: payload.sequence
                  };
                }

                // if the current sequence is the same as the items new sequence
                if (payload.sequence === item.sequence_no) {
                  //if the original dragged item was ahead of the new position,
                  // shift the item +1
                  if (draggedItem.sequence_no > payload.sequence) {
                    return {
                      ...item,
                      sequence_no: item.sequence_no + 1
                    };
                  }
                  //if the original dragged item was below the new position,
                  // shift the item -1
                  return {
                    ...item,
                    sequence_no: item.sequence_no - 1
                  };
                }

                if (item.sequence_no > payload.sequence) {
                  if (draggedItem.sequence_no < item.sequence_no) {
                    return item;
                  } else {
                    return {
                      ...item,
                      sequence_no: item.sequence_no + 1
                    };
                  }
                } else {
                  //if the original dragged item was below the new position,
                  // shift the item -1
                  if (draggedItem.sequence_no < item.sequence_no) {
                    return {
                      ...item,
                      sequence_no: item.sequence_no - 1
                    };
                  } else {
                    return item;
                  }
                }
              })
            }
          }
        }
      }
    };
  },
  [updatePeoplePoi]: (state, { payload }) => {
    const { key, category, id, values } = payload;
    return {
      ...state,
      [key]: {
        ...state[key],
        [category]: {
          ...state[key][category],
          items: state[key][category].items.map(item =>
            item.id === id ? values : item
          )
        }
      }
    };
  },
  [addPeoplePoi]: (state, { payload }) => {
    const { key, category, values } = payload;

    return {
      ...state,
      [key]: {
        ...state[key],
        [category]: {
          ...state[key][category],
          items: [...state[key][category].items, values]
        }
      }
    };
  },
  [removePeoplePoi]: (state, { payload }) => {
    const { key, category, id } = payload;

    return {
      ...state,
      [key]: {
        ...state[key],
        [category]: {
          ...state[key][category],
          items: state[key][category].items.filter(item => item.id !== id)
        }
      }
    };
  },
  [updatePoi]: (state, { payload }) => {
    const { category, key, valueKey, id, values } = payload;
    const poiCategory = state[key][category];

    if (poiCategory.hasOwnProperty("values")) {
      return {
        ...state,
        [key]: {
          ...state[key],
          [category]: {
            ...state[key][category],
            values: {
              ...state[key][category].values,
              [valueKey]: {
                ...state[key][payload.category].values[valueKey],
                items: state[key][payload.category].values[valueKey].items.map(
                  item =>
                    item.id === id
                      ? {
                          ...item,
                          name: values.name,
                          image_url: values.image_url,
                          address_line_1: values.address_line_1,
                          description: values.description,
                          phone_number: values.phone_number,
                          website: values.website,
                          city: values.city,
                          state: values.state,
                          country: values.country,
                          reservation_link: values.reservation_link,
                          menu_link: values.menu_link,
                          directory_link: values.directory_link,
                          zip: values.zip,
                          lat: values.lat,
                          lng: values.lng
                        }
                      : item
                )
              }
            }
          }
        }
      };
    }

    return {
      ...state,
      [key]: {
        ...state[key],
        [category]: {
          ...state[key][payload.category],
          items: state[key][payload.category].items.map(item =>
            item.id === payload.id
              ? {
                  ...item,
                  name: values.name,
                  image_url: values.image_url,
                  address_line_1: values.address_line_1,
                  description: values.description,
                  phone_number: values.phone_number,
                  website: values.website,
                  city: values.city,
                  state: values.state,
                  country: values.country,
                  reservation_link: values.reservation_link,
                  menu_link: values.menu_link,
                  directory_link: values.directory_link,
                  zip: values.zip,
                  lat: values.lat,
                  lng: values.lng
                }
              : item
          )
        }
      }
    };
  },
  [updateMoreLink]: (state, { payload }) => {
    const poiCategory = state[payload.key][payload.category];

    if (poiCategory.hasOwnProperty("values")) {
      return {
        ...state,
        [payload.key]: {
          ...state[payload.key],
          [payload.category]: {
            ...state[payload.key][payload.category],
            values: {
              ...state[payload.key][payload.category].values,
              [payload.valueKey]: {
                ...state[payload.key][payload.category].values[
                  payload.valueKey
                ],
                more_url: payload.link
              }
            }
          }
        }
      };
    }

    return {
      ...state,
      [payload.key]: {
        ...state[payload.key],
        [payload.category]: {
          ...state[payload.key][payload.category],
          more_url: payload.link
        }
      }
    };
  },

  [addPoi]: (state, { payload }) => {
    const poiCategory = state[payload.key][payload.category];

    if (poiCategory.hasOwnProperty("values")) {
      return {
        ...state,
        [payload.key]: {
          ...state[payload.key],
          [payload.category]: {
            ...state[payload.key][payload.category],
            values: {
              ...state[payload.key][payload.category].values,
              [payload.valueKey]: {
                ...state[payload.key][payload.category].values[
                  payload.valueKey
                ],
                items: [
                  ...state[payload.key][payload.category].values[
                    payload.valueKey
                  ].items,
                  payload.values
                ]
              }
            }
          }
        }
      };
    }

    return {
      ...state,
      [payload.key]: {
        ...state[payload.key],
        [payload.category]: {
          ...state[payload.key][payload.category],
          items: [...state[payload.key][payload.category].items, payload.values]
        }
      }
    };
  },
  [removePoi]: (state, { payload }) => {
    const poiCategory = state[payload.key][payload.category];

    if (poiCategory.hasOwnProperty("values")) {
      const deletedItem = state[payload.key][payload.category].values[
        payload.valueKey
      ].items.find(({ id }) => id === payload.id);
      return {
        ...state,
        [payload.key]: {
          ...state[payload.key],
          [payload.category]: {
            ...state[payload.key][payload.category],
            values: {
              ...state[payload.key][payload.category].values,
              [payload.valueKey]: {
                ...state[payload.key][payload.category].values[
                  payload.valueKey
                ],
                items: state[payload.key][payload.category].values[
                  payload.valueKey
                ].items
                  .map(item =>
                    item.id === payload.id
                      ? item
                      : {
                          ...item,
                          sequence_no:
                            item.sequence_no < deletedItem.sequence_no
                              ? item.sequence_no
                              : item.sequence_no - 1
                        }
                  )
                  .filter(({ id }) => id !== payload.id)
              }
            }
          }
        }
      };
    }
    const deletedItem = state[payload.key][payload.category].items.find(
      ({ id }) => id === payload.id
    );
    return {
      ...state,
      [payload.key]: {
        ...state[payload.key],
        [payload.category]: {
          ...state[payload.key][payload.category],
          items: state[payload.key][payload.category].items
            .map(item =>
              item.id === payload.id
                ? item
                : {
                    ...item,
                    sequence_no:
                      item.sequence_no < deletedItem.sequence_no
                        ? item.sequence_no
                        : item.sequence_no - 1
                  }
            )
            .filter(({ id }) => id !== payload.id)
        }
      }
    };
  },
  [setRegionTvOffice]: (state, { payload }) => {
    const { copy_center, post_office, shipping, tv_channels } = payload;
    return {
      ...state,
      tvOffice: {
        copy_center,
        post_office,
        shipping,
        tv_channels
      }
    };
  },
  [updateDestinations]: (state, { payload }) => {
    const { destinations, parent, category } = payload;
    return {
      ...state,
      [parent]: {
        ...state[parent],
        [category]: {
          ...state[parent][category],
          items: destinations
        }
      }
    };
  },
  [removeDestination]: (state, { payload }) => {
    const { id, parent, category } = payload;
    return {
      ...state,
      [parent]: {
        ...state[parent],
        [category]: {
          ...state[parent][category],
          items: state[parent][category].items.filter(
            item => Number(item.id) !== Number(id)
          )
        }
      }
    };
  },
  [updateMultiImage]: (state, { payload }) => {
    const { parent, category, values } = payload;
    return {
      ...state,
      [parent]: {
        ...state[parent],
        [category]: {
          ...state[parent][category],
          desktop: {
            image_url: values.desktop,
            type: "desktop"
          },
          mobile: {
            image_url: values.mobile,
            type: "mobile"
          }
        }
      }
    };
  },
  [setRegionEmergency]: (state, { payload }) => {
    return {
      ...state,
      emergency: payload
    };
  },
  [updateAdminFeature]: (state, { payload }) => {
    const { parent, category, values } = payload;
    return {
      ...state,
      [parent]: {
        ...state[parent],
        [category]: {
          ...state[parent][category],
          items: values
        }
      }
    };
  },
  [removeAdminFeaturePage]: (state, { payload }) => {
    const { parent, category, id } = payload;

    const deletedItem = state[parent][category].items.find(
      item => item.id === id
    );

    return {
      ...state,
      [parent]: {
        ...state[parent],
        [category]: {
          ...state[parent][category],
          items: state[parent][category].items
            .filter(item => item.id !== id)
            .map(item => ({
              ...item,
              sequence_no:
                item.sequence_no > deletedItem.sequence_no
                  ? item.sequence_no - 1
                  : item.sequence_no
            }))
        }
      }
    };
  },
  [setRegionGeneral]: (state, { payload }) => {
    const { privacy_policy, contact_email, terms_of_service } = payload;

    return {
      ...state,
      general: {
        privacy_policy,
        contact_email,
        terms_of_service
      }
    };
  },
  [updateRegionGeneral]: (state, { payload }) => {
    const { key, value } = payload;

    return {
      ...state,
      general: {
        ...state.general,
        [key]: value
      }
    };
  },
  [setRegionAirportDetails]: (state, { payload }) => {
    const {
      terminal_map,
      airport_website,
      car_rentals,
      airport_directions,
      taxis,
      rental_map,
      private_cars
    } = payload;

    return {
      ...state,
      airport_details: {
        terminal_map,
        airport_website,
        car_rentals,
        airport_directions,
        taxis,
        rental_map,
        private_cars
      }
    };
  },
  [moveRegionItemSequence]: (state, { payload }) => {
    return {
      ...state,
      [payload.key]: {
        ...state[payload.key],
        [payload.category]: {
          ...state[payload.key][payload.category],
          items: sequenceMover(
            state[payload.key][payload.category].items,
            payload.id,
            payload.sequence
          )
        }
      }
    };
  },
  [updateAirportWebsite]: (state, { payload }) => {
    return {
      ...state,
      airport_details: {
        ...state.airport_details,
        airport_website: {
          ...state.airport_details.airport_website,
          url: payload.website
        }
      }
    };
  },
  [updateTerminalMapUrl]: (state, { payload }) => {
    return {
      ...state,
      airport_details: {
        ...state.airport_details,
        terminal_map: {
          ...state.airport_details.terminal_map,
          url: payload.website
        }
      }
    };
  },
  [updateRentalMapUrl]: (state, { payload }) => {
    return {
      ...state,
      airport_details: {
        ...state.airport_details,
        rental_map: {
          ...state.airport_details.rental_map,
          url: payload.website
        }
      }
    };
  },
  [updateBasicPoi]: (state, { payload }) => {
    const { key, category, id, values } = payload;
    return {
      ...state,
      [key]: {
        ...state[key],
        [category]: {
          ...state[key][category],
          items: state[key][category].items.map(item =>
            item.id === id
              ? {
                  ...item,
                  name: values.name,
                  website: values.website,
                  image_url: values.image
                }
              : item
          )
        }
      }
    };
  },
  [removeBasicPoi]: (state, { payload }) => {
    const { key, category, id } = payload;

    const deletedItem = state[key][category].items.find(item => item.id === id);

    if (!deletedItem) return state;

    return {
      ...state,
      [key]: {
        ...state[key],
        [category]: {
          ...state[key][category],
          items: state[key][category].items
            .filter(item => item.id !== id)
            .map(item => ({
              ...item,
              sequence_no:
                item.sequence_no < deletedItem.sequence_no
                  ? item.sequence_no
                  : item.sequence_no - 1
            }))
        }
      }
    };
  },
  [addBasicPoi]: (state, { payload }) => {
    const { key, category, data } = payload;
    const { id, name, sequence_no, website, image_url } = data;
    return {
      ...state,
      [key]: {
        ...state[key],
        [category]: {
          ...state[key][category],
          items: [
            ...state[key][category].items,
            {
              id,
              name,
              sequence_no,
              website,
              image_url
            }
          ]
        }
      }
    };
  },
  [setRegionGuestServices]: (state, { payload }) => {
    const {
      banks,
      atms,
      gas_stations,
      private_chefs,
      private_tours,
      personal_shopper,
      massage_therapist,
      farmers_market,
      rentals,
      sunscreen
    } = payload;
    return {
      ...state,
      guest_services: {
        banks,
        atms,
        gas_stations,
        private_chefs,
        private_tours,
        personal_shopper,
        massage_therapist,
        farmers_market,
        rentals,
        sunscreen
      }
    };
  },
  [setRegionSuggestions]: (state, { payload }) => {
    const { shop, dine, play, spa, guest_services } = payload;
    return {
      ...state,
      suggestions: {
        shop,
        dine,
        play,
        spa,
        guest_services
      }
    };
  },
  [setRegionArchived]: (state, { payload }) => {
    const { shop, dine, play, spa, guest_services } = payload;
    return {
      ...state,
      archived: {
        shop,
        dine,
        play,
        spa,
        guest_services
      }
    };
  }
});

export { initialState };

export default regionReducer;
