import React from "react";
import PropTypes from "prop-types";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Route, useLocation, Switch } from "react-router-dom";

import Panel from "./Panel";

function RoutedPanel({ path, children, onClose, lock = false }) {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        appear
        classNames="panelSlide"
        timeout={300}
      >
        <Switch location={location}>
          <Route
            path={path}
            render={({ history }) => (
              <Panel onClose={onClose} lock={lock}>
                {children instanceof Function
                  ? children({ handleClose: onClose })
                  : children}
              </Panel>
            )}
          />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}

RoutedPanel.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.func
  ]),
  onClose: PropTypes.func.isRequired
};

export default RoutedPanel;
