import React from "react";

import { Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

import { HomesEdit, HomesIndex } from "./components";

import { DashboardPage } from "components/Dashboard";

function Homes({ match }) {
  return (
    <DashboardPage>
      <Helmet>
        <title>Homes - Home Concierge Super Admin</title>
      </Helmet>
      <Switch>
        <Route path={`${match.url}`} exact component={HomesIndex} />
        <Route path={`${match.url}/:id/edit`} exact component={HomesEdit} />
      </Switch>
    </DashboardPage>
  );
}

Homes.propTypes = {};

export default Homes;
