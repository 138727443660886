import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { setLoading } from "../actions/loading";
import api from "../utilities/API";
import {
  setAllUsers,
  setAllRegions,
  setAllHouses,
  removeUser,
  addRegion,
  removeRegion,
  setAllAdminUsers,
  updateAdminUser,
  removeAdminUser,
  addAdminUser,
  changeFeatureItemSequence,
  setFeatureOptions,
  updateFeatureOption,
  addFeatureOption,
  removeFeatureOption,
  changeHouseFeatureItemSequence,
  updateFeatureCategory,
  addHouseFeatureOption,
  updateHouseFeatureOption,
  removeHouseFeatureOption,
  setAllIcons,
  updateIcon,
  updateCategoryIcon,
  addUser,
  setDashboardData
} from "../actions/system";
import useSystemState from "./useSystemState";

const useSystemDispatch = () => {
  const dispatch = useDispatch();
  const { features } = useSystemState();
  return {
    getAllUsers: useCallback(async () => {
      dispatch(
        setLoading({ requestName: "getAllUsers", requestStatus: "REQUEST" })
      );

      try {
        const response = await api.getAllUsers();
        dispatch(setAllUsers(response.data));
        dispatch(
          setLoading({ requestName: "getAllUsers", requestStatus: "SUCCESS" })
        );
        return response;
      } catch (error) {
        dispatch(
          setLoading({ requestName: "getAllUsers", requestStatus: "FAILED" })
        );
        throw error;
      }
    }, [dispatch]),
    getAllAdminUsers: useCallback(async () => {
      dispatch(
        setLoading({
          requestName: "getAllAdminUsers",
          requestStatus: "REQUEST"
        })
      );
      try {
        const { data } = await api.getAllAdminUsers();
        dispatch(setAllAdminUsers(data));
        dispatch(
          setLoading({
            requestName: "getAllAdminUsers",
            requestStatus: "SUCCESS"
          })
        );
        return data;
      } catch (error) {
        dispatch(
          setLoading({
            requestName: "getAllAdminUsers",
            requestStatus: "FAILED"
          })
        );
        throw error;
      }
    }, [dispatch]),
    getAllHouses: useCallback(async () => {
      dispatch(
        setLoading({ requestName: "getAllHouses", requestStatus: "REQUEST" })
      );

      try {
        const { houses } = await api.getAllHouses();
        dispatch(setAllHouses(houses));
        dispatch(
          setLoading({ requestName: "getAllHouses", requestStatus: "SUCCESS" })
        );
        return houses;
      } catch (error) {
        dispatch(
          setLoading({ requestName: "getAllHouses", requestStatus: "FAILED" })
        );
        throw error;
      }
    }, [dispatch]),
    getAllRegions: useCallback(async () => {
      dispatch(
        setLoading({ requestName: "getAllRegions", requestStatus: "REQUEST" })
      );
      try {
        const { regions } = await api.getAllRegions();
        dispatch(setAllRegions(regions));
        dispatch(
          setLoading({ requestName: "getAllRegions", requestStatus: "SUCCESS" })
        );
        return regions;
      } catch (error) {
        dispatch(
          setLoading({ requestName: "getAllRegions", requestStatus: "FAILED" })
        );
        throw error;
      }
    }, [dispatch]),
    getAllIcons: useCallback(async () => {
      dispatch(
        setLoading({ requestName: "getAllIcons", requestStatus: "REQUEST" })
      );
      try {
        const response = await api.getAllIcons();
        dispatch(setAllIcons(response));
        dispatch(
          setLoading({ requestName: "getAllIcons", requestStatus: "SUCCESS" })
        );
        return response;
      } catch (error) {
        dispatch(
          setLoading({ requestName: "getAllIcons", requestStatus: "FAILED" })
        );
        throw error;
      }
    }, [dispatch]),
    deleteUser: useCallback(
      async id => {
        dispatch(
          setLoading({ requestName: "deleteUser", requestStatus: "REQUEST" })
        );
        try {
          const data = await api.deleteUser(id);
          dispatch(removeUser(id));
          dispatch(
            setLoading({ requestName: "deleteUser", requestStatus: "SUCCESS" })
          );
          return data;
        } catch (error) {
          dispatch(
            setLoading({ requestName: "deleteUser", requestStatus: "FAILED" })
          );
          throw error;
        }
      },
      [dispatch]
    ),
    createGiftUser: useCallback(
      async values => {
        try {
          const data = await api.createGiftUser(values);
          dispatch(addUser(data));
          return data;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    deleteAdminUser: useCallback(
      async id => {
        dispatch(
          setLoading({
            requestName: "deleteAdminUser",
            requestStatus: "REQUEST"
          })
        );
        try {
          const data = await api.deleteUser(id);
          dispatch(removeAdminUser(id));
          dispatch(
            setLoading({
              requestName: "deleteAdminUser",
              requestStatus: "SUCCESS"
            })
          );
          return data;
        } catch (error) {
          dispatch(
            setLoading({
              requestName: "deleteAdminUser",
              requestStatus: "FAILED"
            })
          );
          throw error;
        }
      },
      [dispatch]
    ),
    createRegion: useCallback(
      async (name, description, tz) => {
        dispatch(
          setLoading({ requestName: "createRegion", requestStatus: "REQUEST" })
        );
        try {
          const region = await api.createRegion(name, description, tz);
          dispatch(
            addRegion({
              id: region.id,
              name: region.name,
              timezone: region.timezone,
              description: region.description,
              property_count: 0
            })
          );
          dispatch(
            setLoading({
              requestName: "createRegion",
              requestStatus: "SUCCESS"
            })
          );
          return region;
        } catch (error) {
          dispatch(
            setLoading({ requestName: "createRegion", requestStatus: "FAILED" })
          );
          throw error;
        }
      },
      [dispatch]
    ),
    createAdminUser: useCallback(
      async userData => {
        dispatch(
          setLoading({
            requestName: "createAdminUser",
            requestStatus: "REQUEST"
          })
        );
        try {
          const { user } = await api.createAdminUser({
            email: userData.email,
            password: userData.password,
            firstName: userData.firstName,
            lastName: userData.lastName,
            phoneNumber: userData.phoneNumber
          });
          dispatch(addAdminUser(user));
          dispatch(
            setLoading({
              requestName: "createAdminUser",
              requestStatus: "SUCCESS"
            })
          );
          return user;
        } catch (error) {
          dispatch(
            setLoading({
              requestName: "createAdminUser",
              requestStatus: "FAILED"
            })
          );

          if (!error.response || !error.response.data)
            throw new Error(
              "There was an unknown error while creating an admin user."
            );
          if (!error.response)
            throw new Error(
              "There was an unknown error while creating an admin user."
            );

          if (error.response.status === "401") {
            throw new Error("You are not authorized to create an admin user.");
          }

          if (error.response.data.errors.email) {
            throw new Error(error.response.data.errors.email);
          }

          if (error.response.data.errors) {
            throw new Error(
              "There was a validation error while creating admin user. Please check all form fields before submitting again."
            );
          }
          throw new Error(
            "There was an unknown error while creating an admin user."
          );
        }
      },
      [dispatch]
    ),
    deleteRegion: useCallback(
      async id => {
        dispatch(
          setLoading({ requestName: "deleteRegion", requestStatus: "REQUEST" })
        );
        try {
          const response = await api.deleteRegion(id);
          dispatch(removeRegion(id));
          dispatch(
            setLoading({
              requestName: "deleteRegion",
              requestStatus: "SUCCESS"
            })
          );
          return response.data;
        } catch (error) {
          dispatch(
            setLoading({ requestName: "deleteRegion", requestStatus: "FAILED" })
          );
          throw error;
        }
      },
      [dispatch]
    ),
    updateAdminUser: useCallback(
      async user => {
        dispatch(
          setLoading({
            requestName: "updateAdminUser",
            requestStatus: "REQUEST"
          })
        );
        try {
          const { admin } = await api.updateAdminUser(user);
          dispatch(updateAdminUser(admin));
          dispatch(
            setLoading({
              requestName: "updateAdminUser",
              requestStatus: "SUCCESS"
            })
          );

          return admin;
        } catch (error) {
          dispatch(
            setLoading({
              requestName: "updateAdminUser",
              requestStatus: "FAILED"
            })
          );
          throw error;
        }
      },
      [dispatch]
    ),
    updateIcon: useCallback(
      async (id, icon) => {
        dispatch(
          setLoading({
            requestName: "updateIcon",
            requestStatus: "REQUEST"
          })
        );
        try {
          const data = await api.updateIcon(id, icon);
          dispatch(
            updateIcon({
              categoryId: data.icon_category_id,
              id: data.id,
              icon: data.image_url
            })
          );
          dispatch(
            setLoading({
              requestName: "updateIcon",
              requestStatus: "SUCCESS"
            })
          );
          return data;
        } catch (error) {
          dispatch(
            setLoading({
              requestName: "updateIcon",
              requestStatus: "FAILED"
            })
          );
          throw error;
        }
      },
      [dispatch]
    ),
    updateCategoryIcon: useCallback(
      async (categoryId, icon) => {
        dispatch(
          setLoading({
            requestName: "updateCategoryIcon",
            requestStatus: "REQUEST"
          })
        );
        try {
          const data = await api.updateCategoryIcon(categoryId, icon);
          dispatch(
            updateCategoryIcon({
              categoryId: data.id,
              icon: data.image_url
            })
          );
          dispatch(
            setLoading({
              requestName: "updateCategoryIcon",
              requestStatus: "SUCCESS"
            })
          );
          return data;
        } catch (error) {
          dispatch(
            setLoading({
              requestName: "updateCategoryIcon",
              requestStatus: "FAILED"
            })
          );
          throw error;
        }
      },
      [dispatch]
    ),
    moveFeatureSequence: useCallback(
      async (category, { id: itemId, sequence: newSequence }) => {
        try {
          dispatch(
            changeFeatureItemSequence({
              category: category,
              id: itemId,
              sequence: newSequence
            })
          );
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    moveHouseFeatureSequence: useCallback(
      async (category, { id: itemId, sequence: newSequence }) => {
        try {
          dispatch(
            changeHouseFeatureItemSequence({
              category: category,
              id: itemId,
              sequence: newSequence
            })
          );
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    commitHouseFeatureSequences: useCallback(
      async category => {
        dispatch(
          setLoading({
            requestName: "commitHouseFeatureSequences",
            requestStatus: "REQUEST"
          })
        );
        try {
          const response = await api.updateHouseFeatureSequences(
            features.houseFeatures.values[category].id,
            features.houseFeatures.values[category].options
          );
          dispatch(
            setLoading({
              requestName: "commitHouseFeatureSequences",
              requestStatus: "SUCCESS"
            })
          );
          return response;
        } catch (error) {
          dispatch(
            setLoading({
              requestName: "commitHouseFeatureSequences",
              requestStatus: "FAILED"
            })
          );
        }
      },
      [dispatch, features]
    ),
    commitFeatureSequences: useCallback(
      async category => {
        dispatch(
          setLoading({
            requestName: "commitFeatureSequences",
            requestStatus: "REQUEST"
          })
        );
        try {
          const response = await api.updateFeatureSequences(
            category,
            features[category].options
          );
          dispatch(
            setLoading({
              requestName: "commitFeatureSequences",
              requestStatus: "SUCCESS"
            })
          );
          return response;
        } catch (error) {
          dispatch(
            setLoading({
              requestName: "commitFeatureSequences",
              requestStatus: "FAILED"
            })
          );
          throw error;
        }
      },
      [dispatch, features]
    ),
    getFeatureOptions: useCallback(async () => {
      dispatch(
        setLoading({
          requestName: "getFeatureOptions",
          requestStatus: "REQUEST"
        })
      );
      try {
        const response = await api.getFeatureOptions();
        dispatch(setFeatureOptions(response));
        dispatch(
          setLoading({
            requestName: "getFeatureOptions",
            requestStatus: "SUCCESS"
          })
        );
      } catch (error) {
        dispatch(
          setLoading({
            requestName: "getFeatureOptions",
            requestStatus: "FAILED"
          })
        );
        throw error;
      }
    }, [dispatch]),
    updateFeatureCategory: useCallback(
      async (category, subCategoryId, data) => {
        dispatch(
          setLoading({
            requestName: "updateFeatureCategory",
            requestStatus: "REQUEST"
          })
        );
        try {
          const response = await api.updateFeatureCategory(subCategoryId, data);

          dispatch(
            updateFeatureCategory({
              category: category,
              data: response
            })
          );
          dispatch(
            setLoading({
              requestName: "updateFeatureCategory",
              requestStatus: "SUCCESS"
            })
          );
          return response;
        } catch (error) {
          dispatch(
            setLoading({
              requestName: "updateFeatureCategory",
              requestStatus: "FAILED"
            })
          );
          throw error;
        }
      },
      [dispatch]
    ),
    createHouseFeatureOption: useCallback(
      async (inHouseFeatureId, inHouseFeatureKey, data) => {
        dispatch(
          setLoading({
            requestName: "createHouseFeatureOption",
            requestStatus: "REQUEST"
          })
        );
        try {
          const response = await api.createHouseFeatureOption(
            inHouseFeatureId,
            data
          );
          dispatch(
            addHouseFeatureOption({
              category: inHouseFeatureKey,
              option: response
            })
          );
          dispatch(
            setLoading({
              requestName: "createHouseFeatureOption",
              requestStatus: "SUCCESS"
            })
          );

          return response;
        } catch (error) {
          dispatch(
            setLoading({
              requestName: "createHouseFeatureOption",
              requestStatus: "FAILED"
            })
          );
          console.error(error);
          throw error;
        }
      },
      [dispatch]
    ),

    updateHouseFeatureOption: useCallback(
      async (inHouseFeatureKey, id, data) => {
        try {
          const response = await api.updateHouseFeatureOption(id, data);
          dispatch(
            updateHouseFeatureOption({
              category: inHouseFeatureKey,
              id,
              data: response
            })
          );
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    deleteHouseFeatureOption: useCallback(
      async (category, id) => {
        try {
          const response = await api.deleteHouseFeatureOption(id);
          dispatch(removeHouseFeatureOption({ category, id }));
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    updateFeatureOption: useCallback(
      async (category, id, data) => {
        dispatch(
          setLoading({
            requestName: "updateFeatureOption",
            requestStatus: "REQUEST"
          })
        );
        try {
          const response = await api.updateFeatureOption(category, id, data);
          dispatch(updateFeatureOption({ category, id, data: response }));
          dispatch(
            setLoading({
              requestName: "updateFeatureOption",
              requestStatus: "SUCCESS"
            })
          );
          return response;
        } catch (error) {
          dispatch(
            setLoading({
              requestName: "updateFeatureOption",
              requestStatus: "FAILED"
            })
          );
          if (!error.response || !error.response.data)
            throw new Error(
              "There was an unknown error while updating the feature option."
            );
          if (!error.response)
            throw new Error(
              "There was an unknown error while updating the feature option."
            );

          if (error.response.status === "401") {
            throw new Error(
              "You are not authorized to update a feature option."
            );
          }
          throw new Error(
            "There was an unknown error while updating the feature option."
          );
        }
      },
      [dispatch]
    ),
    createFeatureOption: useCallback(
      async (category, values) => {
        dispatch(
          setLoading({
            requestName: "createFeatureOption",
            requestStatus: "REQUEST"
          })
        );
        try {
          const response = await api.createFeatureOption(category, values);
          dispatch(addFeatureOption({ category, option: response }));
          dispatch(
            setLoading({
              requestName: "createFeatureOption",
              requestStatus: "SUCCESS"
            })
          );
          return response;
        } catch (error) {
          dispatch(
            setLoading({
              requestName: "createFeatureOption",
              requestStatus: "FAILED"
            })
          );
          if (!error.response || !error.response.data)
            throw new Error(
              "There was an unknown error while creating the feature option."
            );
          if (!error.response)
            throw new Error(
              "There was an unknown error while creating the feature option."
            );

          if (error.response.status === "401") {
            throw new Error(
              "You are not authorized to create a feature option."
            );
          }
          throw new Error(
            "There was an unknown error while updating the feature option."
          );
        }
      },
      [dispatch]
    ),
    deleteFeatureOption: useCallback(
      async (category, id) => {
        dispatch(
          setLoading({
            requestName: "createFeatureOption",
            requestStatus: "REQUEST"
          })
        );
        try {
          const response = await api.deleteFeatureOption(category, id);
          dispatch(removeFeatureOption({ category, id }));
          dispatch(
            setLoading({
              requestName: "createFeatureOption",
              requestStatus: "SUCCESS"
            })
          );
          return response;
        } catch (error) {
          dispatch(
            setLoading({
              requestName: "createFeatureOption",
              requestStatus: "FAILED"
            })
          );
          if (!error.response || !error.response.data)
            throw new Error(
              "There was an unknown error while creating the feature option."
            );
          if (!error.response)
            throw new Error(
              "There was an unknown error while creating the feature option."
            );

          if (error.response.status === "401") {
            throw new Error(
              "You are not authorized to create a feature option."
            );
          }
          throw new Error(
            "There was an unknown error while updating the feature option."
          );
        }
      },
      [dispatch]
    ),
    getDashboardData: useCallback(async () => {
      dispatch(
        setLoading({ requestName: "getDashboard", requestStatus: "REQUEST" })
      );
      try {
        const {
          account_owners_count,
          homes_count,
          pois_count
        } = await api.getDashboardData();
        dispatch(
          setDashboardData({
            account_owners_count,
            homes_count,
            pois_count
          })
        );
      } catch (error) {
        throw new Error(
          "There was an unknown error while getting dashboard data."
        );
      }
    }, [dispatch])
  };
};

export default useSystemDispatch;
