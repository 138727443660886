import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BackButton } from "components/BackButton";
import { DashboardPageHeader } from "components/Dashboard";
import { RoutedTabbedSection } from "components/TabbedSection";
import { SkeletonWrapper } from "components/SkeletonWrapper";
import RoutedTabbedSectionSkeleton from "components/TabbedSection/RoutedTabbedSectionSkeleton";
import { Image } from "components/Image";
import LinkForm from "../components/LinkForm";
import useAlertsDispatch from "hooks/useAlertsDispatch";
import { makeStyles } from "@material-ui/core";
import useRegionState from "hooks/useRegionState";
import useRegionDispatch from "hooks/useRegionDispatch";

const useStyles = makeStyles((theme) => ({
  mainTabImage: {
    width: 138,
    height: 103,
  },
}));

function RegionLinks({ match }) {
  const classes = useStyles();
  const { region, links } = useRegionState();
  const { getRegionLinks, updateRegionLink } = useRegionDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const { addAlert } = useAlertsDispatch();

  useEffect(() => {
    if (!region.id) return;
    getRegionLinks(region.id)
      .then((links) => {
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [getRegionLinks, region.id]);

  const handleLinkUpdate = (key) => {
    return ({ url }, actions) => {
      updateRegionLink(region.id, key, url)
        .then(() => {
          actions.setSubmitting(false);
          addAlert({
            intent: "success",
            text: "Succesfully updated link.",
          });
        })
        .catch((error) => {
          addAlert({
            intent: "error",
            text:
              error.message ||
              "There was an unknown error while updating link.",
          });
        });
    };
  };

  if (error) return <p>Error fetching region links.</p>;

  return (
    <div>
      {region && (
        <BackButton path={`/dashboard/regions/${region.id}`}>
          Back to {region.name}
        </BackButton>
      )}
      <DashboardPageHeader subHeader={region ? region.name : "..."}>
        Links
      </DashboardPageHeader>

      <SkeletonWrapper skeleton={RoutedTabbedSectionSkeleton} loading={loading}>
        {links && (
          <RoutedTabbedSection
            tabs={[links.weather.icon, links.local_news.icon].map(
              (icon, i) => ({
                icon: (
                  <Image
                    src={icon.image_url}
                    alt={icon.title}
                    classes={{ root: classes.mainTabImage }}
                  />
                ),
                label: icon.title,
                path: `${match.url}/${icon.title
                  .replace(/\s+/g, "-")
                  .toLowerCase()}`,
                index: i,
              })
            )}
          >
            <LinkForm
              onSubmit={handleLinkUpdate("weather")}
              url={links.weather.url}
            />
            <LinkForm
              onSubmit={handleLinkUpdate("local_news")}
              url={links.local_news.url}
            />
          </RoutedTabbedSection>
        )}
      </SkeletonWrapper>
    </div>
  );
}

RegionLinks.propTypes = {
  match: PropTypes.object.isRequired,
};

export default RegionLinks;

// tabs={Object.keys(features).map((featureCategory, i) => ({
//   icon: (
//     <Image
//       src={features[featureCategory].image_url}
//       alt={features[featureCategory].title}
//       classes={{ root: classes.mainTabImage }}
//     />
//   ),
//   label: features[featureCategory].title,
//   path: `${match.url}/${features[featureCategory].title
//     .split(" ")
//     .join("-")
//     .toLowerCase()}`,
//   index: i,
// }))}
