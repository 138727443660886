import React from "react";
import PropTypes from "prop-types";

import { Fade, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: { position: "relative" },
  skeletonContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 999,
  },
  contentContainer: {
    position: "relative",
    zIndex: 1,
  },
}));

function SkeletonWrapper({
  skeleton: SkeletonComponent,
  loading,
  children,
  ...rest
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Fade
        in={loading}
        timeout={{ enter: 0, exit: 350 }}
        className={classes.skeletonContainer}
      >
        <SkeletonComponent {...rest} />
      </Fade>
      <Fade in={!loading} timeout={350}>
        <div className={classes.contentContainer}>
          {loading === false && children}
        </div>
      </Fade>
    </div>
  );
}

SkeletonWrapper.propTypes = {
  skeleton: PropTypes.elementType,
  loading: PropTypes.bool,
};

export default SkeletonWrapper;
