import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core";

import AdminUsersTable from "components/AdminUsersTable/AdminUsersTable";
import { DashboardPageHeader } from "components/Dashboard";
import { SkeletonWrapper } from "components/SkeletonWrapper";
import { UsersTableSkeleton } from "components/UsersTable";

import useSystemDispatch from "hooks/useSystemDispatch";
import useAlertsDispatch from "hooks/useAlertsDispatch";
import useLoadingState from "hooks/useLoadingState";
import useSystemState from "hooks/useSystemState";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function AdminUsersIndex() {
  const classes = useStyles();
  const { getAllAdminUsers: getAllAdminUsersLoading } = useLoadingState();
  const { getAllAdminUsers, deleteAdminUser } = useSystemDispatch();
  const { adminUsers = [] } = useSystemState();
  const { addAlert } = useAlertsDispatch();

  const handleUserDelete = (user, closeModal) => {
    deleteAdminUser(user.id)
      .then(() => {
        addAlert({
          intent: "success",
          text: `Succesfully deleted user "${user.email}"`,
        });
        closeModal();
      })
      .catch((error) => {
        console.warn(error);
        addAlert({
          intent: "error",
          text: `There was an error while deleting user "${user.email}". Please try again later.`,
        });
      });
  };

  useEffect(() => {
    getAllAdminUsers();
  }, [getAllAdminUsers]);

  const adminUserRows = adminUsers.map(
    ({ id, email, firstName, lastName, created_at }) => ({
      id,
      email,
      firstName,
      lastName,
      created_at,
    })
  );

  return (
    <div className={classes.root}>
      <DashboardPageHeader
        cta={{ path: "/dashboard/admin/new", text: "Create Admin User" }}
      >
        Admin Users
      </DashboardPageHeader>

      <SkeletonWrapper
        skeleton={UsersTableSkeleton}
        loading={getAllAdminUsersLoading.loading}
      >
        <AdminUsersTable users={adminUserRows} onDelete={handleUserDelete} />
      </SkeletonWrapper>
    </div>
  );
}

AdminUsersIndex.propTypes = {};

export default AdminUsersIndex;
