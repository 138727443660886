import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core";

import FeatureNewForm from "./FeatureNewForm";

import { PanelHeader } from "components/Panel";

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {
    marginTop: theme.spacing(2),
  },
}));

function FeatureNew({ categoryLabel, onNew }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PanelHeader
        header={categoryLabel}
        subHeader={`Create new ${categoryLabel} option`}
      />
      <FeatureNewForm onSubmit={onNew} className={classes.form} />
    </div>
  );
}

FeatureNew.propTypes = {
  categoryLabel: PropTypes.string.isRequired,

  onNew: PropTypes.func.isRequired,
};

export default FeatureNew;
