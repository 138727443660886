import { useSelector } from "react-redux";

const useUserState = () => {
  return {
    isLoggedIn: useSelector(state => state.user.isLoggedIn),
    email: useSelector(state => state.user.email),
    token: useSelector(state => state.user.token)
  };
};

export default useUserState;
