import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";

const useStyles = makeStyles((theme) => ({
  root: {},
  link: {
    display: "inline-flex",
    textDecoration: "none",
    color: theme.palette.secondary.dark,
    "&:hover": {
      "& div:first-of-type": {
        color: theme.palette.grey[700],
      },
      "& p:first-of-type": {
        color: theme.palette.grey[900],
      },
    },
  },
  iconContainer: {
    color: theme.palette.grey[500],
    transition: `0.3s color ${theme.transitions.easing.easeOut}`,
  },
  backText: {
    fontWeight: 700,
    color: theme.palette.grey[700],
    marginLeft: theme.spacing(1),
    transition: `0.3s color ${theme.transitions.easing.easeOut}`,
  },
}));

function BackButton({ path, children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Link to={path} className={classes.link}>
        <div className={classes.iconContainer}>
          <ArrowBackOutlinedIcon />
        </div>
        <Typography paragraph gutterBottom className={classes.backText}>
          {children}
        </Typography>
      </Link>
    </div>
  );
}

BackButton.propTypes = {
  path: PropTypes.string.isRequired,
};

export default BackButton;
