import { useEffect, useState } from "react";

const useTableSort = (items, order, orderBy) => {
  const [sortedItems, setSortedItems] = useState(items);

  useEffect(() => {
    function descendingComparator(a, b, dataKey, order) {
      if (!b[dataKey]) {
        return a[dataKey] ? -1 : 1;
      }
      if (b[dataKey] < a[dataKey]) {
        return order === "desc" ? -1 : 1;
      }
      if (b[dataKey] > a[dataKey]) {
        return order === "desc" ? 1 : -1;
      }

      return 0;
    }
    const newSortedItems = items.sort((a, b) =>
      descendingComparator(a, b, orderBy, order)
    );

    setSortedItems([...newSortedItems]);
  }, [items, order, orderBy]);

  return sortedItems;
};

export default useTableSort;
