import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { removeAlert, addAlert } from "../actions/alerts";

let id = 1;

const useAlertsDispatch = () => {
  const dispatch = useDispatch();

  return {
    removeAlert: useCallback(
      (id) => {
        dispatch(removeAlert(id));
      },
      [dispatch]
    ),
    addAlert: useCallback(
      ({ intent, text }) => {
        id = id + 1;
        dispatch(addAlert({ intent, text, id: id }));
      },
      [dispatch]
    ),
  };
};

export default useAlertsDispatch;
