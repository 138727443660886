import React from "react";
import PropTypes from "prop-types";
import { TextField, makeStyles } from "@material-ui/core";
import { useField } from "formik";
import { URLIcon } from "components/URLIcon";

const useStyles = makeStyles((theme) => ({
  input: ({ half }) => ({
    width: "auto",
    flexBasis: half ? `calc(50% - ${theme.spacing(1) * 2}px)` : "100%",
    margin: theme.spacing(1),
  }),
}));

function URLField({ half, full, ...props }) {
  const [field, meta] = useField(props);
  const classes = useStyles({ half, full });
  const showError = Boolean(meta.error && meta.touched);

  return (
    <TextField
      className={classes.input}
      error={showError}
      helperText={showError ? meta.error : ""}
      {...field}
      {...props}
      InputProps={{
        endAdornment: (
          <URLIcon url={field.value} disabled={meta.error || !field.value} />
        ),
      }}
    />
  );
}

URLField.propTypes = {
  half: PropTypes.bool,
  full: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

export default URLField;
