import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { makeStyles, TableRow, TableCell, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Image } from "../Image";
import { useDnDScroll } from "components/DnDScroll";

const useStyles = makeStyles((theme) => ({
  row: ({ isDragging, isOver, canDrag }) => ({
    opacity: isDragging ? 0.1 : 1,
    position: "relative",
    cursor: canDrag ? "grab" : isDragging ? "grabbing" : "pointer",
    marginBottom: isOver ? 150 : 0,
  }),
  cell: ({ isDragging }) => ({
    height: "20px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
  cellWithImage: ({ isDragging }) => ({
    width: 200,
  }),
  actionCell: {
    whiteSpace: "nowrap",
  },
  cellImageRoot: {
    width: 115,
    height: 85,
  },
}));

const ActionsButton = ({ Icon, link, onClick, data }) => {
  if (link) {
    return (
      <IconButton component={Link} to={link(data)}>
        <Icon />
      </IconButton>
    );
  }

  if (onClick) {
    return (
      <IconButton onClick={(evt) => onClick(evt, data)}>
        <Icon />
      </IconButton>
    );
  }
};

const DraggableTableRow = ({ headers, row, actions, onDrop, onMove }) => {
  const rowRef = useRef(null);
  const [setScrollActive] = useDnDScroll();
  const startingSequence = useRef(row.sequence);
  const [{ isDragging, canDrag }, drag] = useDrag({
    item: { ...row, type: "TABLE_ROW" },
    begin: (monitor) => {
      // store sequence of item when drag begins
      startingSequence.current = row.sequence;

      // make scroll zones visibile
      setScrollActive(true);
    },
    end: (dropResult, monitor) => {
      const item = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!onDrop) return;
      if (!didDrop) {
        // if item didn't drop on target, fire onMove/onDrop with the starting sequence
        onMove(item, { ...row, sequence: startingSequence.current });
      } else {
        // otherwise, handle the drop
        onDrop(item, { ...row });
      }

      // make scroll zones hidden
      setScrollActive(false);
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
      canDrag: !!monitor.canDrag(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: "TABLE_ROW",
    hover: (item, monitor) => {
      if (!rowRef.current) return;
      const dragSequence = item.sequence;
      const hoverSequence = row.sequence;

      if (dragSequence === hoverSequence) return;

      // rectangle size of hovered row
      const hoverBoundingRect = rowRef.current.getBoundingClientRect();

      // vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // mouse position
      const clientOffset = monitor.getClientOffset();

      // position distance of mouse to top of hover rect
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // if the dragged item has a lower sequence than the hovered item,
      // and the distance from top is less than the middle, return
      if (dragSequence < hoverSequence && hoverClientY < hoverMiddleY) {
        return;
      }

      // if the dragged item has a higher sequence than the hovered item,
      // and the distance from top is greater than the middle, return
      if (dragSequence > hoverSequence && hoverClientY > hoverMiddleY) {
        return;
      }

      // otherwise, handle move of items
      onMove(item, { ...row });

      // set the sequence of the current item
      item.sequence = hoverSequence;
    },

    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const classes = useStyles({ isDragging, isOver, canDrag });

  drag(drop(rowRef));
  return (
    <TableRow key={row.id} ref={rowRef} className={classes.row}>
      {headers.map(({ dataKey, image }) =>
        image ? (
          <TableCell key={dataKey} className={classes.cellWithImage}>
            <Image
              src={row[dataKey]}
              alt={row.name}
              classes={{
                root: classes.cellImageRoot,
                image: classes.cellImage,
              }}
            />
          </TableCell>
        ) : (
          <TableCell key={dataKey} className={classes.cell} align="left">
            {row[dataKey]}
          </TableCell>
        )
      )}
      {actions && (
        <TableCell key={row.id} className={classes.actionCell}>
          {actions.map((data, i) => (
            <ActionsButton
              key={i}
              Icon={data.Icon}
              link={data.link}
              onClick={data.onClick}
              data={row}
            />
          ))}
        </TableCell>
      )}
    </TableRow>
  );
};

export default DraggableTableRow;
