import React, { useEffect, useState } from "react";
import { useGoogleMaps } from "react-hook-google-maps";

import { makeStyles } from "@material-ui/core";
import _throttle from "lodash.throttle";

const useStyles = makeStyles(theme => ({
  root: {},
  map: ({ open }) => ({
    transition: "0.35s ease-out all",
    height: "300px",
    width: "100%",
    maxHeight: open ? 300 : 0
  })
}));

function EmbedMap({ onClick, onError, value, onValueChange, open }) {
  const { ref, map, google } = useGoogleMaps(
    process.env.REACT_APP_GOOGLE_API_KEY,
    {
      center: {
        lat: Number(value.lat),
        lng: Number(value.lng)
      },
      zoom: 10,
      clickableIcons: false
    }
  );

  const [tilesLoaded, setTilesLoaded] = useState(false);

  const classes = useStyles({ open });
  useEffect(() => {
    if (!google) return;
    if (!map) return;

    // click event on map
    const handleMapClick = mapsMouseEvent => {
      onClick(mapsMouseEvent, map, google);
    };

    // add load listener

    const loadListener = map.addListener("tilesloaded", () =>
      setTilesLoaded(true)
    );

    // add map click listener
    const clickListener = map.addListener(
      "click",
      _throttle(handleMapClick, 1000, { leading: true })
    );

    // remove listener on dismount
    return () => {
      google.maps.event.removeListener(clickListener);
      google.maps.event.removeListener(loadListener);
    };
  }, [google, map, onClick, onError, value, value.lat, value.lng]);

  useEffect(() => {
    if (!google) return;
    if (!map) return;
    if (!tilesLoaded) return;
    onValueChange(value, map, google);
  }, [google, map, onValueChange, tilesLoaded, value]);

  return (
    <div className={classes.root}>
      <div className={classes.map} ref={ref}></div>
    </div>
  );
}

EmbedMap.propTypes = {};

export default EmbedMap;
