import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BackButton } from "components/BackButton";
import { DashboardPageHeader } from "components/Dashboard";
import { SkeletonWrapper } from "components/SkeletonWrapper";
import { RoutedTabbedSection } from "components/TabbedSection";
import RoutedTabbedSectionSkeleton from "components/TabbedSection/RoutedTabbedSectionSkeleton";
import { PoisTable } from "./components";
import { Image } from "components/Image";
import { makeStyles } from "@material-ui/core";
import useRegionState from "hooks/useRegionState";
import useRegionDispatch from "hooks/useRegionDispatch";
import { PanelProvider } from "components/Panel";

const useStyles = makeStyles(theme => ({
  mainTabImage: {
    width: 138,
    height: 103
  },
  smallTabImage: {
    width: 100
  }
}));

function RegionPois({ match }) {
  const classes = useStyles();
  const { region, pois } = useRegionState();
  const {
    getRegionPois,
    changePoiDefault,
    changePoiSubDefault,
    movePoiSequence,
    moveSubPoiSequence,
    commitPoiSequences,
    commitSubPoiSequences
  } = useRegionDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    // if region isnt yet set, return
    if (!region.id) return;

    getRegionPois(region.id)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [getRegionPois, region.id]);

  const handleSetDefault = category => (event, id) => {
    const isDefault = Boolean(event.target.checked);

    if (!pois[category]) return;
    if (!region) return;
    changePoiDefault(region.id, "pois", pois[category].id, id, isDefault);
  };

  const handleSetSubDefault = (category, subCategory) => (event, id) => {
    const isDefault = Boolean(event.target.checked);

    if (!pois[category]) return;
    if (!pois[category].values[subCategory]) return;
    if (!region) return;

    changePoiSubDefault(
      region.id,
      pois[category].id,
      pois[category].values[subCategory].id,
      id,
      isDefault
    );
  };

  const handlePoiDrop = category => (event, id) => {
    commitPoiSequences(region.id, pois[category].id);
  };

  const handlePoiMove = category => (draggedItem, droppedItem) => {
    movePoiSequence(category, {
      id: draggedItem.id,
      sequence: droppedItem.sequence
    });
  };

  const handlePoiSubMove =
    (category, subCategory) => (draggedItem, droppedItem) => {
      moveSubPoiSequence(category, subCategory, {
        id: draggedItem.id,
        sequence: droppedItem.sequence
      });
    };

  const handlePoiSubDrop = (category, subCategory) => (event, id) => {
    commitSubPoiSequences(
      region.id,
      pois[category].id,
      pois[category].values[subCategory].id
    );
  };

  if (error) return <p>There was an error fetching region POIs.</p>;
  console.log("test");
  return (
    <PanelProvider>
      {region && (
        <BackButton path={`/dashboard/regions/${region.id}`}>
          Back to {region.name}
        </BackButton>
      )}
      <DashboardPageHeader subHeader={region ? region.name : "..."}>
        Points of interest
      </DashboardPageHeader>
      <SkeletonWrapper skeleton={RoutedTabbedSectionSkeleton} loading={loading}>
        {pois && (
          <RoutedTabbedSection
            tabs={Object.keys(pois)
              .sort((a, b) =>
                pois[a].id > pois[b].id ? 1 : pois[b].id > pois[a].id ? -1 : 0
              )
              .map((poiCategory, i) => ({
                icon: (
                  <Image
                    key={poiCategory}
                    alt={pois[poiCategory].name}
                    src={pois[poiCategory].icon}
                    classes={{ root: classes.mainTabImage }}
                  />
                ),
                label: pois[poiCategory].name,
                path: `${match.url}/${poiCategory}`,
                index: i
              }))}
          >
            <PoisTable
              pois={pois.shop}
              parentCategory="shop"
              stateKey="pois"
              onCheck={handleSetDefault("shop")}
              onDrop={handlePoiDrop("shop")}
              onMove={handlePoiMove("shop")}
            />
            <PoisTable
              pois={pois.dine}
              parentCategory="dine"
              stateKey="pois"
              onCheck={handleSetDefault("dine")}
              onDrop={handlePoiDrop("dine")}
              onMove={handlePoiMove("dine")}
            />
            <RoutedTabbedSection
              tabs={Object.keys(pois.play.values)
                .sort((a, b) =>
                  pois.play.values[a].id > pois.play.values[b].id
                    ? 1
                    : pois.play.values[b].id > pois.play.values[a].id
                    ? -1
                    : 0
                )
                .map((category, i) => ({
                  icon: (
                    <Image
                      alt={pois.play.values[category].name}
                      src={pois.play.values[category].icon}
                      classes={{ root: classes.smallTabImage }}
                    />
                  ),
                  label: pois.play.values[category].name,
                  path: `${match.url}/play/${pois.play.values[category].key}`,
                  index: i
                }))}
            >
              {Object.keys(pois.play.values).map((category, i) => (
                <PoisTable
                  key={category}
                  stateKey="pois"
                  parentCategory="play"
                  pois={pois.play.values[category]}
                  category={category}
                  onCheck={handleSetSubDefault("play", category)}
                  onDrop={handlePoiSubDrop("play", category)}
                  onMove={handlePoiSubMove("play", category)}
                />
              ))}
            </RoutedTabbedSection>
            <PoisTable
              pois={pois.spa}
              stateKey="pois"
              parentCategory="spa"
              onCheck={handleSetDefault("spa")}
              onDrop={handlePoiDrop("spa")}
              onMove={handlePoiMove("spa")}
            />
          </RoutedTabbedSection>
        )}
      </SkeletonWrapper>
    </PanelProvider>
  );
}

RegionPois.propTypes = {
  match: PropTypes.object.isRequired
};

export default RegionPois;
