import { useContext } from "react";
import DnDScrollContext from "./DnDScrollContext";

const useDnDScroll = () => {
  const { active, setActive } = useContext(DnDScrollContext);

  return [setActive, active];
};

export default useDnDScroll;
