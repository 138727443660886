import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { BackButton } from "components/BackButton";
import { DashboardPageHeader } from "components/Dashboard";
import useRegionState from "hooks/useRegionState";
import { RoutedTabbedSection } from "components/TabbedSection";
import { SkeletonWrapper } from "components/SkeletonWrapper";
import RoutedTabbedSectionSkeleton from "components/TabbedSection/RoutedTabbedSectionSkeleton";
import useRegionDispatch from "hooks/useRegionDispatch";
import { Image } from "components/Image";
import { makeStyles, Typography } from "@material-ui/core";
import { DestSelector } from "components/DestSelector";
import ResponsiveImageUploader from "components/ResponsiveImageUploader/ResponsiveImageUploader";
import useAlertsDispatch from "hooks/useAlertsDispatch";

const useStyles = makeStyles(theme => ({
  mainTabImage: {
    width: 138,
    height: 103
  }
}));

function RegionTvWifiOffice({ match }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const {
    getRegionTvOffice,
    updateDestinations,
    deleteDestination,
    updateMultiImage
  } = useRegionDispatch();

  const { addAlert } = useAlertsDispatch();
  const { region, tvOffice } = useRegionState();

  useEffect(() => {
    if (!region.id) return;
    getRegionTvOffice(region.id)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [getRegionTvOffice, region.id]);

  const handleDestSubmit = category => {
    return ({ destinations }, actions) => {
      updateDestinations(
        region.id,
        "tvOffice",
        category,
        destinations.map(destination => ({
          ...destination.address,
          id: destination.id
        })),
        tvOffice[category].id
      )
        .then(() => {
          addAlert({
            intent: "success",
            text: `Succesfully updated destinations for "${tvOffice[category].name}"`
          });
          actions.setSubmitting(false);
        })
        .catch(error => {
          addAlert({
            intent: "error",
            text: `There was an error updating destinations for "${tvOffice[category].name}"`
          });
          actions.setSubmitting(false);
        });
    };
  };

  const handleDestDelete = category => id => {
    deleteDestination(region.id, "tvOffice", category, id).catch(error => {
      addAlert({
        intent: "error",
        text: "There was an error deleting the destination."
      });
    });
  };

  const handleUpdateTvChannels = (featureId, values, actions) => {
    updateMultiImage(region.id, "tvOffice", "tv_channels", featureId, values)
      .then(() => {
        addAlert({
          intent: "success",
          text: `Succesfully updated images for "TV Channels"`
        });
        actions.setSubmitting(false);
      })
      .catch(err => {
        addAlert({
          intent: "error",
          text: `There was an error updating image.`
        });
        actions.setSubmitting(false);
        console.error(err);
      });
  };

  if (error)
    return (
      <Typography variant="body1" color="textSecondary">
        There was an error while getting TV Wifi Office data for this region.
      </Typography>
    );

  return (
    <div>
      {region && (
        <BackButton path={`/dashboard/regions/${region.id}`}>
          Back to {region.name}
        </BackButton>
      )}
      <DashboardPageHeader subHeader={region ? region.name : "..."}>
        TV Wifi Office
      </DashboardPageHeader>

      <SkeletonWrapper skeleton={RoutedTabbedSectionSkeleton} loading={loading}>
        {tvOffice && (
          <RoutedTabbedSection
            tabs={[
              tvOffice.copy_center,
              tvOffice.post_office,
              tvOffice.shipping,
              tvOffice.tv_channels
            ].map((cat, i) => ({
              icon: (
                <Image
                  src={cat.icon}
                  alt={cat.name}
                  classes={{ root: classes.mainTabImage }}
                />
              ),
              label: cat.name,
              path: `${match.url}/${cat.key}`,
              index: i
            }))}
          >
            <DestSelector
              multi
              destinations={tvOffice.copy_center.items}
              onSubmit={handleDestSubmit("copy_center")}
              onDelete={handleDestDelete("copy_center")}
            />
            <DestSelector
              multi
              destinations={tvOffice.post_office.items}
              onSubmit={handleDestSubmit("post_office")}
              onDelete={handleDestDelete("post_office")}
            />
            <DestSelector
              multi
              destinations={tvOffice.shipping.items}
              onSubmit={handleDestSubmit("shipping")}
              onDelete={handleDestDelete("shipping")}
            />
            <ResponsiveImageUploader
              desktop={tvOffice.tv_channels.desktop.image_url}
              mobile={tvOffice.tv_channels.mobile.image_url}
              onSubmit={handleUpdateTvChannels.bind(
                undefined,
                tvOffice.tv_channels.feature_id
              )}
            />
          </RoutedTabbedSection>
        )}
      </SkeletonWrapper>
    </div>
  );
}

RegionTvWifiOffice.propTypes = {
  match: PropTypes.object.isRequired
};

export default RegionTvWifiOffice;
