import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { BackButton } from "components/BackButton";
import { DashboardPageHeader } from "components/Dashboard";
import useRegionState from "hooks/useRegionState";
import { SkeletonWrapper } from "components/SkeletonWrapper";
import { RoutedTabbedSection } from "components/TabbedSection";
import RoutedTabbedSectionSkeleton from "components/TabbedSection/RoutedTabbedSectionSkeleton";
import { Typography } from "@material-ui/core";
import useRegionDispatch from "hooks/useRegionDispatch";
import EditorForm from "../components/EditorForm";
import EmailForm from "../components/EmailForm";
import useAlertsDispatch from "hooks/useAlertsDispatch";

function RegionGeneral({ match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const { region, general } = useRegionState();
  const { getRegionGeneral, updateRegionGeneral } = useRegionDispatch();
  const { addAlert } = useAlertsDispatch();
  useEffect(() => {
    if (!region.id) return;

    getRegionGeneral(region.id)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError(err);
        setLoading(false);
      });
  }, [getRegionGeneral, region.id]);

  const handlePrivacyPolicySubmit = ({ value }, actions) => {
    updateRegionGeneral(region.id, "privacy_policy", value)
      .then(() => {
        addAlert({
          intent: "success",
          text: `Succesfully updated privacy policy for "${region.name}".`,
        });
        actions.setSubmitting(false);
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          intent: "error",
          text: `There was an error while updating region privacy policy.`,
        });
        actions.setSubmitting(false);
      });
  };

  const handleTosSubmit = ({ value }, actions) => {
    updateRegionGeneral(region.id, "terms_of_service", value)
      .then(() => {
        addAlert({
          intent: "success",
          text: `Succesfully updated terms of service for "${region.name}".`,
        });
        actions.setSubmitting(false);
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          intent: "error",
          text: `There was an error while updating region TOS.`,
        });
        actions.setSubmitting(false);
      });
  };

  const handleEmailSubmit = ({ email }, actions) => {
    updateRegionGeneral(region.id, "contact_email", email)
      .then(() => {
        addAlert({
          intent: "success",
          text: `Succesfully updated contact email for "${region.name}".`,
        });
        actions.setSubmitting(false);
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          intent: "error",
          text: `There was an error while updating region contact email.`,
        });
        actions.setSubmitting(false);
      });
  };

  if (error) {
    return (
      <Typography variant="body1" color="textSecondary">
        There was an error while fetching general info for the region.
      </Typography>
    );
  }
  return (
    <div>
      {region && (
        <BackButton path={`/dashboard/regions/${region.id}`}>
          Back to {region.name}
        </BackButton>
      )}
      <DashboardPageHeader subHeader={region ? region.name : "..."}>
        General
      </DashboardPageHeader>

      <SkeletonWrapper loading={loading} skeleton={RoutedTabbedSectionSkeleton}>
        {general && (
          <RoutedTabbedSection
            tabs={[
              {
                label: "Privacy Policy",
                path: `${match.url}/privacy-policy`,
                index: 0,
              },
              { label: "Terms of Service", path: `${match.url}/tos`, index: 1 },
              {
                label: "Contact Email",
                path: `${match.url}/contact-email`,
                index: 2,
              },
            ]}
          >
            <EditorForm
              value={general.privacy_policy}
              cta="Save Privacy Policy"
              onSubmit={handlePrivacyPolicySubmit}
            />
            <EditorForm
              value={general.terms_of_service}
              cta="Save Terms Of Service"
              onSubmit={handleTosSubmit}
            />
            <EmailForm
              email={general.contact_email}
              onSubmit={handleEmailSubmit}
            />
          </RoutedTabbedSection>
        )}
      </SkeletonWrapper>
    </div>
  );
}

RegionGeneral.propTypes = {
  match: PropTypes.object.isRequired,
};

export default RegionGeneral;
