import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { FormWrapper } from "components/FormWrapper";
import { ImageUpload } from "components/ImageUpload";
import { HCButton } from "components/HCButton";
import useAlertsDispatch from "hooks/useAlertsDispatch";
import { makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  submit: { marginTop: theme.spacing(2) },
  form: {
    justifyContent: "center",
  },
}));

const IconEditFormValidationSchema = Yup.object().shape({
  icon: Yup.mixed().required("You must upload an icon for the category."),
});

function IconEditForm({ id, title, image, onSubmit, className }) {
  const { addAlert } = useAlertsDispatch();
  const classes = useStyles();

  const handleImageUploadError = (file, message) => {
    addAlert({
      intent: "error",
      text: message,
    });
  };

  const initialValues = {
    icon: image || "",
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={IconEditFormValidationSchema}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <FormWrapper
          onSubmit={handleSubmit}
          className={clsx(classes.form, className && className)}
        >
          <ImageUpload
            name="icon"
            id="icon"
            label="Icon"
            onError={handleImageUploadError}
          />
          <HCButton
            loading={isSubmitting}
            variant="contained"
            type="submit"
            color="primary"
            disabled={values.icon === image}
            className={{ root: classes.submit }}
            fullWidth
          >
            Update {title} Icon
          </HCButton>
        </FormWrapper>
      )}
    </Formik>
  );
}

IconEditForm.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default IconEditForm;
