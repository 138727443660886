import { useCallback } from "react";
import { useDispatch } from "react-redux";
import api from "utilities/API";
import {
  setRegionData,
  updateRegionData,
  setRegionHomes,
  setRegionHosts,
  setRegionLinks,
  updateRegionLink,
  setRegionPois,
  setPoiDefault,
  movePoiSequence,
  moveSubPoiSequence,
  setPoiSubDefault,
  updatePoi,
  addPoi,
  removePoi,
  setRegionTvOffice,
  updateDestinations,
  removeDestination,
  updateMultiImage,
  setRegionEmergency,
  updateAdminFeature,
  removeAdminFeaturePage,
  setRegionGeneral,
  updateRegionGeneral,
  setRegionAirportDetails,
  moveRegionItemSequence,
  updateAirportWebsite,
  updateBasicPoi,
  removeBasicPoi,
  addBasicPoi,
  setRegionGuestServices,
  updatePeoplePoi,
  addPeoplePoi,
  setRegionSuggestions,
  setRegionArchived,
  updateMoreLink,
  updateTerminalMapUrl,
  updateRentalMapUrl
} from "actions/region";
import useRegionState from "./useRegionState";

const useRegionDispatch = () => {
  const dispatch = useDispatch();
  const { pois, region } = useRegionState();
  return {
    getRegion: useCallback(
      async regionId => {
        try {
          const { region } = await api.getRegion(regionId);
          dispatch(setRegionData(region));
          return region;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
	mergeRegion: useCallback(
	  async (id, data) => {
		try {
		  const response = await api.mergeRegion(id, data);
		  dispatch(updateRegionData(response));
		  return response;
		} catch (error) {
		  throw error;
		}
	  },
	  [dispatch]
	),
    updateRegion: useCallback(
      async (regionId, { name, description, timezone, defaults }) => {
        try {
          const response = await api.updateRegion(regionId, {
            name,
            description,
            timezone,
			defaults
          });
          dispatch(updateRegionData(response));
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    getRegionHomes: useCallback(
      async regionId => {
        try {
          const response = await api.getRegionHomes(regionId);
          dispatch(setRegionHomes(response));
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    getRegionHosts: useCallback(
      async regionId => {
        try {
          const response = await api.getRegionHosts(regionId);
          dispatch(setRegionHosts(response));
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    getRegionLinks: useCallback(
      async regionId => {
        try {
          const response = await api.getLinks(regionId);
          dispatch(setRegionLinks(response));
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    updateRegionLink: useCallback(
      async (regionId, key, url) => {
        try {
          const response = await api.updateLink(regionId, key, url);
          dispatch(updateRegionLink({ key, url }));
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    getRegionPois: useCallback(
      async regionId => {
        try {
          const response = await api.getRegionPois(regionId);
          dispatch(setRegionPois(response));
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    changePoiDefault: useCallback(
      async (regionId, stateKey, featureId, poiId, is_default) => {
        try {
          const response = await api.setPOIDefault(
            regionId,
            featureId,
            poiId,
            is_default
          );

          dispatch(
            setPoiDefault({ featureId, key: stateKey, id: poiId, is_default })
          );
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    changePoiSubDefault: useCallback(
      async (regionId, featureId, subFeatureId, poiId, is_default) => {
        try {
          const response = await api.setPOIDefault(
            regionId,
            subFeatureId,
            poiId,
            is_default
          );
          dispatch(
            setPoiSubDefault({ featureId, subFeatureId, id: poiId, is_default })
          );
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    movePoiSequence: useCallback(
      async (category, { id, sequence }) => {
        try {
          dispatch(movePoiSequence({ category, id, sequence }));

          return;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    moveSubPoiSequence: useCallback(
      async (category, subCategory, { id, sequence }) => {
        try {
          dispatch(moveSubPoiSequence({ category, subCategory, id, sequence }));
          return;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),

    commitSubPoiSequences: useCallback(
      async (regionId, featureId, subFeatureId) => {
        try {
          const category = Object.keys(pois).find(
            key => pois[key].id === featureId
          );
          const subCategory = Object.keys(pois[category].values).find(
            key => pois[category].values[key].id === subFeatureId
          );

          const response = await api.updateRegionPoiSequences(
            regionId,
            subFeatureId,
            pois[category].values[subCategory].items
          );

          return response;
        } catch (error) {
          throw error;
        }
      },
      [pois]
    ),
    commitPoiSequences: useCallback(
      async (regionId, featureId) => {
        try {
          const category = Object.keys(pois).find(
            key => pois[key].id === featureId
          );

          const response = await api.updateRegionPoiSequences(
            regionId,
            featureId,
            pois[category].items
          );

          return response;
        } catch (error) {
          throw error;
        }
      },
      [pois]
    ),
    updatePoi: useCallback(
      async (regionId, stateKey, categoryKey, valueKey, poiId, values) => {
        try {
          const response = await api.updatePoi(regionId, poiId, values);
          dispatch(
            updatePoi({
              key: stateKey,
              category: categoryKey,
              valueKey,
              id: poiId,
              values: response
            })
          );

          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),

    archivePoi: useCallback(
      async (regionId, stateKey, categoryKey, valueKey, poiId) => {
        try {
          const response = await api.updatePoiArchive(regionId, poiId, 1);
          dispatch(
            removePoi({
              key: stateKey,
              category: categoryKey,
              valueKey,
              id: poiId
            })
          );
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    unarchivePoi: useCallback(
      async (regionId, stateKey, categoryKey, valueKey, poiId, values) => {
        try {
          await api.updatePoi(regionId, poiId, values);
          const response = await api.updatePoiArchive(regionId, poiId, 0);
          dispatch(
            removePoi({
              key: stateKey,
              category: categoryKey,
              valueKey,
              id: poiId
            })
          );
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    approvePoi: useCallback(
      async (regionId, stateKey, categoryKey, valueKey, poiId, values) => {
        try {
          const update = await api.updatePoi(regionId, poiId, values);
          await api.approvePoi(regionId, poiId);
          dispatch(
            updatePoi({
              key: stateKey,
              category: categoryKey,
              valueKey,
              id: poiId,
              values: update
            })
          );
          dispatch(
            removePoi({
              key: stateKey,
              category: categoryKey,
              valueKey,
              id: poiId
            })
          );
          return update;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    createPoi: useCallback(
      async (
        regionId,
        stateKey,
        featureId,
        categoryKey,
        valueKey,
        poiId,
        values
      ) => {
        try {
          const response = await api.createPoi(regionId, featureId, values);
          dispatch(
            addPoi({
              category: categoryKey,
              key: stateKey,
              valueKey,
              id: poiId,
              values: response
            })
          );

          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    updateMoreLink: useCallback(
      async (regionId, stateKey, featureId, categoryKey, valueKey, link) => {
        try {
          const response = await api.updateMoreLink(regionId, featureId, link);
          dispatch(
            updateMoreLink({
              link,

              category: categoryKey,
              valueKey,
              key: stateKey
            })
          );
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    deletePoi: useCallback(
      async (regionId, stateKey, categoryKey, valueKey, poiId) => {
        try {
          const response = await api.deletePoi(regionId, poiId);
          dispatch(
            removePoi({
              key: stateKey,
              category: categoryKey,
              valueKey,
              id: poiId
            })
          );
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    getRegionTvOffice: useCallback(
      async regionId => {
        try {
          const response = await api.getRegionTvOffice(regionId);

          const { copy_center, post_office, shipping, tv_channels } = response;
          dispatch(
            setRegionTvOffice({
              copy_center,
              post_office,
              shipping,
              tv_channels
            })
          );

          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    updateDestinations: useCallback(
      async (regionId, parent, category, destinations, featureId) => {
        try {
          const response = await api.updateDestinations(
            regionId,
            destinations,
            featureId
          );
          dispatch(
            updateDestinations({
              parent,
              category,
              destinations: response.items
            })
          );
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    deleteDestination: useCallback(
      async (regionId, parent, category, destinationId) => {
        try {
          const response = await api.deleteDestination(regionId, destinationId);
          dispatch(removeDestination({ parent, category, id: destinationId }));
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    updateMultiImage: useCallback(
      async (regionId, parent, category, featureId, { desktop, mobile }) => {
        try {
          const response = await api.updateMultiImage(regionId, featureId, {
            desktop,
            mobile
          });
          dispatch(
            updateMultiImage({
              parent,
              category,
              id: featureId,
              values: { desktop: response.desktop, mobile: response.mobile }
            })
          );
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    getRegionEmergency: useCallback(
      async regionId => {
        try {
          const response = await api.getRegionEmergency(regionId);
          dispatch(setRegionEmergency(response));
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    updateAdminFeature: useCallback(
      async (regionId, parent, category, pages, adminFeatureId) => {
        try {
          const response = await api.updateAdminFeature(
            regionId,
            adminFeatureId,
            pages
          );
          dispatch(
            updateAdminFeature({ parent, category, values: response.items })
          );
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    deleteAdminFeaturePage: useCallback(
      async (
        regionId,
        parent,
        category,
        adminFeatureId,
        adminFeaturePageId
      ) => {
        try {
          const response = await api.deleteAdminFeaturePage(
            regionId,
            adminFeatureId,
            adminFeaturePageId
          );

          dispatch(
            removeAdminFeaturePage({ parent, category, id: adminFeaturePageId })
          );
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    getRegionGeneral: useCallback(
      async regionId => {
        try {
          const response = await api.getRegionGeneral(regionId);
          const { privacy_policy, contact_email, terms_of_service } = response;
          dispatch(
            setRegionGeneral({
              privacy_policy,
              contact_email,
              terms_of_service
            })
          );
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    updateRegionGeneral: useCallback(
      async (regionId, key, value) => {
        if (
          !["privacy_policy", "contact_email", "terms_of_service"].includes(key)
        )
          throw new Error("Invalid value key.");
        try {
          const response = await api.updateRegionGeneral(regionId, {
            [key]: value
          });

          dispatch(updateRegionGeneral({ key, value }));

          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    getRegionAirportDetails: useCallback(
      async regionId => {
        try {
          const response = await api.getRegionAirportDetails(regionId);
          dispatch(setRegionAirportDetails(response));
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    moveRegionItemSequence: useCallback(
      async (key, category, id, newSequence) => {
        dispatch(
          moveRegionItemSequence({ key, category, id, sequence: newSequence })
        );
      },
      [dispatch]
    ),
    commitRegionItemSequences: useCallback(
      async (regionId, key, category, featureId) => {
        try {
          const items = region[key][category].items;

          const response = await api.updateRegionPoiSequences(
            regionId,
            featureId,
            items
          );

          return response;
        } catch (err) {
          throw err;
        }

        // try {
        //   const category = Object.keys(pois).find(
        //     (key) => pois[key].id === featureId
        //   );
        //   const subCategory = Object.keys(pois[category].values).find(
        //     (key) => pois[category].values[key].id === subFeatureId
        //   );

        //   const response = await api.updateRegionPoiSequences(
        //     regionId,
        //     subFeatureId,
        //     pois[category].values[subCategory].items
        //   );

        //   return response;
        // } catch (error) {
        //   throw error;
        // }
      },
      [region]
    ),

    updateRentalMapUrl: useCallback(
      async (regionId, website) => {
        try {
          const response = await api.updateLink(
            regionId,
            "rental_map",
            website
          );
          dispatch(updateRentalMapUrl({ website }));
          return response;
        } catch (err) {
          throw err;
        }
      },
      [dispatch]
    ),
    updateTerminalMapUrl: useCallback(
      async (regionId, website) => {
        try {
          const response = await api.updateLink(
            regionId,
            "terminal_map",
            website
          );
          dispatch(updateTerminalMapUrl({ website }));
          return response;
        } catch (err) {
          throw err;
        }
      },
      [dispatch]
    ),
    updateAirportWebsite: useCallback(
      async (regionId, website) => {
        try {
          const response = await api.updateAirportWebsite(regionId, website);
          dispatch(updateAirportWebsite({ website }));
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    updateAirportDirections: useCallback(async (regionId, destination) => {
      try {
        const response = await api.updateAirportDirections(
          regionId,
          destination
        );

        return response;
      } catch (error) {
        throw error;
      }
    }, []),
    updateBasicPoi: useCallback(
      async (regionId, key, category, poiId, values) => {
        try {
          const response = await api.updateBasicPoi(regionId, poiId, values);
          dispatch(
            updateBasicPoi({ key, category, id: poiId, values: response })
          );
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    ),
    updatePeoplePoi: useCallback(
      async (regionId, stateKey, category, poiId, values) => {
        try {
          const response = await api.updatePeoplePoi(regionId, poiId, values);
          dispatch(
            updatePeoplePoi({
              key: stateKey,
              category,
              id: poiId,
              values: response
            })
          );
          return response;
        } catch (err) {
          throw err;
        }
      },
      [dispatch]
    ),

    createPeoplePoi: useCallback(
      async (regionId, stateKey, category, featureId, values) => {
        try {
          const response = await api.createPeoplePoi(
            regionId,
            featureId,
            values
          );
          dispatch(
            addPeoplePoi({
              key: stateKey,
              category,
              values: response
            })
          );
          return response;
        } catch (err) {
          throw err;
        }
      },
      [dispatch]
    ),

    createBasicPoi: useCallback(
      async (regionId, key, category, featureId, values) => {
        try {
          const response = await api.createPoi(regionId, featureId, values);
          dispatch(addBasicPoi({ key, category, data: response }));
          return response;
        } catch (err) {
          throw err;
        }
      },
      [dispatch]
    ),
    deleteBasicPoi: useCallback(
      async (regionId, key, category, poiId) => {
        try {
          const response = await api.deletePoi(regionId, poiId);
          dispatch(removeBasicPoi({ key, category, id: poiId }));
          return response;
        } catch (err) {
          throw err;
        }
      },
      [dispatch]
    ),
    getRegionSuggestions: useCallback(
      async regionId => {
        try {
          const response = await api.getRegionSuggestions(regionId);
          dispatch(setRegionSuggestions(response));
          return response;
        } catch (err) {
          throw err;
        }
      },
      [dispatch]
    ),

    getRegionArchived: useCallback(
      async regionId => {
        try {
          const response = await api.getRegionArchived(regionId);
          dispatch(setRegionArchived(response));
          return response;
        } catch (err) {
          throw err;
        }
      },
      [dispatch]
    ),

    getRegionGuestServices: useCallback(
      async regionId => {
        try {
          const response = await api.getRegionGuestServices(regionId);
          const {
            banks,
            atms,
            gas_stations,
            private_chefs,
            private_tours,
            personal_shopper,
            massage_therapist,
            farmers_market,
            rentals,
            sunscreen
          } = response;
          dispatch(
            setRegionGuestServices({
              banks,
              atms,
              gas_stations,
              private_chefs,
              private_tours,
              personal_shopper,
              massage_therapist,
              farmers_market,
              rentals,
              sunscreen
            })
          );
          return response;
        } catch (error) {
          throw error;
        }
      },
      [dispatch]
    )
  };
};

export default useRegionDispatch;
