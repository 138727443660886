import React from "react";
import PropTypes from "prop-types";
import { PanelHeader } from "components/Panel";
import { useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import PoiDataForm from "../RegionPois/components/PoiDataForm";
import PeoplePoiForm from "./PeoplePoiForm";

const useStyles = makeStyles(theme => ({
  root: {}
}));

function PoiArchivePanel({ onUnarchive, pois, isPeople }) {
  const match = useRouteMatch();
  const classes = useStyles();
  const poiId = Number(match.params.id);

  const poi = poiId ? pois.items.find(({ id }) => id === poiId) : null;

  const hasReservation =
    poi.key === "dine" || poi.key === "play" || poi.key === "spa"
      ? true
      : false;

  const hasMenu = poi.key === "dine" || poi.key === "spa";

  if (!poi) return null;

  return (
    <div className={classes.root}>
      <PanelHeader
        header={`Review archived "${pois.name}" POI`}
        subHeader={pois.name}
        icon={pois.icon}
      />
      {isPeople ? (
        <PeoplePoiForm
          data={poi}
          onSubmit={onUnarchive}
          cta={"Un-archive POI"}
        />
      ) : (
        <PoiDataForm
          poi={poi}
          onSubmit={onUnarchive}
          withReservation={hasReservation}
          withMenu={hasMenu}
          cta="Un-archive POI"
        />
      )}
    </div>
  );
}

PoiArchivePanel.propTypes = {
  onUnarchive: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      address_line_1: PropTypes.string,
      address_line_2: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.number,
      image_url: PropTypes.string,
      is_approved: PropTypes.number,
      is_default: PropTypes.number,
      name: PropTypes.string,
      sequence_no: PropTypes.number,
      state: PropTypes.string
    })
  )
};

export default PoiArchivePanel;
