import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.dark,
    textDecoration: "none",
    transition: `0.3s color ${theme.transitions.easing.easeOut}`,
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.primary.main,
    },
  },
}));

function RegionLinkCell({ to, name, id }) {
  const classes = useStyles();

  if (!name || !id) return null;
  return (
    <Link to={`/dashboard/regions/${id}`} className={classes.root}>
      {name} <strong>({id})</strong>
    </Link>
  );
}

RegionLinkCell.propTypes = {
  to: PropTypes.string.isRequired,
  name: PropTypes.string,
  id: PropTypes.number,
};

export default RegionLinkCell;
