import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

function FallbackSwitch({ children, fallback: Fallback }) {
  return (
    <Switch>
      {children}
      {Fallback && <Route component={Fallback} />}
    </Switch>
  );
}

FallbackSwitch.propTypes = {
  fallback: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default FallbackSwitch;
