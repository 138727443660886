import React, { useState, useEffect } from "react";
import {
  TableRow,
  Paper,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import useStyles from "./styles";
import PaginatedTableHead from "./PaginatedTableHead";
import formatUTCDate from "utilities/formatUTCDate";
import useTableSort from "hooks/useTableSort";

const ActionsButton = ({ Icon, link, onClick, data }) => {
  if (link) {
    return (
      <IconButton component={Link} to={link(data)}>
        <Icon />
      </IconButton>
    );
  }

  if (onClick) {
    return (
      <IconButton onClick={(evt) => onClick(evt, data)}>
        <Icon />
      </IconButton>
    );
  }
};

function PaginatedTable({
  headers,
  rows,
  actions,
  query,
  order: initialOrder = "desc",
  orderBy: initialOrderBy,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState(initialOrder);
  const [orderBy, setOrderBy] = useState(initialOrderBy || headers[0].dataKey);
  // sort the rows with the order/orderBy given
  const sortedRows = useTableSort(rows, order, orderBy);

  // handle setting sort order and order by
  const handleSortRequest = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // handle changing current page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // handle changing rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // set page to 0 if query, order or orderBy change
  useEffect(() => {
    setPage(0);
  }, [query, order, orderBy]);

  const classes = useStyles();
  return (
    <>
      <TableContainer
        component={Paper}
        variant="outlined"
        className={classes.root}
      >
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <PaginatedTableHead
            headers={headers}
            actions={Boolean(actions)}
            order={order}
            orderBy={orderBy}
            onSortRequest={handleSortRequest}
          />
          <TableBody>
            {sortedRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id}>
                  {headers.map(({ dataKey, date }) => (
                    <TableCell
                      key={dataKey}
                      className={classes.cell}
                      align="left"
                    >
                      {date ? formatUTCDate(row[dataKey]) : row[dataKey]}
                    </TableCell>
                  ))}
                  {actions && (
                    <TableCell key={row.id} className={classes.cell}>
                      {actions.map((data, i) => (
                        <ActionsButton
                          key={i}
                          Icon={data.Icon}
                          link={data.link}
                          onClick={data.onClick}
                          data={row}
                        />
                      ))}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            {actions && <TableRow></TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}

PaginatedTable.propTypes = {};

export default PaginatedTable;
