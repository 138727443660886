import { createMuiTheme } from "@material-ui/core";

export default createMuiTheme({
  palette: {
    primary: {
      light: "#7eb4ff",
      main: "#3F84F4",
      dark: "#0058c1",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#94f9f4",
      main: "#60C6C1",
      dark: "#269591",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Quicksand, Roboto, sans-serif",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
  },
});
