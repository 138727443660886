import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import {
  makeStyles,
  Button,
  FormControl,
  FormHelperText,
  ButtonBase,
  Typography,
} from "@material-ui/core";

import { useField } from "formik";
import { NativeTypes } from "react-dnd-html5-backend";
import { useDrop } from "react-dnd";

import validateFile from "../../utilities/validateFile";
import clsx from "clsx";
import { HCModal } from "components/HCModal";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  previewContainer: {
    paddingBottom: (9 / 16) * 100 + "%",
    position: "relative",
    width: "100%",
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: ({ showError }) =>
      showError ? theme.palette.error.main : theme.palette.grey[500],
    background: ({ isActive }) =>
      isActive ? "rgba(0, 0, 0, 0.2)" : "transparent",
    // height: 115,
    // width: 185,
    // padding: theme.spacing(1),
  },
  previewImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
    userSelect: "none",
    userDrag: "none",
  },
  inputContainer: {
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  input: {},
  popupImg: {
    height: "auto",
    width: "100%",
    maxHeight: "90vh",
    maxWidth: "768px",
    objectFit: "contain",
  },
  previewImageBtn: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
  imgFallback: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function FullImageUpload(props) {
  // connect to formik
  const [field, meta, helpers] = useField(props);
  const [showModal, setShowModal] = useState(false);
  const { onError, className } = props;

  // setup drop target for previewContainer
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop: (item, monitor) => {
      const file = monitor.getItem().files[0];

      const error = validateFile(file, {
        maxSize: 1,
        types: ["image/gif", "image/jpeg", "image/png", "image/svg+xml"],
      });

      // if there is file validation error and onError callback is specified,
      // return early and call onError.
      if (error) {
        if (onError) {
          onError(file, error);
        }
        return;
      }

      // otherwise, set the formik value as the uploaded file
      helpers.setValue(file);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const handleInputValueChange = (evt) => {
    const file = evt.currentTarget.files[0];

    // handle validation the same as above with drop target
    const error = validateFile(file, {
      maxSize: 1,
      types: ["image/gif", "image/jpeg", "image/png", "image/svg+xml"],
    });

    if (error) {
      if (onError) {
        onError(file, error);
      }

      return;
    }

    helpers.setValue(evt.currentTarget.files[0]);
  };

  // on blur of input, set as touched
  const handleInputBlur = (evt) => {
    helpers.setTouched(true);
  };

  // is active is when there is a native file over element
  const isActive = canDrop && isOver;

  // if field has error and has been touched
  const showError = meta.error && meta.touched;

  const classes = useStyles({ isActive, showError });
  const isFile = (input) => "File" in window && input instanceof File;
  const previewURL = useMemo(
    () =>
      isFile(field.value)
        ? URL.createObjectURL(field.value)
        : field.value
        ? field.value
        : null,
    [field.value]
  );

  return (
    <FormControl
      className={clsx(classes.root, className && className)}
      error={showError}
    >
      {showError && <FormHelperText>{meta.error}</FormHelperText>}
      <div className={classes.previewContainer} ref={drop}>
        {previewURL && field.value ? (
          <ButtonBase
            className={classes.previewImageBtn}
            onClick={() => setShowModal(true)}
          >
            <img
              src={previewURL}
              alt="Preview"
              className={classes.previewImage}
            />
          </ButtonBase>
        ) : (
          <div className={classes.imgFallback}>
            <Typography variant="h5">
              <strong>Upload an image</strong>
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.inputContainer}>
        <Button
          variant="outlined"
          component="label"
          color="primary"
          onBlur={handleInputBlur}
        >
          Upload File
          <input
            accept="image/*"
            type="file"
            style={{ display: "none" }}
            {...props}
            name={field.name}
            id={field.id}
            onChange={handleInputValueChange}
            onBlur={handleInputBlur}
          />
        </Button>
      </div>
      <HCModal open={showModal} handleClose={() => setShowModal(false)}>
        <img src={previewURL} className={classes.popupImg} alt={"Preview"} />
      </HCModal>
    </FormControl>
  );
}

FullImageUpload.propTypes = {
  name: PropTypes.string.isRequired,
  onError: PropTypes.func,
};

export default FullImageUpload;
