function validateFile(
  file,
  config = {
    size: 1,
    types: ["image/gif", "image/jpeg", "image/png", "image/svg+xml"],
  }
) {
  if (!file) return "No valid file given.";
  const { size, type } = file;

  const mb = size / Math.pow(1024, 2);

  if (mb > config.maxSize) {
    return `The image must not be larger than ${config.maxSize} MB.`;
  }

  if (!config.types.includes(type)) {
    const typesFormatted = config.types.reduce((acc, curr, index) => {
      acc = `${acc}${acc && ","} ${curr}`;
      return acc;
    }, "");
    return `The image is the wrong type; this field only accepts: ${typesFormatted}`;
  }

  return null;
}

export default validateFile;
