import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import IconsListItem from "./IconsListItem";
import { RoutedPanel } from "components/Panel";
import { useRouteMatch, useHistory } from "react-router-dom";
import IconsEditPanel from "./IconsEditPanel";
import useSystemDispatch from "hooks/useSystemDispatch";
import useAlertsDispatch from "hooks/useAlertsDispatch";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  iconList: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
}));

function IconsList({ iconCategory }) {
  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();
  const { updateIcon, updateCategoryIcon } = useSystemDispatch();
  const { addAlert } = useAlertsDispatch();

  const closePanel = () => history.push({ pathname: match.url });
  const iconIdMatch = useRouteMatch({
    path: `${match.url}/:iconId`,
  });

  const handleIconCategoryUpdate = (values, actions) => {
    const iconCategoryId = iconCategory.id;

    if (!iconCategoryId) return;

    updateCategoryIcon(iconCategoryId, values.icon)
      .then((data) => {
        actions.setSubmitting(false);
        addAlert({
          intent: "success",
          text: `Succesfully updated icon for category "${data.title}".`,
        });
        closePanel();
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addAlert({
          intent: "error",
          text:
            error.message ||
            "There was an unknown error while updating category icon.",
        });
      });
  };

  const handleIconUpdate = (values, actions) => {
    const iconId = iconIdMatch?.params.iconId;

    if (!iconId) return;

    updateIcon(iconId, values.icon)
      .then((data) => {
        addAlert({
          intent: "success",
          text: `Succesfully updated "${data.title}" icon.`,
        });
        closePanel();
      })
      .catch((error) => {
        console.warn(error);
        addAlert({
          intent: "error",
          text:
            error.message || "There was an unknown error while updating icon.",
        });
      });
  };

  return (
    <Paper variant="outlined" className={classes.root}>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Click on an item to change the icon
      </Typography>
      <div className={classes.iconList}>
        <IconsListItem
          key={iconCategory.id}
          id={iconCategory.id}
          title={iconCategory.title}
          image={iconCategory.image_url}
          isCategory={true}
        />
        {iconCategory.icons.map(({ id, title, image_url }) => (
          <IconsListItem key={id} id={id} title={title} image={image_url} />
        ))}
      </div>

      <RoutedPanel path={`${match.url}/edit`} onClose={closePanel}>
        <IconsEditPanel
          data={iconCategory}
          onUpdate={handleIconCategoryUpdate}
        />
      </RoutedPanel>

      <RoutedPanel path={`${match.url}/:id(\\d+)`} onClose={closePanel}>
        <IconsEditPanel
          data={iconCategory.icons.find(
            ({ id }) => Number(id) === Number(iconIdMatch?.params.iconId)
          )}
          onUpdate={handleIconUpdate}
        />
      </RoutedPanel>
    </Paper>
  );
}

IconsList.propTypes = {
  icons: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      image_url: PropTypes.string.isRequired,
      icons: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
          image_url: PropTypes.string.isRequired,
        })
      ),
    })
  ),
};

export default IconsList;
