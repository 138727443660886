import React from "react";
import PropTypes from "prop-types";

import { Paper, makeStyles } from "@material-ui/core";

import RegionNewForm from "./RegionNewForm";

import { DashboardPageHeader } from "components/Dashboard";

import useSystemDispatch from "hooks/useSystemDispatch";
import useAlertsDispatch from "hooks/useAlertsDispatch";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(4),
  },
}));

function RegionsNew({ history }) {
  const classes = useStyles();

  const { createRegion } = useSystemDispatch();
  const { addAlert } = useAlertsDispatch();

  const handleSubmit = (values, actions) => {
    createRegion(values.name, values.description, values.timezone)
      .then((region) => {
        addAlert({
          intent: "success",
          text: `Succesfully created region "${region.name}"`,
        });
        actions.setSubmitting(false);
        history.push({ pathname: "/dashboard/regions" });
      })
      .catch((error) => {
        console.warn(error);
        addAlert({
          intent: "error",
          text: "There was an unknown error while creating a region.",
        });
        actions.setSubmitting(false);
      });
  };

  return (
    <div className={classes.root}>
      <DashboardPageHeader>Create new region</DashboardPageHeader>
      <Paper className={classes.paper}>
        <RegionNewForm onSubmit={handleSubmit} />
      </Paper>
    </div>
  );
}

RegionsNew.propTypes = {
  history: PropTypes.object.isRequired,
};

export default RegionsNew;
