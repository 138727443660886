import React from "react";
import PropTypes from "prop-types";
import { NativeTypes } from "react-dnd-html5-backend";
import { useDrop } from "react-dnd";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    "&:after": {
      content: "''",
      display: ({ isOver }) => (isOver ? "block" : "none"),
      position: "absolute",
      width: "100%",
      height: "100%",

      background: "rgba(0, 0, 0, 0.55)",
    },
  },
}));

function FileDropper({ children, onDrop, className }) {
  const [{ isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop: (item, monitor) => {
      const file = monitor.getItem().files[0];

      onDrop(file);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const classes = useStyles({ isOver });

  return (
    <div ref={drop} className={clsx(classes.root, className && className)}>
      {children}
    </div>
  );
}

FileDropper.propTypes = {
  onDrop: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default FileDropper;
