import React, { useState } from "react";
import PropTypes from "prop-types";
import { DraggableTable } from "components/DraggableTable";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useRouteMatch, useHistory, Link } from "react-router-dom";
import { RoutedPanel } from "components/Panel";
import { makeStyles } from "@material-ui/core";
import useRegionDispatch from "hooks/useRegionDispatch";
import useRegionState from "hooks/useRegionState";
import useAlertsDispatch from "hooks/useAlertsDispatch";
import { HCButton } from "components/HCButton";
import { HCModal } from "components/HCModal";
import PoiDeleteModal from "../RegionPois/components/PoiDeleteModal";
import PeoplePoiNewPanel from "./PeoplePoiNewPanel";
import PeoplePoiEditPanel from "./PeoplePoiEditPanel";
import DefaultCheckbox from "../RegionPois/components/DefaultCheckbox";
import ArchiveIcon from "@material-ui/icons/Archive";
import PoiArchiveModal from "../RegionPois/components/PoiArchiveModal";
import PoiLinkPanel from "../RegionPois/components/PoiLinkPanel";

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
    "& > div": {
      marginTop: theme.spacing(2),
      "&:first-of-type": {
        marginTop: 0
      }
    }
  }
}));

function PeoplePoiTable({
  categoryLabel,
  pois,
  onDrop,
  onMove,
  stateKey,
  category,
  maxChecked = 12,
  onCheck
}) {
  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();
  const { region } = useRegionState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);

  const [queuedDeleteData, setQueuedDeleteData] = useState();
  const [queuedArchiveData, setQueuedArchiveData] = useState();

  const {
    updatePeoplePoi,
    createPeoplePoi,
    deletePoi,
    archivePoi,
    updateMoreLink
  } = useRegionDispatch();
  const { addAlert } = useAlertsDispatch();

  // draggable table expects `sequence` not `sequence_no`
  const items = pois.items.map(({ sequence_no, ...item }) => ({
    ...item,
    sequence: sequence_no
  }));

  const defaultItems = pois.items.filter(({ is_default }) =>
    Boolean(is_default)
  );
  const optionsFormatted = items
    .map(option => ({
      ...option,
      category: categoryLabel,
      isDefault: (
        <DefaultCheckbox
          id={option.id}
          value={Boolean(option.is_default)}
          onCheck={onCheck}
          disabled={
            defaultItems.length + 1 > maxChecked &&
            Boolean(option.is_default) === false
          }
        />
      )
    }))
    .sort((a, b) => (a.sequence > b.sequence ? 1 : -1));

  const closePanel = () => {
    history.push({ pathname: match.url });
  };

  const handlePoiUpdate = (poiId, values, actions) => {
    updatePeoplePoi(region.id, stateKey, category, poiId, values)
      .then(() => {
        addAlert({
          intent: "success",
          text: "Succesfully updated POI."
        });
        actions.setSubmitting(false);
        closePanel();
      })
      .catch(err => {
        console.error(err);
        addAlert({
          intent: "error",
          text: "There was an error while updating the poi."
        });
        actions.setSubmitting(false);
      });
  };

  const handlePoiNew = (_, values, actions) => {
    createPeoplePoi(
      region.id,
      stateKey,
      category,
      region[stateKey][category].id,
      values
    )
      .then(() => {
        addAlert({
          intent: "success",
          text: "Succesfully created POI."
        });
        actions.setSubmitting(false);
        closePanel();
      })
      .catch(err => {
        console.error(err);
        addAlert({
          intent: "error",
          text: "There was an error while creating the poi."
        });
        actions.setSubmitting(false);
      });
  };

  const handleDeleteClick = (evt, data) => {
    setQueuedDeleteData(null);
    setQueuedDeleteData(data);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = (data, actions) => {
    deletePoi(region.id, stateKey, category, null, data.id)
      .then(() => {
        actions.setSubmitting(false);
        setShowDeleteModal(false);
        setQueuedDeleteData();
        addAlert({
          intent: "success",
          text: `Succesfully deleted POI "${data.name}" (ID #${data.id}).`
        });
      })
      .catch(err => {
        console.error(err);
        actions.setSubmitting(false);
        addAlert({
          intent: "error",
          text:
            err.response?.data?.message ||
            "There was an unknown error while deleting POI."
        });
      });
  };

  const handleArchiveClick = (evt, data) => {
    setQueuedArchiveData(null);
    setQueuedArchiveData(data);
    setShowArchiveModal(true);
  };

  const handleMoreLinkUpdate = (data, actions) => {
    updateMoreLink(region.id, stateKey, pois.id, category, null, data.link)
      .then(() => {
        actions.setSubmitting(false);
        addAlert({
          intent: "success",
          text: `Succesfully updated MORE link for "${pois.name}"`
        });
        history.push({ pathname: match.url });
      })
      .catch(err => {
        console.error(err);
        actions.setSubmitting(false);
        addAlert({
          intent: "success",
          text:
            err.response?.data?.message ||
            "There was an unknown error while updating MORE link."
        });
      });
  };

  const handleArchiveConfirm = (data, actions) => {
    const {
      id,
      image,
      name,
      description,
      address,
      phone,
      website,
      reservations
    } = data;
    archivePoi(region.id, stateKey, category, pois.key, id, {
      image,
      name,
      description,
      address,
      phone,
      website,
      reservations
    })
      .then(() => {
        actions.setSubmitting(false);
        setShowArchiveModal(false);
        setQueuedArchiveData(null);
        addAlert({
          intent: "success",
          text: `Succesfully archived POI "${data.name}" (ID #${data.id}).`
        });
      })
      .catch(err => {
        actions.setSubmitting(false);
        console.error(err);
        addAlert({
          intent: "error",
          text:
            err.response?.data?.message ||
            "There was an unknown error while archiving POI."
        });
      });
  };
  return (
    <div className={classes.root}>
      <div className={classes.actions}>
        <HCButton
          variant="outlined"
          color="primary"
          component={Link}
          to={`${match.url}/link`}
          className={classes.newBtn}
        >
          Edit MORE Link
        </HCButton>
        <HCButton
          variant="contained"
          color="primary"
          component={Link}
          to={`${match.url}/new`}
          className={classes.newBtn}
        >
          Create New {categoryLabel} POI
        </HCButton>
      </div>
      <DraggableTable
        onDrop={onDrop}
        onMove={onMove}
        headers={[
          {
            label: "ID",
            dataKey: "id"
          },

          {
            label: "Name",
            dataKey: "name"
          },
          {
            label: "Email",
            dataKey: "email"
          },
          {
            label: "Phone",
            dataKey: "phone_number"
          },
          {
            label: "Website",
            dataKey: "website"
          },
          {
            label: "Default",
            dataKey: "isDefault"
          }
        ]}
        rows={optionsFormatted}
        actions={[
          { Icon: EditIcon, link: ({ id }) => `${match.url}/${id}` },
          { Icon: DeleteIcon, onClick: handleDeleteClick },
          { Icon: ArchiveIcon, onClick: handleArchiveClick }
        ]}
      />
      <RoutedPanel path={`${match.url}/new`} onClose={closePanel}>
        <PeoplePoiNewPanel
          onSubmit={handlePoiNew}
          categoryLabel={categoryLabel}
          pois={items}
        />
      </RoutedPanel>

      <RoutedPanel path={`${match.url}/link`} onClose={closePanel}>
        <PoiLinkPanel onUpdate={handleMoreLinkUpdate} pois={pois} />
      </RoutedPanel>

      <RoutedPanel path={`${match.url}/:id(\\d+)`} onClose={closePanel}>
        <PeoplePoiEditPanel
          onSubmit={handlePoiUpdate}
          categoryLabel={categoryLabel}
          pois={items}
        />
      </RoutedPanel>

      <HCModal
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
      >
        <PoiDeleteModal
          data={queuedDeleteData}
          onClose={() => setShowDeleteModal(false)}
          onSubmit={handleDeleteConfirm}
        />
      </HCModal>
      <HCModal
        open={showArchiveModal}
        handleClose={() => setShowArchiveModal(false)}
      >
        <PoiArchiveModal
          data={queuedArchiveData}
          onClose={() => setShowArchiveModal(false)}
          onSubmit={handleArchiveConfirm}
        />
      </HCModal>
    </div>
  );
}

PeoplePoiTable.propTypes = {
  categoryLabel: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      website: PropTypes.string,
      sequence: PropTypes.number
    })
  ),
  onDrop: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired
};

export default PeoplePoiTable;
