import React from "react";
import PropTypes from "prop-types";
import { ImageUpload } from "components/ImageUpload";
import { Formik } from "formik";
import { Typography, makeStyles } from "@material-ui/core";
import { HCButton } from "components/HCButton";

const useStyles = makeStyles((theme) => ({
  uploadContainer: {
    display: "inline-flex",
    width: "auto",
  },
  box: {
    marginTop: theme.spacing(2),
    "&:first-of-type": {
      marginTop: 0,
    },
  },
  submit: {
    display: "inline-block",
    marginTop: theme.spacing(2),
  },
}));

function ResponsiveImageUploader({ mobile, desktop, onSubmit }) {
  const classes = useStyles();

  const initialValues = {
    desktop: desktop || "",
    mobile: mobile || "",
  };
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting, values }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.box}>
            <Typography variant="h6">Desktop Image</Typography>
            <ImageUpload
              name="desktop"
              id="desktop"
              label="Icon"
              className={classes.uploadContainer}
            />
          </div>
          <div className={classes.box}>
            <Typography variant="h6">Mobile Image</Typography>
            <ImageUpload
              name="mobile"
              id="mobile"
              label="Icon"
              className={classes.uploadContainer}
            />
          </div>
          <HCButton
            loading={isSubmitting}
            type="submit"
            variant="contained"
            color="primary"
            className={{ root: classes.submit }}
            disabled={JSON.stringify(initialValues) === JSON.stringify(values)}
          >
            Save Images
          </HCButton>
        </form>
      )}
    </Formik>
  );
}

ResponsiveImageUploader.propTypes = {
  desktop: PropTypes.string,
  mobile: PropTypes.string,
};

export default ResponsiveImageUploader;
