import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Card,
  CardActionArea,
  CardContent,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {},
  link: {},
}));

function CardLink({ path, children, className }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card
        className={clsx(classes.card, className && className)}
        variant="outlined"
        color="primary"
      >
        <CardActionArea
          disableRipple={true}
          component={Link}
          to={path}
          className={classes.link}
        >
          <CardContent>{children}</CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}

CardLink.propTypes = {
  path: PropTypes.string,
};

export default CardLink;
