import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@material-ui/core";
import { Link, useRouteMatch } from "react-router-dom";

import NoImageUrl from "../../../assets/No-Image.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    borderColor: ({ isCategory }) =>
      isCategory ? theme.palette.primary.main : "inherit",
    width: 135,
    marginRight: theme.spacing(4),
    "&:last-of-type": {
      marginRight: 0,
    },
  },
  imageContainer: {},
  imageWrapper: {
    width: 150,
    height: 111,
  },
  titleContainer: {},
  cardMedia: {
    backgroundColor: "rgba(0, 0, 0, 0.25)",
    backgroundRepeat: "none",
    backgroundPosition: "center",
    height: 100,
  },
  cardTitle: {
    color: ({ isCategory }) =>
      isCategory ? theme.palette.primary.main : theme.palette.text.secondary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

function IconsListItem({ id, title, image, isCategory }) {
  const match = useRouteMatch();
  const classes = useStyles({ isCategory });

  return (
    <Card variant="outlined" className={classes.root}>
      <CardActionArea
        component={Link}
        to={isCategory ? `${match.url}/edit` : `${match.url}/${id}`}
      >
        {image ? (
          <CardMedia
            className={classes.cardMedia}
            image={image}
            title={title}
          />
        ) : (
          <CardMedia
            className={classes.cardMedia}
            image={NoImageUrl}
            title={title}
          />
        )}
        <CardContent>
          <Typography variant="body1" className={classes.cardTitle}>
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

IconsListItem.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default IconsListItem;
