import React from "react";
import { forwardRef } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    display: "flex",
    maxWidth: "100%",
    overflow: "hidden",
  },

  tabWrapper: {
    marginRight: theme.spacing(3),
  },

  tabImage: {},
  tabText: {
    marginTop: theme.spacing(0.5),
  },
  contentRoot: {
    marginTop: theme.spacing(2),
  },
}));

const TabsSkeleton = () => {
  const classes = useStyles();
  const tabCount = 15;

  const skeletonItems = [...Array(tabCount)];
  return (
    <div className={classes.tabsRoot}>
      {skeletonItems.map((_, i) => (
        <div className={classes.tabWrapper} key={i}>
          <Skeleton
            animation="wave"
            variant="rect"
            width={138}
            height={103}
            className={classes.tabImage}
          />
          <Skeleton
            animation="wave"
            variant="text"
            width="100%"
            height={30}
            className={classes.tabText}
          />
        </div>
      ))}
    </div>
  );
};

const ContentSkeleton = () => {
  const classes = useStyles();
  return (
    <div className={classes.contentRoot}>
      <Skeleton animation="wave" variant="rect" width={"100%"} height={300} />
    </div>
  );
};

const IconsTabbedSectionSkeleton = forwardRef((props, ref) => {
  return (
    <div ref={ref} {...props}>
      <TabsSkeleton />
      <ContentSkeleton />
    </div>
  );
});

IconsTabbedSectionSkeleton.propTypes = {};

export default IconsTabbedSectionSkeleton;
