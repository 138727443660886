import React from "react";
import PropTypes from "prop-types";

import { Typography, makeStyles } from "@material-ui/core";
import { Image } from "../Image";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  image: {
    maxWidth: "100%",
  },
  iconRoot: {
    height: 110,
    width: 150,
  },
  text: {},
}));

function PanelHeader({ header, subHeader, icon }) {
  const classes = useStyles();
  return (
    <header className={classes.root}>
      {icon && (
        <div className={classes.icon}>
          <Image src={icon} alt={header} classes={{ root: classes.iconRoot }} />
        </div>
      )}
      <div className={classes.text}>
        {subHeader && (
          <Typography variant="body1" color="textSecondary">
            {subHeader}
          </Typography>
        )}
        {header && <Typography variant="h4">{header}</Typography>}
      </div>
    </header>
  );
}

PanelHeader.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string,
  icon: PropTypes.string,
};

export default PanelHeader;
