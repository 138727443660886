import React from "react";
import PropTypes from "prop-types";
import { Paper, makeStyles } from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import TableControls from "../TableControls";
import { SearchField } from "../SearchField";
import useSearchQuery from "../../hooks/useSearchQuery";
import PaginatedTable from "../PaginatedTable/PaginatedTable";

const useStyles = makeStyles((theme) => ({
  searchField: {
    maxWidth: "320px",
    width: "100%",
  },
}));

function HomesTable({ houses }) {
  const classes = useStyles();
  const [query, handleQueryChange, items] = useSearchQuery(houses);

  return (
    <Paper variant="outlined">
      <TableControls>
        <SearchField
          onChange={handleQueryChange}
          value={query}
          placeholder="Search by property name or address"
          className={classes.searchField}
        />
      </TableControls>
      <PaginatedTable
        headers={[
          {
            label: "House Id",
            dataKey: "id",
          },
          { label: "Region", dataKey: "region" },
          { label: "Property Name", dataKey: "propertyName" },
          { label: "User", dataKey: "user" },
          { label: "Created at", dataKey: "createdAt", date: true },
        ]}
        rows={items}
        actions={[
          { Icon: EditIcon, link: ({ id }) => `/dashboard/homes/${id}/edit` },
        ]}
        order="desc"
        orderBy="createdAt"
      />
    </Paper>
  );
}

HomesTable.propTypes = {
  houses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      propertyName: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      region: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      user: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    })
  ),
};

export default HomesTable;
