import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from "@material-ui/core";
import { NavLink } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  toolbar: theme.mixins.toolbar
}));

const AdapterLink = React.forwardRef((props, ref) => (
  <NavLink innerRef={ref} activeClassName="Mui-selected" {...props} />
));

function DashboardSidebar({ items, subItems }) {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.toolbar} />
      <List>
        {items.map((item, index) => (
          <ListItem
            button
            key={item.key}
            component={AdapterLink}
            to={`${item.path}`}
            exact={item.exact}
          >
            {item.icon && (
              <ListItemIcon>
                <item.icon />
              </ListItemIcon>
            )}
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      {subItems && (
        <>
          <Divider />
          <List>
            {subItems.map((item, index) => (
              <ListItem
                button
                key={item.key}
                component={AdapterLink}
                to={`${item.path}`}
                exact={item.exact}
              >
                {item.icon && (
                  <ListItemIcon>
                    <item.icon />
                  </ListItemIcon>
                )}
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Drawer>
  );
}

export default DashboardSidebar;
