import React from "react";
import PropTypes from "prop-types";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from "@material-ui/core";

import useStyles from "./styles";

function PaginatedTableHead({
  headers,
  actions = false,
  orderBy,
  order,
  onSortRequest,
}) {
  const classes = useStyles();

  const createSortHandler = (property) => (event) => {
    onSortRequest(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headers.map(({ label, dataKey }) => (
          <TableCell
            key={dataKey}
            className={classes.cell}
            sortDirection={orderBy === dataKey ? order : false}
          >
            <TableSortLabel
              active={orderBy === dataKey}
              direction={orderBy === dataKey ? order : "asc"}
              onClick={createSortHandler(dataKey)}
            >
              {label}
            </TableSortLabel>
          </TableCell>
        ))}
        {actions && <TableCell className={classes.cell}>Actions</TableCell>}
      </TableRow>
    </TableHead>
  );
}

PaginatedTableHead.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      dataKey: PropTypes.string.isRequired,
      date: PropTypes.bool,
    })
  ),
  actions: PropTypes.bool,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.oneOf(["desc", "asc"]).isRequired,
  onSortRequest: PropTypes.func.isRequired,
};

export default PaginatedTableHead;
