import React from "react";
import PropTypes from "prop-types";

import { Formik } from "formik";
import { InputField } from "../InputField";
import { FormWrapper } from "../FormWrapper";
import { MenuItem, makeStyles } from "@material-ui/core";
import { SelectField } from "../SelectField";
import HCButton from "../HCButton/HCButton";

import * as Yup from "yup";
import PhoneField from "components/PhoneField";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

const UserDataFormValidationSchema = Yup.object().shape({
  email: Yup.string().required("You must have an email set for the user"),
  firstName: Yup.string().required(
    "You must have an first name set for the user"
  ),
  lastName: Yup.string().required(
    "You must have an last name set for the user"
  ),
  phone: Yup.string(),
  accountStatus: Yup.mixed().oneOf(["active", "inactive"]),
});

function UserDataForm({ data, handleUpdate }) {
  const classes = useStyles();
  const initialValues = {
    email: data.email || "",
    firstName: data.first_name || "",
    lastName: data.last_name || "",
    phone: data.phone_number || "",
    accountStatus: data.is_active ? "active" : "inactive",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UserDataFormValidationSchema}
      onSubmit={handleUpdate(data)}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <FormWrapper onSubmit={handleSubmit}>
          <InputField
            full
            name="email"
            id="email"
            variant="outlined"
            label="Email"
            disabled={true}
          />
          <InputField
            half
            name="firstName"
            id="firstName"
            variant="outlined"
            label="First Name"
          />
          <InputField
            half
            name="lastName"
            id="lastName"
            variant="outlined"
            label="Last Name"
          />
          <PhoneField
            full
            type="tel"
            name="phone"
            id="phone"
            variant="outlined"
            label="Phone"
          />
          <SelectField
            full
            id="accountStatus"
            name="accountStatus"
            label="Account Status"
          >
            <MenuItem value={"active"}>Active</MenuItem>
            <MenuItem value={"inactive"}>Inactive</MenuItem>
          </SelectField>
          <HCButton
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            loading={isSubmitting}
            disabled={JSON.stringify(initialValues) === JSON.stringify(values)}
          >
            Update User Data
          </HCButton>
        </FormWrapper>
      )}
    </Formik>
  );
}

UserDataForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isActive: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string.isRequired,
    phone_number: PropTypes.string,
    plan_id: PropTypes.string,
    houses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        house_owner_name: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
  handleUpdate: PropTypes.func.isRequired,
};

export default UserDataForm;
