import React from "react";
import PropTypes from "prop-types";
import { PanelHeader } from "components/Panel";
import PoiSuggestionsForm from "./PoiSuggestionsForm";
import { useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import PeoplePoiForm from "./PeoplePoiForm";

const useStyles = makeStyles(theme => ({
  root: {}
}));

function PoiSuggestionsPanel({ onApprove, pois, type }) {
  const match = useRouteMatch();
  const classes = useStyles();
  const poiId = Number(match.params.id);

  const poi = poiId ? pois.items.find(({ id }) => id === poiId) : null;

  if (!poi) return null;

  return (
    <div className={classes.root}>
      <PanelHeader
        header={`Review suggested "${pois.name}" POI`}
        subHeader={pois.name}
        icon={pois.icon}
      />
      {type === "general" ? (
        <PoiSuggestionsForm poi={poi} onSubmit={onApprove} cta="Approve POI" />
      ) : type === "people" ? (
        <PeoplePoiForm data={poi} onSubmit={onApprove} cta="Approve POI" />
      ) : null}
    </div>
  );
}

PoiSuggestionsPanel.propTypes = {
  onApprove: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      address_line_1: PropTypes.string,
      address_line_2: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.number,
      image_url: PropTypes.string,
      is_approved: PropTypes.number,
      is_default: PropTypes.number,
      name: PropTypes.string,
      sequence_no: PropTypes.number,
      state: PropTypes.string
    })
  )
};

export default PoiSuggestionsPanel;
