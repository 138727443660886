import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import {
  makeStyles,
  FormControl,
  FormHelperText,
  Button,
} from "@material-ui/core";
import { FileDropper } from "components/FileDropper";
import validateFile from "../../../../utilities/validateFile";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    width: "100%",
    maxWidth: 550,
    margin: "0 auto",
    textAlign: "center",
  },
  preview: {
    paddingBottom: "56.25%",
    position: "relative",
  },
  previewInner: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "grey",
  },
  previewImage: {
    objectFit: "cover",
    objectPosition: "position",
    width: "100%",
    height: "100%",
  },
  inputContainer: {
    marginTop: theme.spacing(2),
  },
}));

function PoiImageUploader({ id, name, className, onError, ...props }) {
  const classes = useStyles();
  const [field, meta, helpers] = useField({ name });

  const showError = meta.error && meta.touched;

  const handleChangeFile = (file) => {
    const error = validateFile(file, {
      maxSize: 3.5,
      types: ["image/gif", "image/jpeg", "image/png", "image/svg+xml"],
    });

    if (onError && error) {
      onError(error);
      return;
    }

    helpers.setValue(file);
  };

  const handleFileDrop = (file) => handleChangeFile(file);

  const handleInputValueChange = (evt) => {
    const file = evt.currentTarget.files[0];
    handleChangeFile(file);
  };

  // on blur of input, set as touched
  const handleInputBlur = (evt) => {
    helpers.setTouched(true);
  };

  const isFile = (input) => "File" in window && input instanceof File;
  const previewURL = useMemo(
    () =>
      isFile(field.value)
        ? URL.createObjectURL(field.value)
        : field.value
        ? field.value
        : null,
    [field.value]
  );

  return (
    <FormControl
      className={clsx(classes.root, className && className)}
      error={showError}
    >
      {showError && <FormHelperText>{meta.error}</FormHelperText>}
      <FileDropper onDrop={handleFileDrop} className={classes.preview}>
        <div className={classes.previewInner}>
          {previewURL && (
            <img
              src={previewURL}
              alt="Preview"
              className={classes.previewImage}
            />
          )}
        </div>
      </FileDropper>
      <div className={classes.inputContainer}>
        <Button variant="outlined" component="label" color="primary">
          Upload File
          <input
            accept="image/*"
            type="file"
            style={{ display: "none" }}
            {...props}
            name={field.name}
            id={field.id}
            onChange={handleInputValueChange}
            onBlur={handleInputBlur}
          />
        </Button>
      </div>
    </FormControl>
  );
}

PoiImageUploader.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  onError: PropTypes.func,
};

export default PoiImageUploader;
