import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormWrapper } from "components/FormWrapper";
import { Typography, Button } from "@material-ui/core";
import { HCButton } from "components/HCButton";

function PoiSuggestionDeleteModal({ data, onClose, onSubmit }) {
  const [isSubmitting, setSubmitting] = useState(false);

  if (!data) return null;
  const handleDelete = (evt) => {
    evt.preventDefault();
    setSubmitting(true);
    if (onSubmit) onSubmit(data, { setSubmitting });
  };
  return (
    <FormWrapper onSubmit={handleDelete}>
      <Typography variant="h4" gutterBottom>
        Are you <strong>sure</strong> you want to permanently delete the
        suggestion <strong>{data.name}</strong>?
      </Typography>
      <Typography paragraph>
        This will permanently remove this POI from the suggested list.
      </Typography>
      <HCButton
        type="submit"
        loading={isSubmitting}
        variant="contained"
        color="primary"
        style={{ marginRight: "10px" }}
      >
        Yes, delete the suggestion
      </HCButton>
      <Button variant="contained" onClick={onClose}>
        No, go back
      </Button>
    </FormWrapper>
  );
}

PoiSuggestionDeleteModal.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PoiSuggestionDeleteModal;
