import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { PanelHeader } from "components/Panel";
import PeoplePoiForm from "./PeoplePoiForm";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function PeoplePoiNewPanel({ pois, onSubmit, categoryLabel }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PanelHeader
        header={`Create new ${categoryLabel}`}
        subHeader={categoryLabel}
      />
      <PeoplePoiForm onSubmit={onSubmit} cta={"Create People POI"} />
    </div>
  );
}

PeoplePoiNewPanel.propTypes = {
  pois: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
};

export default PeoplePoiNewPanel;
