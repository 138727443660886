import React from "react";
import PropTypes from "prop-types";

import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";

import { Paper, makeStyles } from "@material-ui/core";

import TableControls from "../TableControls";
import { SearchField } from "../SearchField";
import PaginatedTable from "../PaginatedTable/PaginatedTable";

import useSearchQuery from "../../hooks/useSearchQuery";

const useStyles = makeStyles((theme) => ({
  searchField: {
    maxWidth: "320px",
    width: "100%",
  },
}));

function RegionsTable({ regions = [], onDelete, onEdit }) {
  const classes = useStyles();
  const [query, handleQueryChange, items] = useSearchQuery(regions);

  return (
    <Paper variant="outlined">
      <TableControls>
        <SearchField
          onChange={handleQueryChange}
          value={query}
          placeholder="Search region by name"
          className={classes.searchField}
        />
      </TableControls>

      <PaginatedTable
        query={query}
        headers={[
          {
            label: "Region ID",
            dataKey: "id",
          },
          { label: "Region Name", dataKey: "name" },
          { label: "Timezone", dataKey: "timezone" },
          { label: "# of Properties", dataKey: "property_count" },
          { label: "Created at", dataKey: "created_at", date: true },
        ]}
        rows={items}
        actions={[
          {
            Icon: VisibilityIcon,
            link: ({ id }) => `/dashboard/regions/${id}`,
          },
          { Icon: DeleteIcon, onClick: onDelete },
        ]}
        order="desc"
        orderBy="created_at"
      />
    </Paper>
  );
}

RegionsTable.propTypes = {
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      timezone: PropTypes.string,
      property_count: PropTypes.number,
    })
  ),
};

export default RegionsTable;
