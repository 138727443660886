import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";
import computeSubscription from "utilities/computeSubscription";

const useStyles = makeStyles((theme) => ({
  root: {},
  textSection: {
    marginTop: theme.spacing(4),
    "&:first-of-type": {
      marginTop: "0px",
    },
  },
}));

function UserSubscription({ data }) {
  const classes = useStyles();

  const subscriptionData = computeSubscription(data.plan_id);

  return (
    <div className={classes.root}>
      {subscriptionData ? (
        <>
          <div className={classes.textSection}>
            <Typography variant="body1" color="textSecondary">
              {" "}
              Current Subscription:
            </Typography>
            <Typography variant="h5" color="primary" gutterBottom>
              {data.plan_id ? (
                <strong>{subscriptionData.name}</strong>
              ) : (
                <strong>No Subscription Plan</strong>
              )}
            </Typography>
          </div>

          <div className={classes.textSection}>
            <Typography variant="body1" color="textSecondary">
              {" "}
              Subscription Cycle:
            </Typography>
            <Typography variant="h5" color="primary" gutterBottom>
              {data.plan_id ? (
                <strong>{subscriptionData.cycle}</strong>
              ) : (
                <strong>Not Defined</strong>
              )}
            </Typography>
          </div>
        </>
      ) : (
        <Typography variant="h5" color="textSecondary">
          {" "}
          No subscription found.
        </Typography>
      )}
    </div>
  );
}

UserSubscription.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isActive: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string.isRequired,
    phone_number: PropTypes.string,
    plan_id: PropTypes.string,
    houses: PropTypes.arrayOf(
      PropTypes.shape({
        regionId: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        house_owner_name: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
};

export default UserSubscription;
