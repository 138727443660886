const SET_ALL_USERS = "SET_ALL_USERS";
const SET_ALL_HOUSES = "SET_ALL_HOUSES";
const SET_ALL_REGIONS = "SET_ALL_REGIONS";
const SET_ALL_ADMIN_USERS = "SET_ALL_ADMIN_USERS";
const SET_FEATURE_OPTIONS = "SET_FEATURE_OPTIONS";

const REMOVE_USER = "REMOVE_USER";
const ADD_USER = "ADD_USER";

const UPDATE_ADMIN_USER = "UPDATE_ADMIN_USER";
const REMOVE_ADMIN_USER = "REMOVE_ADMIN_USER";
const ADD_ADMIN_USER = "ADD_ADMIN_USER";

const ADD_REGION = "ADD_REGION";
const REMOVE_REGION = "REMOVE_REGION";

const CHANGE_FEATURE_ITEM_SEQUENCE = "CHANGE_FEATURE_ITEM_SEQUENCE";
const CHANGE_HOUSE_FEATURE_ITEM_SEQUENCE = "CHANGE_HOUSE_FEATURE_ITEM_SEQUENCE";

const UPDATE_FEATURE_OPTION = "UPDATE_FEATURE_OPTION";
const ADD_FEATURE_OPTION = "ADD_FEATURE_OPTION";
const REMOVE_FEATURE_OPTION = "REMOVE_FEATURE_OPTION";
const ADD_HOUSE_FEATURE_OPTION = "ADD_HOUSE_FEATURE_OPTION";
const UPDATE_HOUSE_FEATURE_OPTION = "UPDATE_HOUSE_FEATURE_OPTION";
const REMOVE_HOUSE_FEATURE_OPTION = "REMOVE_HOUSE_FEATURE_OPTION";
const UPDATE_FEATURE_CATEGORY = "UPDATE_FEATURE_CATEGORY";

const SET_ALL_ICONS = "SET_ALL_ICONS";
const UPDATE_ICON = "UPDATE_ICON";
const UPDATE_CATEGORY_ICON = "UPDATE_CATEGORY_ICON";

const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";

export {
  SET_ALL_USERS,
  SET_ALL_HOUSES,
  REMOVE_USER,
  SET_ALL_REGIONS,
  SET_ALL_ADMIN_USERS,
  ADD_REGION,
  REMOVE_REGION,
  UPDATE_ADMIN_USER,
  REMOVE_ADMIN_USER,
  ADD_ADMIN_USER,
  CHANGE_FEATURE_ITEM_SEQUENCE,
  SET_FEATURE_OPTIONS,
  UPDATE_FEATURE_OPTION,
  ADD_FEATURE_OPTION,
  REMOVE_FEATURE_OPTION,
  CHANGE_HOUSE_FEATURE_ITEM_SEQUENCE,
  UPDATE_FEATURE_CATEGORY,
  ADD_HOUSE_FEATURE_OPTION,
  UPDATE_HOUSE_FEATURE_OPTION,
  REMOVE_HOUSE_FEATURE_OPTION,
  SET_ALL_ICONS,
  UPDATE_ICON,
  UPDATE_CATEGORY_ICON,
  ADD_USER,
  SET_DASHBOARD_DATA
};
