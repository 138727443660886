import { useState, useEffect } from "react";

function filterSearch(array, value, key) {
  return array.filter(
    key
      ? a =>
          String(a[key])
            .toLowerCase()
            .includes(value.toLowerCase())
      : a =>
          Object.keys(a).some(k =>
            String(a[k])
              .toLowerCase()
              .includes(value.toLowerCase())
          )
  );
}

const useSearchQuery = (items, objectSelector) => {
  const [filteredItems, setFilteredItems] = useState(items);

  const [query, setQuery] = useState("");

  const handleQueryChange = evt => {
    setQuery(evt.target.value);
  };

  useEffect(() => {
    if (!items) return;

    const filteredData = filterSearch(items, query, objectSelector);

    setFilteredItems(filteredData);
  }, [items, objectSelector, query]);

  return [query, handleQueryChange, filteredItems];
};

export default useSearchQuery;
