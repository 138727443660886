import React from "react";
import PropTypes from "prop-types";

import { makeStyles, FormHelperText, FormControl } from "@material-ui/core";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useField } from "formik";

// default editor config - removes all formatting plugin, keep in-sync with homeowner portal/guest experience
const editorConfig = {
  removePlugins: [
    "Bold",
    "Italic",
    "Underline",
    "UploadAdapter",
    "BlockQuote",
    "CKFinder",
    "EasyImage",
    "Heading",
    "Image",
    "ImageCaption",
    "ImageStyle",
    "ImageToolbar",
    "ImageUpload",
    "Indent",
    "List",
    "MediaEmbed",
    "PasteFromOffice",
    "Table",
    "TableToolbar"
  ],
  toolbar: []
};

const useStyles = makeStyles(theme => ({
  editor: ({ showError }) => ({
    width: "100%",
    padding: theme.spacing(1),
    "& p": {
      margin: 0
    },
    "& .ck-editor__editable": {
      height: 300,
      borderColor: `${
        showError ? theme.palette.error.main : "rgba(0, 0, 0, 0.23)"
      } !important`,
      "& p[data-placeholder]::before": {
        color: showError
          ? theme.palette.error.main
          : theme.palette.text.disabled
      }
    }
  })
}));

function InputEditor(props) {
  const [field, meta, helpers] = useField(props);

  const showError = meta.error && meta.touched;

  const config = {
    ...editorConfig,
    placeholder: props.label
  };

  // Save for use later if needing to do init operations on ckeditor
  const handleInit = editor => {};

  // whenever the editor value changes, set formik field value
  const handleChange = (event, editor) => {
    const data = editor.getData();
    helpers.setValue(data);
  };

  // set touched when onBlur is fired
  const handleBlur = (event, editor) => {
    helpers.setTouched(true);
  };

  // Save for use later if needing to do focus operations on ckeditor
  const handleFocus = () => {};

  const classes = useStyles({ showError });

  return (
    <FormControl
      className={classes.editor}
      error={showError}
      variant="outlined"
    >
      <CKEditor
        editor={ClassicEditor}
        config={config}
        data={field.value}
        onInit={handleInit}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      {showError && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
}

InputEditor.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string
};

export default InputEditor;
