import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Fab } from "@material-ui/core";
import { AddressField } from "components/AddressField";
import RemoveIcon from "@material-ui/icons/Remove";
const useStyles = makeStyles(theme => ({
  root: { width: "100%", display: "flex", alignItems: "center" }
}));

function DestSelectorField({ name, id, half, full, onDelete }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AddressField name={name} placeholder="Destination" />
      <div className={classes.actions}>
        <Fab
          color="primary"
          aria-label="delete"
          size="small"
          onClick={onDelete}
        >
          <RemoveIcon />
        </Fab>
      </div>
    </div>
  );
}

DestSelectorField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  half: PropTypes.bool,
  full: PropTypes.bool,
  onDelete: PropTypes.func.isRequired
};

export default DestSelectorField;
