import { createReducer } from "@reduxjs/toolkit";
import { addAlert, removeAlert } from "../actions/alerts";
const initialState = [];

const alertsReducer = createReducer(initialState, {
  [addAlert]: (state, { payload }) => {
    return [...state, payload];
  },
  [removeAlert]: (state, { payload }) => {
    return state.filter((alert) => alert.id !== payload);
  },
});

export { initialState };
export default alertsReducer;
