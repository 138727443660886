import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { FormWrapper } from "components/FormWrapper";
import { HCButton } from "components/HCButton";
import { makeStyles } from "@material-ui/core";
import { InputField } from "components/InputField";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

function EmailForm({ email = "", cta = "Submit", onSubmit }) {
  const initialValues = {
    email,
  };

  const classes = useStyles();

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting, values }) => (
        <FormWrapper onSubmit={handleSubmit}>
          <InputField
            type="email"
            variant="outlined"
            label="Contact Email"
            full
            name="email"
            id="email"
          />
          <HCButton
            type="submit"
            color="primary"
            variant="contained"
            loading={isSubmitting}
            disabled={values.email === initialValues.email}
            className={{ root: classes.submit }}
          >
            {cta}
          </HCButton>
        </FormWrapper>
      )}
    </Formik>
  );
}

EmailForm.propTypes = {
  email: PropTypes.string,
  cta: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default EmailForm;
