import React from "react";
import PropTypes from "prop-types";

import { Paper, makeStyles } from "@material-ui/core";

import AdminUsersNewForm from "./AdminUsersNewForm";

import { DashboardPageHeader } from "components/Dashboard";

import useSystemDispatch from "hooks/useSystemDispatch";
import useAlertsDispatch from "hooks/useAlertsDispatch";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(4),
  },
}));

function AdminUsersNew({ history }) {
  const classes = useStyles();

  const { createAdminUser } = useSystemDispatch();
  const { addAlert } = useAlertsDispatch();

  const handleSubmit = (values, actions) => {
    createAdminUser(values)
      .then((user) => {
        addAlert({
          intent: "success",
          text: `Succesfully created admin user "${user.email}"`,
        });
        history.push({ pathname: "/dashboard/admin" });
        actions.setSubmitting(false);
      })
      .catch((error) => {
        console.warn(error);
        addAlert({
          intent: "error",
          text: error.message,
        });
        actions.setSubmitting(false);
      });
  };

  return (
    <div className={classes.root}>
      <DashboardPageHeader>Create new admin user</DashboardPageHeader>
      <Paper className={classes.paper}>
        <AdminUsersNewForm onSubmit={handleSubmit} />
      </Paper>
    </div>
  );
}

AdminUsersNew.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AdminUsersNew;
