import React from "react";
import PropTypes from "prop-types";

import PublicIcon from "@material-ui/icons/Public";
import { Link as Anchor, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: ({ disabled }) => ({
    color: disabled ? theme.palette.grey[300] : theme.palette.primary.light,
    pointerEvents: disabled ? "none" : "all",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  }),
}));

function URLIcon({ url, disabled, icon: Icon }) {
  const classes = useStyles({ disabled });

  const formattedUrl = (rawUrl) => {
    if (!rawUrl.match(/^[a-zA-Z]+:\/\//)) {
      return "http://" + rawUrl;
    }
    return rawUrl;
  };

  return (
    <Anchor
      className={classes.root}
      disabled={disabled}
      href={disabled ? undefined : formattedUrl(url)}
      target="_blank"
      rel="noopener noreferrer"
      color="primary"
    >
      {Icon ? <Icon /> : <PublicIcon />}
    </Anchor>
  );
}

URLIcon.propTypes = {
  url: PropTypes.string,
  disabled: PropTypes.bool,
};

export default URLIcon;
