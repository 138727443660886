import React from "react";

import { Paper, makeStyles, Typography } from "@material-ui/core";

import { DashboardPageHeader } from "../../../components/Dashboard";

import UsersGiftNewForm from "./UsersGiftNewForm";
import useAlertsDispatch from "hooks/useAlertsDispatch";
import useSystemDispatch from "hooks/useSystemDispatch";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
}));

function UsersNew({ history }) {
  const { createGiftUser } = useSystemDispatch();
  const { addAlert } = useAlertsDispatch();

  const handleGiftUserCreate = (
    {
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      confirmPassword,
      duration,
    },
    actions
  ) => {
    createGiftUser({
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      confirmPassword,
      duration,
    })
      .then(() => {
        addAlert({
          intent: "success",
          text: `Succesfully created gift user "${email}"`,
        });
        actions.setSubmitting(false);
        history.push({ pathname: "/dashboard/users" });
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          intent: "error",
          text: "There was an unknown error while creating a region.",
        });
        actions.setSubmitting(false);
      });
  };

  const classes = useStyles();
  return (
    <>
      <DashboardPageHeader>Gift Account</DashboardPageHeader>
      <Paper className={classes.paper}>
        <Typography paragraph>
          Create an account with the "Complimentary" plan.
        </Typography>
        <Typography paragraph>
          Once the subscription duration is met, the users plan will be removed
          from their account and they will not be able to view their homes until
          they add a payment method and choose a paid subscription plan.
        </Typography>
        <UsersGiftNewForm onSubmit={handleGiftUserCreate} />
      </Paper>
    </>
  );
}

UsersNew.propTypes = {};

export default UsersNew;
