import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { PanelHeader } from "components/Panel";
import IconEditForm from "./IconEditForm";

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {
    marginTop: theme.spacing(3),
  },
}));

function IconsEditPanel({ data, onUpdate }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PanelHeader header={data.title} subHeader={"Icon"} />
      <IconEditForm
        id={data.id}
        title={data.title}
        image={data.image_url}
        className={classes.form}
        onSubmit={onUpdate}
      />
    </div>
  );
}

IconsEditPanel.propTypes = {
  categoryData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    icons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        image_url: PropTypes.string.isRequired,
      })
    ),
  }),
};

export default IconsEditPanel;
