import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { BackButton } from "components/BackButton";
import { DashboardPageHeader } from "components/Dashboard";
import useRegionState from "hooks/useRegionState";
import { SkeletonWrapper } from "components/SkeletonWrapper";
import RoutedTabbedSectionSkeleton from "components/TabbedSection/RoutedTabbedSectionSkeleton";
import { RoutedTabbedSection } from "components/TabbedSection";
import { Image } from "components/Image";
import { makeStyles, Typography } from "@material-ui/core";
import useRegionDispatch from "hooks/useRegionDispatch";
import { PanelProvider } from "components/Panel";
import PoiSuggestionsTable from "./components/PoiSuggestionsTable";

const useStyles = makeStyles(theme => ({
  mainTabImage: {
    width: 138,
    height: 103
  },
  smallTabImage: {
    width: 100
  }
}));

function RegionPending({ match }) {
  const classes = useStyles();

  const { region, suggestions } = useRegionState();

  const { getRegionSuggestions } = useRegionDispatch();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    if (!region.id) return;

    getRegionSuggestions(region.id)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setError(error);
        setLoading(false);
      });
  }, [getRegionSuggestions, region.id]);

  if (error)
    return (
      <Typography variant="body2" color="textSecondary">
        There was an error fetching the suggestions for the region.
      </Typography>
    );
  return (
    <PanelProvider>
      {region && (
        <BackButton path={`/dashboard/regions/${region.id}`}>
          Back to {region.name}
        </BackButton>
      )}
      <DashboardPageHeader subHeader={region ? region.name : "..."}>
        Pending suggestions
      </DashboardPageHeader>

      <SkeletonWrapper skeleton={RoutedTabbedSectionSkeleton} loading={loading}>
        {suggestions && (
          <RoutedTabbedSection
            tabs={[
              suggestions.shop,
              suggestions.dine,
              suggestions.play,
              suggestions.spa,
              suggestions.guest_services
            ].map((cat, i) => ({
              icon: (
                <Image
                  src={cat.icon}
                  alt={cat.name}
                  classes={{ root: classes.mainTabImage }}
                />
              ),
              label: cat.name,
              path: `${match.url}/${cat.key}`,
              index: i
            }))}
          >
            <PoiSuggestionsTable
              pois={suggestions.shop}
              stateKey="suggestions"
              parentCategory="shop"
            />
            <PoiSuggestionsTable
              pois={suggestions.dine}
              stateKey="suggestions"
              parentCategory="dine"
            />
            <RoutedTabbedSection
              tabs={Object.keys(suggestions.play.values)
                .sort((a, b) =>
                  suggestions.play.values[a].id > suggestions.play.values[b].id
                    ? 1
                    : suggestions.play.values[b].id >
                      suggestions.play.values[a].id
                    ? -1
                    : 0
                )
                .map((subCatKey, i) => ({
                  icon: (
                    <Image
                      src={suggestions.play.values[subCatKey].icon}
                      alt={suggestions.play.values[subCatKey].name}
                      classes={{ root: classes.smallTabImage }}
                    />
                  ),
                  label: suggestions.play.values[subCatKey].name,
                  path: `${match.url}/play/${suggestions.play.values[subCatKey].key}`,
                  index: i
                }))}
            >
              {Object.keys(suggestions.play.values)
                .sort((a, b) =>
                  suggestions.play.values[a].id > suggestions.play.values[b].id
                    ? 1
                    : suggestions.play.values[b].id >
                      suggestions.play.values[a].id
                    ? -1
                    : 0
                )
                .map((subCatKey, i) => (
                  <PoiSuggestionsTable
                    key={suggestions.play.values[subCatKey].id}
                    pois={suggestions.play.values[subCatKey]}
                    stateKey="suggestions"
                    parentCategory={"play"}
                  />
                ))}
            </RoutedTabbedSection>

            <PoiSuggestionsTable
              pois={suggestions.spa}
              stateKey="suggestions"
              parentCategory="spa"
            />
            <RoutedTabbedSection
              tabs={Object.keys(suggestions.guest_services.values)
                .sort((a, b) =>
                  suggestions.guest_services.values[a].id >
                  suggestions.guest_services.values[b].id
                    ? 1
                    : suggestions.guest_services.values[b].id >
                      suggestions.guest_services.values[a].id
                    ? -1
                    : 0
                )
                .map((subCatKey, i) => ({
                  icon: (
                    <Image
                      src={suggestions.guest_services.values[subCatKey].icon}
                      alt={suggestions.guest_services.values[subCatKey].name}
                      classes={{ root: classes.smallTabImage }}
                    />
                  ),
                  label: suggestions.guest_services.values[subCatKey].name,
                  path: `${match.url}/guestServices/${suggestions.guest_services.values[subCatKey].key}`,
                  index: i
                }))}
            >
              {Object.keys(suggestions.guest_services.values)
                .sort((a, b) =>
                  suggestions.guest_services.values[a].id >
                  suggestions.guest_services.values[b].id
                    ? 1
                    : suggestions.guest_services.values[b].id >
                      suggestions.guest_services.values[a].id
                    ? -1
                    : 0
                )
                .map((subCatKey, i) => (
                  <PoiSuggestionsTable
                    type={subCatKey === "rentals" ? "general" : "people"}
                    key={suggestions.guest_services.values[subCatKey].id}
                    pois={suggestions.guest_services.values[subCatKey]}
                    stateKey="suggestions"
                    parentCategory={"guest_services"}
                  />
                ))}
            </RoutedTabbedSection>
          </RoutedTabbedSection>
        )}
      </SkeletonWrapper>
    </PanelProvider>
  );
}

RegionPending.propTypes = {
  match: PropTypes.object.isRequired
};

export default RegionPending;
