import React from "react";
// import PropTypes from "prop-types";
import { makeStyles, Fab } from "@material-ui/core";
import { Formik, FieldArray } from "formik";
import FeaturePageForm from "./FeaturePageForm";
import { HCButton } from "components/HCButton";
import AddIcon from "@material-ui/icons/Add";

import * as Yup from "yup";

const useStyles = makeStyles(theme => ({
  root: {},
  repeaterForms: {},
  repeaterActions: {
    textAlign: "center"
  },
  repeaterSubmit: {
    marginTop: theme.spacing(2)
  },
  repeaterAdd: {
    marginTop: theme.spacing(2)
  },
  submit: {
    display: "inline-block"
  }
}));

const FeatureEditorValidationSchema = Yup.object().shape({
  features: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().nullable(),
      description: Yup.string().required("Description is required"),
      image: Yup.mixed().test(
        "is-valid-image",
        "You must upload a valid image.",
        value => {
          if (
            typeof value === "object" &&
            value instanceof File &&
            value !== null
          )
            return true;
          if (typeof value === "string" && value) return true;
          return false;
        }
      )
    })
  )
});

function FeatureEditor({ feature, onSubmit, onDelete }) {
  const classes = useStyles();
  const handleSubmit = (values, actions) => {
    onSubmit({ ...values, id: feature.id }, actions);
  };

  const initialValues = {
    features:
      feature.items.length > 0
        ? feature.items.map(item => ({
            id: item.id,
            description: item.description,
            image: item.image_url
          }))
        : [{ description: "", image: null, id: null }]
  };

  return (
    <div className={classes.root}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={FeatureEditorValidationSchema}
        enableReinitialize={true}
      >
        {({ handleSubmit, values, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <FieldArray
              name="features"
              render={arrayHelpers => (
                <div className={classes.repeaterRoot}>
                  <div className={classes.repeaterForms}>
                    {values.features?.map((feature, index) => (
                      <FeaturePageForm
                        key={feature.id || `index-${index}`}
                        page={feature}
                        name={`features.[${index}]`}
                        onRemove={() =>
                          !feature.id
                            ? values.features.length > 1
                              ? arrayHelpers.remove(index)
                              : null
                            : onDelete(feature.id)
                        }
                      />
                    ))}
                  </div>
                  <div className={classes.repeaterActions}>
                    <Fab
                      variant="extended"
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          description: "",
                          image: null,
                          id: null
                        })
                      }
                      size="small"
                      className={classes.repeaterAdd}
                    >
                      <AddIcon style={{ marginRight: 8 }} />
                      Add new page
                    </Fab>
                  </div>
                  <div className={classes.repeaterSubmit}>
                    <HCButton
                      loading={isSubmitting}
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={{ root: classes.submit }}
                    >
                      Update Feature Pages
                    </HCButton>
                  </div>
                </div>
              )}
            />
          </form>
        )}
      </Formik>
    </div>
  );
}

FeatureEditor.propTypes = {};

export default FeatureEditor;
