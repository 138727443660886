import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core";

import { TabbedSection } from "components/TabbedSection";
import { UsersTable } from "components/UsersTable";
import { HouseDataForm } from "components/HouseDataForm";
import { BackButton } from "components/BackButton";
import { DashboardPageHeader } from "components/Dashboard";

import api from "utilities/API";

import useAlertsDispatch from "hooks/useAlertsDispatch";
import useSystemDispatch from "hooks/useSystemDispatch";
import computeSubscription from "utilities/computeSubscription";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function HomesEdit({ match }) {
  const homeId = match.params.id;
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [house, setHouse] = useState(null);
  const { addAlert } = useAlertsDispatch();
  const { deleteUser } = useSystemDispatch();
  const [hosts, setHosts] = useState(null);
  useEffect(() => {
    setLoading(true);
    api
      .getHouse(homeId)
      .then(({ house: houseData }) => {
        setHouse(houseData);
        return api.getHouseHosts(homeId).then(({ hosts }) => {
          setHosts(hosts);
          setLoading(false);
        });
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [homeId]);

  const handleUpdateHouseInfo = (values, actions) => {
    api.updateHouse({ id: homeId, ...values }).then((houseData) => {
      setHouse(houseData.house);
      addAlert({
        intent: "success",
        text: `Succesfully updated house "${houseData.house.houseName}"`,
      });
      actions.setSubmitting(false);
    });
  };

  if (error) return <p>Error fetching house</p>;

  const hostRows = hosts
    ? hosts.map(({ id, first_name, last_name, email, createdAt, plan_id }) => {
        const subscription = computeSubscription(plan_id);
        return {
          id: id,
          firstName: first_name,
          lastName: last_name,
          email,
          created_at: createdAt,
          plan: subscription
            ? `${subscription.name} (${subscription.cycle})`
            : null,
        };
      })
    : null;

  const handleUserDelete = (user, closeModal) => {
    deleteUser(user.id)
      .then(() => {
        addAlert({
          intent: "success",
          text: `Succesfully deleted user "${user.firstName} ${user.lastName}"`,
        });
        setHosts(hosts.filter(({ user_id }) => user_id !== user.id));
        closeModal();
      })
      .catch((error) => {
        console.warn(error);
        addAlert({
          intent: "error",
          text: `There was an error while deleting user "${user.firstName} ${user.lastName}". Please try again later.`,
        });
      });
  };

  return (
    <div className={classes.root}>
      <BackButton path={"/dashboard/homes"}>Back To Homes</BackButton>
      <DashboardPageHeader subHeader="Home">
        {loading ? "..." : house.houseName}
      </DashboardPageHeader>
      <TabbedSection
        tabs={[
          { label: "Info", index: 0 },
          { label: "Hosts", index: 1 },
        ]}
      >
        {!loading && (
          <HouseDataForm data={house} handleUpdate={handleUpdateHouseInfo} />
        )}
        {!loading && (
          <UsersTable users={hostRows} onDelete={handleUserDelete} />
        )}
      </TabbedSection>
    </div>
  );
}

HomesEdit.propTypes = {};

export default HomesEdit;
