import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { FormWrapper } from "components/FormWrapper";
import { InputField } from "components/InputField";
import { HCButton } from "components/HCButton";
import momentTZ from "moment-timezone";
import { SelectField } from "components/SelectField";
import { MenuItem, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  submit: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

function RegionEditForm({
  values = { name: "", description: "", timezone: "" },
  onSubmit,
}) {
  const classes = useStyles();

  const initialValues = {
    name: values.name || "",
    description: values.description || "",
    timezone: values.timezone || "",
  };

  const timeZonesList = useMemo(() => momentTZ.tz.zonesForCountry("US"), []);

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, isSubmitting, values }) => (
        <FormWrapper onSubmit={handleSubmit}>
          <InputField
            full
            id="name"
            name="name"
            variant="outlined"
            label="Name of region"
          />
          <InputField
            full
            id="description"
            name="description"
            variant="outlined"
            label="Description of region"
          />
          <SelectField
            full
            id="timezone"
            name="timezone"
            label="Region timezone"
          >
            {timeZonesList.map((tz) => (
              <MenuItem key={tz} value={tz}>
                {tz}
              </MenuItem>
            ))}
          </SelectField>
          <HCButton
            type="submit"
            variant="contained"
            color="primary"
            className={{ root: classes.submit }}
            loading={isSubmitting}
            disabled={JSON.stringify(initialValues) === JSON.stringify(values)}
          >
            Update Region
          </HCButton>
        </FormWrapper>
      )}
    </Formik>
  );
}

RegionEditForm.propTypes = {
  initialValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    timezone: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
};

export default RegionEditForm;
