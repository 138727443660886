import React from "react";
import PropTypes from "prop-types";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

function ViewToggler({ view, onChange }) {
  return (
    <ToggleButtonGroup value={view} exclusive onChange={onChange}>
      <ToggleButton value="detail">Detail</ToggleButton>
      <ToggleButton value="list">List</ToggleButton>
    </ToggleButtonGroup>
  );
}

ViewToggler.propTypes = {
  view: PropTypes.oneOf(["detail", "list"]),
  onChange: PropTypes.func.isRequired,
};

export default ViewToggler;
