import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { FormWrapper } from "components/FormWrapper";
import { InputField } from "components/InputField";
import * as Yup from "yup";
import PoiImageUploader from "../RegionPois/components/PoiImageUploader";
import { InputEditor } from "components/InputEditor";
import { AddressField } from "components/AddressField";
import PhoneField from "components/PhoneField";
import { URLField } from "components/URLField";
import { makeStyles } from "@material-ui/core";
import useAlertsDispatch from "hooks/useAlertsDispatch";
import { HCButton } from "components/HCButton";

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing(4)
  },
  submit: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  imageUploader: {
    marginBottom: theme.spacing(2)
  }
}));

const PoiDataFormValidationSchema = Yup.object().shape({
  image: Yup.mixed()
    .nullable()
    .required("You must upload an image for the POI.")
    .test(
      "is-valid-img",
      "This image does not look valid",
      value =>
        ("File" in window && value instanceof File) || typeof value === "string"
    ),
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
  address: Yup.object()
    .shape({
      formattedAddress: Yup.mixed(),
      lat: Yup.mixed(),
      lng: Yup.mixed()
    })
    .test(
      "is-valid-addr",
      "This address does not look valid.",
      value =>
        value.address_line_1 ||
        (value.lat && value.lng && value.formattedAddress)
    ),
  phone: Yup.string(),
  website: Yup.string().url("Invaid URL")
});

function PoiSuggestionsForm({
  onSubmit,
  withReservation,
  cta = "Save",
  poi = {}
}) {
  const classes = useStyles();
  const { addAlert } = useAlertsDispatch();

  const initialValues = {
    image: poi.image_url || null,
    name: poi.name || "",
    description: poi.description || "",
    address: {
      formattedAddress: poi.address_line_1,
      address_line_1: poi.address_line_1,
      city: poi.city,
      state: poi.state,
      country: poi.country,
      zip: poi.zip,
      lat: poi.lat,
      lng: poi.lng
    },
    reservations: poi.reservation_link || "",
    phone: poi.phone_number
      ? poi.phone_number.replace(/\D/g, "").replace(" ", "")
      : "",
    website: poi.website || ""
  };

  const handleImageError = error => {
    addAlert({ intent: "error", text: error });
  };

  return (
    <Formik
      onSubmit={onSubmit.bind(undefined, poi.id)}
      initialValues={initialValues}
      validationSchema={PoiDataFormValidationSchema}
    >
      {({ isSubmitting, values, handleSubmit }) => (
        <FormWrapper onSubmit={handleSubmit} className={classes.form}>
          <PoiImageUploader
            id="image"
            name="image"
            className={classes.imageUploader}
            onError={handleImageError}
          />
          <InputField
            full
            id="name"
            name="name"
            variant="outlined"
            label="Name"
          />
          <InputEditor
            name="description"
            id="description"
            variant="outlined"
            label="Description"
          />
          <AddressField name="address" placeholder="Address" required={true} />
          <PhoneField
            half
            type="tel"
            name="phone"
            id="phone"
            variant="outlined"
            label="Phone"
          />
          <URLField
            half
            name="website"
            id="website"
            variant="outlined"
            label="Website"
          />

          {withReservation && (
            <InputField
              full
              name="reservations"
              id="reservations"
              variant="outlined"
              label="Reservation URL"
            />
          )}
          <HCButton
            type="submit"
            variant="contained"
            color="secondary"
            className={{ root: classes.submit }}
            loading={isSubmitting}
          >
            {cta}
          </HCButton>
        </FormWrapper>
      )}
    </Formik>
  );
}

PoiSuggestionsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  withReservation: PropTypes.bool,
  cta: PropTypes.string,
  poi: PropTypes.shape({
    address_line_1: PropTypes.string,
    address_line_2: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    image_url: PropTypes.string,
    is_approved: PropTypes.number,
    is_default: PropTypes.number,
    name: PropTypes.string,
    sequence_no: PropTypes.number,
    state: PropTypes.string
  })
};

export default PoiSuggestionsForm;
