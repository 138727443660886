import React, { useState, useEffect } from "react";
import DashboardPageHeader from "../../../components/Dashboard/DashboardPageHeader";
import { Paper, makeStyles, Tabs, Tab } from "@material-ui/core";
import { UserDataForm } from "../../../components/UserDataForm";
import { HomesTable } from "../../../components/HomesTable";
import { UserSubscription } from "../../../components/UserSubscription";
import api from "../../../utilities/API";
import useAlertsDispatch from "../../../hooks/useAlertsDispatch";
import { BackButton } from "../../../components/BackButton";
import UserLinkCell from "components/UsersTable/UserLinkCell";
import RegionLinkCell from "components/UsersTable/RegionLinkCell";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
  root: {},
}));

function UsersEdit({ match }) {
  const classes = useStyles();

  const [tabPage, setTabPage] = useState(0);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { addAlert } = useAlertsDispatch();

  const handleChange = (event, newTab) => {
    setTabPage(newTab);
  };
  useEffect(() => {
    setLoading(true);
    const getUser = (id) => {
      api
        .getUser(id)
        .then(({ data }) => {
          setUserData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.warn(error);
          setLoading(false);
        });
    };

    getUser(match.params.id);
  }, [match.params.id]);

  const handleUserDataUpdate = (data) => {
    return (values, actions) => {
      api
        .updateUserData({ id: data.id, ...values })
        .then(() => {
          setUserData({
            ...userData,
            first_name: values.firstName,
            last_name: values.lastName,
            phone_number: values.phone,
            email: values.email,
            is_active: values.accountStatus === "active" ? 1 : 0,
          });
          addAlert({
            intent: "success",
            text: `Succesfully updated user "${values.firstName} ${values.lastName}"`,
          });
          actions.setSubmitting(false);
        })
        .catch((err) => {
          console.warn(err);
          addAlert({
            intent: "error",
            text: `There was an error while updating the user.`,
          });
          actions.setSubmitting(false);
        });
    };
  };

  const houses = userData
    ? userData.houses.map(({ id, region, name, created_at, user }) => ({
        id,
        region: (
          <RegionLinkCell
            name={region.name}
            id={region.id}
            to={`/dashboard/regions/${region.id}`}
          />
        ),

        propertyName: name,
        user: (
          <UserLinkCell
            firstName={user.first_name}
            lastName={user.last_name}
            id={user.id}
            to={`/dashboard/users/${user.id}/edit`}
          />
        ),
        createdAt: created_at,
      }))
    : null;
  return (
    <div className={classes.root}>
      <BackButton path={"/dashboard/users"}>Back To Users</BackButton>
      <DashboardPageHeader subHeader="User">
        {loading ? "..." : `${userData.first_name} ${userData.last_name}`}
      </DashboardPageHeader>
      <Tabs
        onChange={handleChange}
        value={tabPage}
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="Info" />
        <Tab label="Subscription" />
        <Tab label="Created Homes" />
      </Tabs>
      {!loading && (
        <Paper variant="outlined" className={classes.paper}>
          {tabPage === 0 && (
            <UserDataForm data={userData} handleUpdate={handleUserDataUpdate} />
          )}
          {tabPage === 1 && <UserSubscription data={userData} />}
          {tabPage === 2 && <HomesTable houses={houses} />}
        </Paper>
      )}
    </div>
  );
}

UsersEdit.propTypes = {};

export default UsersEdit;
