import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BackButton } from "components/BackButton";
import { DashboardPageHeader } from "components/Dashboard";
import useRegionState from "hooks/useRegionState";
import { SkeletonWrapper } from "components/SkeletonWrapper";
import RoutedTabbedSectionSkeleton from "components/TabbedSection/RoutedTabbedSectionSkeleton";
import { RoutedTabbedSection } from "components/TabbedSection";
import { Typography } from "@material-ui/core";
import useAlertsDispatch from "hooks/useAlertsDispatch";
import { PanelProvider } from "components/Panel";
import EditorForm from "../components/EditorForm";
import useRegionDispatch from "../../../hooks/useRegionDispatch";

function RegionDefaults({ match }) {
  const { region } = useRegionState();

  const { mergeRegion, getRegion } = useRegionDispatch();

  const { addAlert } = useAlertsDispatch();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [tabs, setTabs] = useState([]);

  useEffect(() => {
	if (! region.id) return;

	const defaultTabs = [
	  {
		"key": "checkout",
		"label": "Check Out Info",
		"content": ""
	  },
	  {
		"key": "covid",
		"label": "Covid19",
		"content": ""
	  }
	];

	const tabs = [];

	defaultTabs.forEach((val, idx) => {
	  const currentDefault = region.defaults ? region.defaults.find(regionDefault => regionDefault.key === val.key) : null;

	  tabs.push(
		{
		  key: val.key,
		  label: val.label,
		  content: currentDefault ? currentDefault.content : val.content,
		  path: `${match.url}/${val.key}`,
		  index: idx,
		}
	  );
	})

	setTabs(tabs);
	setLoading(false);

  }, [region, match]);

  if (error) {
	return (
	  <Typography variant="body1" color="textSecondary">
		There was an error getting the airport details for the region.
	  </Typography>
	);
  }

  const updateDefault = (actions, value, tab) => {
	let regionDefaults = region.defaults ? JSON.parse(JSON.stringify(region.defaults)) : [];

	let exists = false;

	regionDefaults.forEach(val => {
	  if (val.key === tab.key){
		exists = true;
		val.content = value;
	  }
	});

	if (! exists) {
	  regionDefaults.push({
		"key": tab.key,
		"label": tab.label,
		"content": value
	  });
	}

	mergeRegion(region.id, {defaults: regionDefaults})
	  .then((updatedRegion) => {
		addAlert({
		  intent: "success",
		  text: `Updated region "${updatedRegion.name}"`,
		});
		getRegion(region.id).then(() => {
		  actions.setSubmitting(false);
		});
	  })
	  .catch((error) => {
		setError(error);

		addAlert({
		  intent: "error",
		  text: error.message || "Unknown error while updating region.",
		});
	  });
  };

  return (
    <PanelProvider>
      {region && (
        <BackButton path={`/dashboard/regions/${region.id}`}>
          Back to {region.name}
        </BackButton>
      )}
      <DashboardPageHeader subHeader={region ? region.name : '...'}>
        Region Defaults
      </DashboardPageHeader>
	  <SkeletonWrapper loading={loading} skeleton={RoutedTabbedSectionSkeleton}>
		{tabs && (
		  <RoutedTabbedSection
			tabs={tabs}
		  >
			{tabs.map(tab => (
			  <EditorForm
				key={tab.index}
				value={tab.content}
				cta="Save"
				onSubmit={({ value }, actions)=> updateDefault(actions, value, tab)}
			  />
			))}
		  </RoutedTabbedSection>
		)}
	  </SkeletonWrapper>
    </PanelProvider>
  );
}

RegionDefaults.propTypes = {
  match: PropTypes.object.isRequired
};

export default RegionDefaults;
