import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { PanelHeader } from "components/Panel";
import BasicPoiForm from "./BasicPoiForm";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function BasicPoiNewPanel({ pois, onSubmit, categoryLabel }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PanelHeader
        header={`Create new ${categoryLabel}`}
        subHeader={categoryLabel}
      />
      <BasicPoiForm onSubmit={onSubmit} cta={"Create POI"} />
    </div>
  );
}

BasicPoiNewPanel.propTypes = {
  pois: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
};

export default BasicPoiNewPanel;
