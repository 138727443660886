import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import { makeStyles, Fade } from "@material-ui/core";
import useAlertsDispatch from "../../hooks/useAlertsDispatch";

const useStyles = makeStyles((theme) => ({
  alert: {
    maxWidth: "500px",
    width: "100%",
    marginBottom: theme.spacing(1),
  },
}));

function AlertItem({ id, intent, children, ...rest }) {
  const classes = useStyles();
  const { removeAlert } = useAlertsDispatch();
  useEffect(() => {
    if (id) {
      setTimeout(() => {
        removeAlert(id);
      }, 5000);
    }
  }, [id, removeAlert]);

  return (
    <Fade in={true} mountOnEnter unmountOnExit>
      <Alert className={classes.alert} severity={intent} {...rest}>
        {children}
      </Alert>
    </Fade>
  );
}

AlertItem.propTypes = {
  id: PropTypes.number,
  intent: PropTypes.oneOf(["error", "warning", "info", "success"]),
};

export default AlertItem;
