const SET_REGION_DATA = "SET_REGION_DATA";
const UPDATE_REGION_DATA = "UPDATE_REGION_DATA";

const SET_REGION_HOMES = "SET_REGION_HOMES";

const SET_REGION_HOSTS = "SET_REGION_HOSTS";

const SET_REGION_LINKS = "SET_REGION_LINKS";
const UPDATE_REGION_LINK = "UPDATE_REGION_LINK";

const SET_REGION_POIS = "SET_REGION_POIS";
const SET_POI_DEFAULT_STATE = "SET_POI_DEFAULT_STATE";
const SET_POI_SUB_DEFAULT_STATE = "SET_POI_SUB_DEFAULT_STATE";

const MOVE_POI_SEQUENCE = "MOVE_POI_SEQUENCE";
const MOVE_SUB_POI_SEQUENCE = "MOVE_SUB_POI_SEQUENCE";

const UPDATE_POI = "UPDATE_POI";
const ADD_POI = "ADD_POI";
const REMOVE_POI = "REMOVE_POI";

const SET_REGION_TV_OFFICE = "SET_REGION_TV_OFFICE";

const UPDATE_DESTINATIONS = "UPDATE_DESTINATIONS";
const REMOVE_DESTINATION = "REMOVE_DESTINATION";

const UPDATE_MULTI_IMAGE = "UPDATE_MULTI_IMAGE";

const SET_REGION_EMERGENCY = "SET_REGION_EMERGENCY";

const UPDATE_ADMIN_FEATURE = "UPDATE_ADMIN_FEATURE";
const REMOVE_ADMIN_FEATURE_PAGE = "REMOVE_ADMIN_FEATURE_PAGE";

const SET_REGION_GENERAL = "SET_REGION_GENERAL";
const UPDATE_REGION_GENERAL = "UPDATE_REGION_GENERAL";

const SET_REGION_AIRPORT_DETAILS = "SET_REGION_AIRPORT_DETAILS";

const MOVE_REGION_ITEM_SEQUENCE = "MOVE_REGION_ITEM_SEQUENCE";

const UPDATE_TERMINAL_MAP_URL = "UPDATE_TERMINAL_MAP_URL";

const UPDATE_RENTAL_MAP_URL = "UPDATE_RENTAL_MAP_URL";

const UPDATE_AIRPORT_WEBSITE = "UPDATE_AIRPORT_WEBSITE";

const UPDATE_BASIC_POI = "UPDATE_BASIC_POI";

const REMOVE_BASIC_POI = "REMOVE_BASIC_POI";

const ADD_BASIC_POI = "ADD_BASIC_POI";
const SET_REGION_GUEST_SERVICES = "SET_REGION_GUEST_SERVICES";

const UPDATE_PEOPLE_POI = "UPDATE_PEOPLE_POI";
const ADD_PEOPLE_POI = "ADD_PEOPLE_POI";
const REMOVE_PEOPLE_POI = "REMOVE_PEOPLE_POI";

const SET_REGION_SUGGESTIONS = "SET_REGION_SUGGESTIONS";

const SET_REGION_ARCHIVED = "SET_REGION_ARCHIVED";
const UPDATE_MORE_LINK = "UPDATE_MORE_LINK";

export {
  SET_REGION_DATA,
  UPDATE_REGION_DATA,
  SET_REGION_HOMES,
  SET_REGION_HOSTS,
  SET_REGION_LINKS,
  UPDATE_REGION_LINK,
  SET_REGION_POIS,
  SET_POI_DEFAULT_STATE,
  MOVE_POI_SEQUENCE,
  MOVE_SUB_POI_SEQUENCE,
  SET_POI_SUB_DEFAULT_STATE,
  UPDATE_POI,
  ADD_POI,
  REMOVE_POI,
  SET_REGION_TV_OFFICE,
  UPDATE_DESTINATIONS,
  REMOVE_DESTINATION,
  UPDATE_MULTI_IMAGE,
  SET_REGION_EMERGENCY,
  UPDATE_ADMIN_FEATURE,
  REMOVE_ADMIN_FEATURE_PAGE,
  SET_REGION_GENERAL,
  UPDATE_REGION_GENERAL,
  SET_REGION_AIRPORT_DETAILS,
  MOVE_REGION_ITEM_SEQUENCE,
  UPDATE_AIRPORT_WEBSITE,
  UPDATE_BASIC_POI,
  REMOVE_BASIC_POI,
  ADD_BASIC_POI,
  SET_REGION_GUEST_SERVICES,
  UPDATE_PEOPLE_POI,
  ADD_PEOPLE_POI,
  REMOVE_PEOPLE_POI,
  SET_REGION_SUGGESTIONS,
  SET_REGION_ARCHIVED,
  UPDATE_MORE_LINK,
  UPDATE_TERMINAL_MAP_URL,
  UPDATE_RENTAL_MAP_URL
};
