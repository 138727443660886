import { createReducer } from "@reduxjs/toolkit";

import {
  setAllUsers,
  setAllHouses,
  removeUser,
  setAllRegions,
  addRegion,
  removeRegion,
  setAllAdminUsers,
  updateAdminUser,
  removeAdminUser,
  addAdminUser,
  changeFeatureItemSequence,
  setFeatureOptions,
  updateFeatureOption,
  addFeatureOption,
  removeFeatureOption,
  changeHouseFeatureItemSequence,
  updateFeatureCategory,
  addHouseFeatureOption,
  updateHouseFeatureOption,
  removeHouseFeatureOption,
  setAllIcons,
  updateIcon,
  updateCategoryIcon,
  addUser,
  setDashboardData
} from "../actions/system";

const initialState = {
  users: [],
  regions: [],
  features: {},
  icons: []
};

const systemReducer = createReducer(initialState, {
  [setAllUsers]: (state, { payload }) => {
    return {
      ...state,
      users: payload
    };
  },
  [setAllHouses]: (state, { payload }) => {
    return {
      ...state,
      houses: payload
    };
  },
  [setAllAdminUsers]: (state, { payload }) => {
    return {
      ...state,
      adminUsers: payload
    };
  },
  [removeUser]: (state, { payload }) => {
    return {
      ...state,
      users: state.users.filter(({ id }) => id !== payload)
    };
  },
  [addUser]: (state, { payload }) => {
    const {
      id,
      created_at,
      firstName,
      lastName,
      email,
      accountType,
      phoneNumber,
      accountStatus,
      userType,
      plan_id
    } = payload;
    return {
      ...state,
      users: [
        {
          id,
          created_at,
          firstName,
          lastName,
          email,
          accountType,
          phoneNumber,
          accountStatus,
          userType,
          plan_id
        },
        ...state.users
      ]
    };
  },
  [setAllRegions]: (state, { payload }) => {
    return {
      ...state,
      regions: payload
    };
  },
  [addRegion]: (state, { payload }) => {
    return {
      ...state,
      regions: [payload, ...state.regions]
    };
  },
  [removeRegion]: (state, { payload }) => {
    return {
      ...state,
      regions: state.regions.filter(({ id }) => id !== payload)
    };
  },
  [updateAdminUser]: (state, { payload }) => {
    if (!state.adminUsers) return state;
    return {
      ...state,
      adminUsers: state.adminUsers.map(user =>
        user.id === payload.id
          ? {
              ...user,
              id: user.id,
              email: payload.email,
              firstName: payload.first_name,
              lastName: payload.last_name,
              accountType: payload.account_type,
              phoneNumber: payload.phone_number
            }
          : user
      )
    };
  },
  [removeAdminUser]: (state, { payload }) => {
    if (!state.adminUsers) return state;
    return {
      ...state,
      adminUsers: state.adminUsers.filter(({ id }) => id !== payload)
    };
  },
  [addAdminUser]: (
    state,
    { payload: { email, first_name, last_name, phone_number, id } }
  ) => {
    return {
      ...state,
      adminUsers: [
        {
          id,
          email,
          firstName: first_name,
          lastName: last_name,
          phoneNumber: phone_number
        },
        ...state.adminUsers
      ]
    };
  },

  [changeHouseFeatureItemSequence]: (state, { payload }) => {
    const { id: itemId, sequence: newSequence, category } = payload;

    const draggedItem = state.features.houseFeatures.values[
      category
    ].options.find(option => option.id === itemId);
    if (draggedItem.sequence === newSequence) return state;
    if (!draggedItem) return state;

    return {
      ...state,
      features: {
        ...state.features,
        houseFeatures: {
          ...state.features.houseFeatures,
          values: {
            ...state.features.houseFeatures.values,
            [category]: {
              ...state.features.houseFeatures.values[category],
              options: state.features.houseFeatures.values[
                category
              ].options.map(option => {
                const { sequence, id } = option;
                // if the option id is the same as the id being set,
                // change its sequence to the new sequence
                if (id === itemId) {
                  return {
                    ...option,
                    sequence: newSequence
                  };
                }

                // if the current sequence is the same as the items new sequence
                if (sequence === newSequence) {
                  //if the original dragged item was ahead of the new position,
                  // shift the item +1
                  if (draggedItem.sequence > newSequence) {
                    return {
                      ...option,
                      sequence: sequence + 1
                    };
                  }
                  //if the original dragged item was below the new position,
                  // shift the item -1
                  return {
                    ...option,
                    sequence: sequence - 1
                  };
                }

                if (sequence > newSequence) {
                  if (draggedItem.sequence < sequence) {
                    return option;
                  } else {
                    return {
                      ...option,
                      sequence: sequence + 1
                    };
                  }
                } else {
                  //if the original dragged item was below the new position,
                  // shift the item -1
                  if (draggedItem.sequence < sequence) {
                    return {
                      ...option,
                      sequence: sequence - 1
                    };
                  } else {
                    return option;
                  }
                }
              })
            }
          }
        }
      }
    };
  },
  [changeFeatureItemSequence]: (state, { payload }) => {
    const { id: itemId, sequence: newSequence, category } = payload;

    const draggedItem = state.features[category].options.find(
      option => option.id === itemId
    );
    if (draggedItem.sequence === newSequence) return state;
    if (!draggedItem) return state;

    return {
      ...state,
      features: {
        ...state.features,
        [category]: {
          ...state.features[category],
          options: state.features[category].options.map(option => {
            const { sequence, id } = option;
            // if the option id is the same as the id being set,
            // change its sequence to the new sequence
            if (id === itemId) {
              return {
                ...option,
                sequence: newSequence
              };
            }

            // if the current sequence is the same as the items new sequence
            if (sequence === newSequence) {
              //if the original dragged item was ahead of the new position,
              // shift the item +1
              if (draggedItem.sequence > newSequence) {
                return {
                  ...option,
                  sequence: sequence + 1
                };
              }
              //if the original dragged item was below the new position,
              // shift the item -1
              return {
                ...option,
                sequence: sequence - 1
              };
            }

            if (sequence > newSequence) {
              if (draggedItem.sequence < sequence) {
                return option;
              } else {
                return {
                  ...option,
                  sequence: sequence + 1
                };
              }
            } else {
              //if the original dragged item was below the new position,
              // shift the item -1
              if (draggedItem.sequence < sequence) {
                return {
                  ...option,
                  sequence: sequence - 1
                };
              } else {
                return option;
              }
            }
          })
        }
      }
    };
  },
  [updateFeatureCategory]: (state, { payload }) => {
    const { data, category } = payload;

    return {
      ...state,
      features: {
        ...state.features,
        houseFeatures: {
          ...state.features.houseFeatures,
          values: {
            ...state.features.houseFeatures.values,
            [category]: {
              ...state.features.houseFeatures.values[category],
              title: data.title,
              image_url: data.image_url,
              description: data.description
            }
          }
        }
      }
    };
  },
  [setFeatureOptions]: (state, { payload }) => {
    return {
      ...state,
      features: Object.keys(payload).reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: payload[curr]
        }),
        {}
      )
    };
  },
  [updateFeatureOption]: (state, { payload }) => {
    const { data, category, id } = payload;

    return {
      ...state,
      features: {
        ...state.features,
        [category]: {
          ...state.features[category],
          options: state.features[category].options.map(option =>
            option.id === id
              ? {
                  id,
                  ...data
                }
              : option
          )
        }
      }
    };
  },
  [addFeatureOption]: (state, { payload }) => {
    const { category, option } = payload;

    return {
      ...state,
      features: {
        ...state.features,
        [category]: {
          ...state.features[category],
          options: [...state.features[category].options, option]
        }
      }
    };
  },
  [addHouseFeatureOption]: (state, { payload }) => {
    const { category, option } = payload;

    return {
      ...state,
      features: {
        ...state.features,
        houseFeatures: {
          ...state.features.houseFeatures,
          values: {
            ...state.features.houseFeatures.values,
            [category]: {
              ...state.features.houseFeatures.values[category],
              options: [
                ...state.features.houseFeatures.values[category].options,
                {
                  id: option.id,
                  name: option.title,
                  key: option.key,
                  image_url: option.image_url,
                  description: option.description,
                  default_text: option.default_text,
                  sequence: option.sequence
                }
              ]
            }
          }
        }
      }
    };
  },
  [removeHouseFeatureOption]: (state, { payload }) => {
    const { category, id } = payload;
    const deletedOption = state.features.houseFeatures.values[
      category
    ].options.find(option => id === option.id);

    return {
      ...state,
      features: {
        ...state.features,
        houseFeatures: {
          ...state.features.houseFeatures,
          values: {
            ...state.features.houseFeatures.values,
            [category]: {
              ...state.features.houseFeatures.values[category],
              options: state.features.houseFeatures.values[category].options
                .filter(option => option.id !== id)
                .map(option => ({
                  ...option,
                  sequence:
                    option.sequence > deletedOption.sequence
                      ? option.sequence - 1
                      : option.sequence
                }))
            }
          }
        }
      }
    };
  },
  [removeFeatureOption]: (state, { payload }) => {
    const { category, id } = payload;

    const deletedOption = state.features[category].options.find(
      option => id === option.id
    );

    return {
      ...state,
      features: {
        ...state.features,
        [category]: {
          ...state.features[category],
          options: state.features[category].options
            .filter(option => option.id !== id)
            .map(option => ({
              ...option,
              sequence:
                option.sequence > deletedOption.sequence
                  ? option.sequence - 1
                  : option.sequence
            }))
        }
      }
    };
  },
  [updateHouseFeatureOption]: (state, { payload }) => {
    const { category, id, data } = payload;

    return {
      ...state,
      features: {
        ...state.features,
        houseFeatures: {
          ...state.features.houseFeatures,
          values: {
            ...state.features.houseFeatures.values,
            [category]: {
              ...state.features.houseFeatures.values[category],
              options: state.features.houseFeatures.values[
                category
              ].options.map(option =>
                option.id === id
                  ? {
                      ...option,
                      id,
                      name: data.title,
                      key: data.key,
                      image_url: data.image_url,
                      description: data.description,
                      default_text: data.default_text,
                      sequence: data.sequence
                    }
                  : option
              )
            }
          }
        }
      }
    };
  },
  [setAllIcons]: (state, { payload }) => {
    return {
      ...state,
      icons: payload
    };
  },
  [updateIcon]: (state, { payload }) => {
    const { categoryId, id, icon: iconImage } = payload;
    return {
      ...state,
      icons: state.icons.map(category =>
        category.id === categoryId
          ? {
              ...category,
              icons: category.icons.map(icon =>
                icon.id === id
                  ? {
                      ...icon,
                      image_url: iconImage
                    }
                  : icon
              )
            }
          : category
      )
    };
  },
  [updateCategoryIcon]: (state, { payload }) => {
    const { categoryId, icon } = payload;
    return {
      ...state,
      icons: state.icons.map(category =>
        category.id === categoryId
          ? {
              ...category,
              image_url: icon
            }
          : category
      )
    };
  },
  [setDashboardData]: (state, { payload }) => {
    const { account_owners_count, homes_count, pois_count } = payload;
    return {
      ...state,
      dashboard: {
        account_owners_count,
        homes_count,
        pois_count
      }
    };
  }
});

export { initialState };
export default systemReducer;
