import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { FormWrapper } from "components/FormWrapper";
import { InputEditor } from "components/InputEditor";
import { HCButton } from "components/HCButton";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

function EditorForm({ value = "", cta = "Submit", onSubmit }) {
  const initialValues = {
    value,
  };

  const classes = useStyles();

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting, values }) => (
        <FormWrapper onSubmit={handleSubmit}>
          <InputEditor full name="value" id="value" />
          <HCButton
            type="submit"
            color="primary"
            variant="contained"
            loading={isSubmitting}
            disabled={values.value === initialValues.value}
            className={{ root: classes.submit }}
          >
            {cta}
          </HCButton>
        </FormWrapper>
      )}
    </Formik>
  );
}

EditorForm.propTypes = {
  value: PropTypes.string,
  cta: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default EditorForm;
