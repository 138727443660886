import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core";

import { Formik } from "formik";
import * as Yup from "yup";

import { FormWrapper } from "components/FormWrapper";
import { InputField } from "components/InputField";
import { HCButton } from "components/HCButton";
import PhoneField from "components/PhoneField";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
}));

const AdminUsersNewFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("You must enter a valid email.")
    .required("Email is required"),
  firstName: Yup.string().required("You must enter a first name."),
  lastName: Yup.string().required("You must enter a last name."),
  phoneNumber: Yup.string(),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords do not match.")
    .required("You must confirm your password"),
});

function AdminUsersNewForm({ onSubmit }) {
  const classes = useStyles();
  const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={AdminUsersNewFormValidationSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <FormWrapper onSubmit={handleSubmit}>
          <InputField
            full
            name="email"
            id="emai"
            variant="outlined"
            label="Email"
          />
          <InputField
            half
            name="firstName"
            id="firstName"
            variant="outlined"
            label="First Name"
          />
          <InputField
            half
            name="lastName"
            id="lastName"
            variant="outlined"
            label="Last Name"
          />
          <PhoneField
            full
            type="tel"
            name="phoneNumber"
            id="phoneNumber"
            variant="outlined"
            label="Phone Number"
          />
          <InputField
            full
            type="password"
            name="password"
            id="password"
            variant="outlined"
            label="Password"
          />

          <InputField
            full
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            variant="outlined"
            label="Confirm Password"
          />
          <HCButton
            loading={isSubmitting}
            variant="contained"
            type="submit"
            color="primary"
            className={{ root: classes.submit }}
            fullWidth
          >
            Create Admin User
          </HCButton>
        </FormWrapper>
      )}
    </Formik>
  );
}

AdminUsersNewForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default AdminUsersNewForm;
