import React from "react";
import { makeStyles, Paper, Typography, SvgIcon } from "@material-ui/core";
import LoginForm from "./LoginForm";
import { Redirect } from "react-router-dom";
import useUserDispatch from "../../hooks/useUserDispatch";
import useUserState from "../../hooks/useUserState";
import { ReactComponent as HCIcon } from "../../assets/HC_Icon.svg";
import useAlertsDispatch from "../../hooks/useAlertsDispatch";
import { GeneralErrorBoundary } from "components/ErrorBoundary";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    maxWidth: "400px",
    padding: theme.spacing(4),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  icon: {
    width: "100px",
    height: "auto",
    display: "block",
    margin: "0 auto",
  },
  header: {
    marginBottom: theme.spacing(2),
  },
}));

function Login() {
  const classes = useStyles();
  const { loginUser } = useUserDispatch();
  const { isLoggedIn } = useUserState();
  const { addAlert } = useAlertsDispatch();
  const handleLogin = ({ email, password }) => {
    loginUser(email, password)
      .then(() => {
        addAlert({ intent: "success", text: "Succesfully logged in." });
      })
      .catch((err) => {
        addAlert({ intent: "error", text: err.message });
      });
  };

  if (isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <GeneralErrorBoundary message="An unknown error has occurred.">
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={3}>
          <div className={classes.header}>
            <SvgIcon className={classes.icon}>
              <HCIcon />
            </SvgIcon>
            <Typography variant="h6" align="center">
              Home Concierge Super Admin
            </Typography>
          </div>
          <LoginForm onLogin={handleLogin} />
        </Paper>
      </div>
    </GeneralErrorBoundary>
  );
}

export default Login;
