import React, { forwardRef } from "react";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between"
  }
});

const SplashItemSkeleton = props => (
  <Skeleton
    animation="wave"
    variant="rect"
    width={"calc(30% + 16px)"}
    height={"128px"}
    {...props}
  />
);

const SplashSkeleton = forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <div ref={ref} {...props}>
      <div className={classes.root}>
        <SplashItemSkeleton />
        <SplashItemSkeleton />
        <SplashItemSkeleton />
      </div>
    </div>
  );
});

SplashSkeleton.propTypes = {};

export default SplashSkeleton;
