import React, { useState } from "react";
import PropTypes from "prop-types";
import { DraggableTable } from "components/DraggableTable";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useRouteMatch, useHistory, Link } from "react-router-dom";
import { RoutedPanel } from "components/Panel";
import { makeStyles } from "@material-ui/core";
import BasicPoiEditPanel from "./BasicPoiEditPanel";
import useRegionDispatch from "hooks/useRegionDispatch";
import useRegionState from "hooks/useRegionState";
import useAlertsDispatch from "hooks/useAlertsDispatch";
import { HCButton } from "components/HCButton";
import BasicPoiNewPanel from "./BasicPoiNewPanel";
import { HCModal } from "components/HCModal";
import PoiDeleteModal from "../RegionPois/components/PoiDeleteModal";

const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
}));

function BasicPoiTable({
  categoryLabel,
  pois,
  onDrop,
  onMove,
  stateKey,
  category,
}) {
  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();
  const { region } = useRegionState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [queuedDeleteData, setQueuedDeleteData] = useState();

  const {
    updateBasicPoi,
    createBasicPoi,
    deleteBasicPoi,
  } = useRegionDispatch();
  const { addAlert } = useAlertsDispatch();

  // draggable table expects `sequence` not `sequence_no`
  const items = pois.items.map(({ sequence_no, ...item }) => ({
    ...item,
    sequence: sequence_no,
  }));

  const featureId = pois.id;

  const optionsFormatted = items
    .map((option) => ({
      ...option,
      category: categoryLabel,
    }))
    .sort((a, b) => (a.sequence > b.sequence ? 1 : -1));

  const closePanel = () => {
    history.push({ pathname: match.url });
  };

  const handlePoiUpdate = (poiId, values, actions) => {
    updateBasicPoi(region.id, stateKey, category, poiId, values)
      .then(() => {
        addAlert({
          intent: "success",
          text: "Succesfully updated POI.",
        });
        actions.setSubmitting(false);
        closePanel();
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          intent: "error",
          text: "There was an error while updating the poi.",
        });
        actions.setSubmitting(false);
      });
  };

  const handlePoiNew = (values, actions) => {
    createBasicPoi(region.id, stateKey, category, featureId, values)
      .then(() => {
        addAlert({
          intent: "success",
          text: "Succesfully created POI.",
        });
        actions.setSubmitting(false);
        closePanel();
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          intent: "error",
          text: "There was an error while creating the poi.",
        });
        actions.setSubmitting(false);
      });
  };

  const handleDeleteClick = (evt, data) => {
    setQueuedDeleteData(null);
    setQueuedDeleteData(data);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = (data, actions) => {
    deleteBasicPoi(region.id, stateKey, category, data.id)
      .then(() => {
        actions.setSubmitting(false);
        setShowDeleteModal(false);
        setQueuedDeleteData();
        addAlert({
          intent: "success",
          text: `Succesfully deleted POI "${data.name}" (ID #${data.id}).`,
        });
      })
      .catch((err) => {
        console.error(err);
        actions.setSubmitting(false);
        addAlert({
          intent: "error",
          text:
            err.response?.data?.message ||
            "There was an unknown error while deleting POI.",
        });
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.actions}>
        <HCButton
          variant="contained"
          color="primary"
          component={Link}
          to={`${match.url}/new`}
          className={classes.newBtn}
        >
          Create New {categoryLabel} POI
        </HCButton>
      </div>
      <DraggableTable
        onDrop={onDrop}
        onMove={onMove}
        headers={[
          {
            label: "ID",
            dataKey: "id",
          },

          {
            label: "Name",
            dataKey: "name",
          },

          {
            label: "Website",
            dataKey: "website",
          },
        ]}
        rows={optionsFormatted}
        actions={[
          { Icon: EditIcon, link: ({ id }) => `${match.url}/${id}` },
          { Icon: DeleteIcon, onClick: handleDeleteClick },
        ]}
      />
      <RoutedPanel path={`${match.url}/new`} onClose={closePanel}>
        <BasicPoiNewPanel
          onSubmit={handlePoiNew}
          categoryLabel={categoryLabel}
          pois={items}
        />
      </RoutedPanel>

      <RoutedPanel path={`${match.url}/:id(\\d+)`} onClose={closePanel}>
        <BasicPoiEditPanel
          onSubmit={handlePoiUpdate}
          categoryLabel={categoryLabel}
          pois={items}
        />
      </RoutedPanel>

      <HCModal
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
      >
        <PoiDeleteModal
          data={queuedDeleteData}
          onClose={() => setShowDeleteModal(false)}
          onSubmit={handleDeleteConfirm}
        />
      </HCModal>
    </div>
  );
}

BasicPoiTable.propTypes = {
  categoryLabel: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      website: PropTypes.string,
      sequence: PropTypes.number,
    })
  ),
  onDrop: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
};

export default BasicPoiTable;
