const getPlusCode = async ({ location }) => {
  const response = await fetch(
    `https://plus.codes/api?address=${location.lat},${location.lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
  );

  const data = await response.json();

  return data.plus_code;
};

export default getPlusCode;
