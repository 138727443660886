import React from "react";
import { Typography, Divider, makeStyles, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    marginBottom: theme.spacing(4),
  },
  pageHeaderTitle: {},
  pageHeaderContent: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  pageHeaderText: {},
}));

function DashboardPageHeader({ children, cta, subHeader }) {
  const classes = useStyles();
  return (
    <div className={classes.pageHeader}>
      <div className={classes.pageHeaderContent}>
        <div className={classes.pageHeaderText}>
          {subHeader && (
            <Typography variant="body1" color="textSecondary">
              {subHeader}
            </Typography>
          )}
          <Typography variant="h3" className={classes.pageHeaderTitle}>
            {children}
          </Typography>
        </div>
        {cta && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            component={Link}
            to={cta.path}
          >
            {cta.text}
          </Button>
        )}
      </div>
      <Divider />
    </div>
  );
}

DashboardPageHeader.propTypes = {};

export default DashboardPageHeader;
