import React, { useEffect } from "react";
import "./App.css";
import { DashboardWrapper } from "./components/Dashboard";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import dashboardRoutes from "./components/Dashboard/dashboardRoutes";
import { RouteGenerator } from "./components/RouteGenerator";
import { Login } from "./components/Login";

import useUserDispatch from "./hooks/useUserDispatch";
import useLoadingState from "./hooks/useLoadingState";
import Alerts from "./components/Alerts/Alerts";
import { GeneralErrorBoundary } from "components/ErrorBoundary";

function Dashboard({ match }) {
  return (
    <DashboardWrapper>
      <GeneralErrorBoundary message="An unknown error has occurred!">
        <RouteGenerator routes={dashboardRoutes} />
      </GeneralErrorBoundary>
    </DashboardWrapper>
  );
}

function App() {
  const { checkAuth } = useUserDispatch();
  const { storage } = useLoadingState();

  // checkAuth on mount
  useEffect(() => {
    checkAuth().catch(console.warn);
  }, [checkAuth]);

  // if storage is loading, don't display page until finished
  if (storage.loading) return null;

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/login" component={Login} />
        <Route render={() => <Redirect to="/login" />} />
      </Switch>
      <Alerts />
    </BrowserRouter>
  );
}

export default App;
