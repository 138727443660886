import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {},
  paper: {
    padding: theme.spacing(4)
  }
}));

function TabbedSection({ tabs, children }) {
  const classes = useStyles();
  const [tabPage, setTabPage] = useState(0);

  const handleTabChange = (_, newTab) => {
    setTabPage(newTab);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={tabPage}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
      >
        {tabs.map(({ label, icon }, i) => (
          <Tab key={i} label={label} icon={icon} />
        ))}
      </Tabs>
      <Paper variant="outlined" className={classes.paper}>
        {Array.isArray(children) ? children[tabPage] : children}
      </Paper>
    </div>
  );
}

TabbedSection.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string
    })
  )
};

export default TabbedSection;
