import React, { useMemo } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import useUserState from "../../hooks/useUserState";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useUserState();

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

function RouteGenerator({ routes }) {
  const memoizedRoutes = useMemo(() => routes, [routes]);
  return (
    <Switch>
      {memoizedRoutes.map(route =>
        route.isPrivate ? (
          <PrivateRoute
            key={route.key}
            path={`${route.path}`}
            component={route.component}
            exact={route.exact || false}
          />
        ) : (
          <Route
            key={route.key}
            path={`${route.path}`}
            render={route.component}
            exact={route.exact || false}
          />
        )
      )}
    </Switch>
  );
}

RouteGenerator.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      exact: PropTypes.bool,
      isPrivate: PropTypes.bool
    })
  )
};

export default RouteGenerator;
