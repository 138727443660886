import React from "react";
import PropTypes from "prop-types";
import { PanelHeader } from "components/Panel";
import { useRouteMatch } from "react-router-dom";
import PoiDataForm from "./PoiDataForm";

function PoiEditPanel({ onUpdate, pois, parentCategory }) {
  const match = useRouteMatch();
  const poiId = Number(match.params.id);

  const poi = poiId ? pois.items.find(({ id }) => id === poiId) : null;

  const hasReservation =
    parentCategory === "dine" ||
    parentCategory === "play" ||
    parentCategory === "spa"
      ? true
      : false;

  const hasMenu = parentCategory === "dine" || parentCategory === "spa";

  const hasDirectory = parentCategory === "shop";

  if (!poi) return null;

  return (
    <div>
      <PanelHeader header={poi.name} subHeader={pois.name} icon={pois.icon} />
      <PoiDataForm
        poi={poi}
        cta="Update POI"
        onSubmit={onUpdate}
        withReservation={hasReservation}
        withMenu={hasMenu}
        withDirectory={hasDirectory}
      />
    </div>
  );
}

PoiEditPanel.propTypes = {
  pois: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    icon: PropTypes.string,
    description: PropTypes.string,
    key: PropTypes.string,
    url: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        address_line_1: PropTypes.string,
        address_line_2: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.number,
        image_url: PropTypes.string,
        is_approved: PropTypes.number,
        is_default: PropTypes.number,
        name: PropTypes.string,
        sequence_no: PropTypes.number,
        state: PropTypes.string
      })
    )
  })
};

export default PoiEditPanel;
