import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core";

import { useRouteMatch } from "react-router-dom";

import FeatureEditForm from "./FeatureEditForm";

import { PanelHeader } from "components/Panel";

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {
    marginTop: theme.spacing(2),
  },
}));

function FeatureEdit({ categoryLabel, options, onUpdate }) {
  const classes = useStyles();
  const match = useRouteMatch();
  const featureId = Number(match.params.id);
  const featureData = options.find(({ id }) => id === featureId);

  if (!featureData) return <p>NO FEATURE DATA</p>;
  return (
    <div className={classes.root}>
      <PanelHeader
        header={featureData.name}
        subHeader={categoryLabel}
        icon={featureData.image_url}
      />
      <FeatureEditForm
        name={featureData.name}
        description={featureData.description}
        defaultText={featureData.default_text}
        icon={featureData.image_url}
        isActive={featureData.is_active}
        onSubmit={(values, actions) => onUpdate(values, actions, featureData)}
        className={classes.form}
      />
    </div>
  );
}

FeatureEdit.propTypes = {
  categoryLabel: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      image_url: PropTypes.string.isRequired,
      description: PropTypes.string,
      default_text: PropTypes.string,
      is_active: PropTypes.number,
    })
  ).isRequired,
};

export default FeatureEdit;
