import React, { Component } from "react";
import PropTypes from "prop-types";
import GeneralErrorComponent from "./GeneralErrorComponent";

export class GeneralErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.any,
    message: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
    console.error(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <GeneralErrorComponent message={this.props.message} />;
    }

    return this.props.children;
  }
}

export default GeneralErrorBoundary;
