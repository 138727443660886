import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "768px"
  }
}));

function FormWrapper({ children, className, ...props }) {
  const classes = useStyles();
  return (
    <form className={clsx(classes.form, className && className)} {...props}>
      {children}
    </form>
  );
}

FormWrapper.propTypes = {};

export default FormWrapper;
