import React from "react";
import PropTypes from "prop-types";

import { makeStyles, MenuItem } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";

import { InputField } from "../../../components/InputField";
import { SelectField } from "../../../components/SelectField";
import { HCButton } from "components/HCButton";

const UsersGiftNewFormSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Email must be valid")
    .required("Email is required"),
  phoneNumber: Yup.string().required("Phone number is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string().required("You must confirm your password"),
  duration: Yup.string().required(
    "You must choose a duration for the complimentary subscription plan."
  ),
});

const useStyles = makeStyles((theme) => ({
  giftFormPaper: {
    padding: theme.spacing(4),
  },
  giftForm: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "768px",
  },
  giftFormSubmit: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

function UsersGiftNewForm({ onSubmit }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        password: "",
        confirmPassword: "",
        duration: "",
      }}
      onSubmit={onSubmit}
      validationSchema={UsersGiftNewFormSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className={classes.giftForm}>
          <InputField
            half
            name="firstName"
            id="firstName"
            variant="outlined"
            label="First Name"
          />
          <InputField
            half
            name="lastName"
            id="lastName"
            variant="outlined"
            label="Last Name"
          />
          <InputField
            half
            name="email"
            id="email"
            variant="outlined"
            label="Email"
          />
          <InputField
            half
            name="phoneNumber"
            id="phoneNumber"
            variant="outlined"
            label="Phone Number"
          />
          <InputField
            half
            name="password"
            id="password"
            variant="outlined"
            label="Password"
            type="password"
          />
          <InputField
            half
            name="confirmPassword"
            id="confirmPassword"
            variant="outlined"
            label="Confirm password"
            type="password"
          />
          <SelectField
            full
            id="duration"
            name="duration"
            label="Subscription Duration"
          >
            <MenuItem value={"indefinite"}>Indefinite</MenuItem>
            <MenuItem value={"1_mo"}>1 Month</MenuItem>
            <MenuItem value={"3_mos"}>3 Months</MenuItem>
            <MenuItem value={"6_mos"}>6 Months</MenuItem>
            <MenuItem value={"12_mos"}>1 Year</MenuItem>
          </SelectField>
          <HCButton
            loading={isSubmitting}
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginRight: "10px" }}
          >
            Create Gift Account
          </HCButton>
        </form>
      )}
    </Formik>
  );
}

UsersGiftNewForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default UsersGiftNewForm;
