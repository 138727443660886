import { createReducer } from "@reduxjs/toolkit";
import { loginUser, logoutUser } from "../actions/user";
const initialState = {};

const userReducer = createReducer(initialState, {
  [loginUser]: (
    state,
    { payload: { token, email, first_name, last_name } }
  ) => {
    return {
      ...state,
      isLoggedIn: Boolean(token && email),
      token,
      email,
      first_name,
      last_name,
    };
  },
  [logoutUser]: (state, action) => {
    return {
      isLoggedIn: false,
    };
  },
});

export { initialState };

export default userReducer;
