import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles, TextField } from "@material-ui/core";
import { useField } from "formik";
import scriptLoader from "react-async-script-loader";
// import useGoogleAutocompletePredictions from "../../hooks/useGoogleAutocompletePredictions";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getDetails
} from "use-places-autocomplete";
import { URLIcon } from "components/URLIcon";
import MapIcon from "@material-ui/icons/Map";
import EmbedMap from "components/EmbedMap/EmbedMap";
import getPlusCode from "utilities/getPlusCode";
import { HCButton } from "components/HCButton";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

const useStyles = makeStyles(theme => ({
  root: props => ({
    width: "auto",
    flexBasis: props.half ? `calc(50% - ${theme.spacing(1) * 2}px)` : "100%",
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2)
  }),
  openContainer: {}
}));

const getAddressComponents = address_components => {
  return address_components.reduce((acc, curr) => {
    const types = curr.types;
    if (types.includes("administrative_area_level_1")) {
      acc.state = curr.short_name;
    } else if (types.includes("locality")) {
      acc.city = curr.long_name;
    } else if (types.includes("postal_code")) {
      acc.zip = curr.long_name;
    } else if (types.includes("country")) {
      acc.country = curr.long_name;
    } else if (types.includes("route")) {
      acc.street = curr.long_name;
    } else if (types.includes("plus_code")) {
      acc.plus_code = curr.long_name;
    } else if (types.includes("street_number")) {
      acc.street_number = curr.long_name;
    } else if (types.includes("subpremise")) {
      acc.subpremise = curr.long_name;
    }

    return acc;
  }, {});
};

function AddressField({
  name,
  required = false,
  placeholder,
  isScriptLoadSucceed
}) {
  const [field, meta, helpers] = useField({ name });
  const classes = useStyles();
  const { suggestions, setValue } = usePlacesAutocomplete({
    callbackName: "initMap"
  });
  const [geoLoading, setGeoLoading] = useState(false);
  const [mapOpen, setMapOpen] = useState(false);
  const markersRef = useRef([]);

  // change handler for input
  const handleInputChange = evt => {
    const val = evt.target.value;
    setValue(val);
    if (!val) {
      helpers.setValue({ formattedAddress: "" });
    }
  };

  // change handler for autocomplete select
  const handleAutoChange = async (evt, value) => {
    helpers.setValue({ formattedAddress: value });
    if (!value) return;
    setGeoLoading(true);
    // get the selected suggestion
    const suggestion = suggestions.data.find(
      suggestion => suggestion.description === value
    );
    // get geocode results
    const results = await getGeocode({ address: value });
    // get latlng
    const coords = await getLatLng(results[0]);

    // get details for location
    const details = await getDetails({
      placeId: suggestion.place_id
    });
    // if details has no address_components, set value as empty
    // and return
    if (!details.address_components) {
      setValue(null);
      helpers.setValue({ formattedAddress: "" });
      return;
    }

    // get individual address components
    const {
      city,
      state,
      zip,
      country,
      street,
      plus_code,
      street_number,
      subpremise
    } = getAddressComponents(details.address_components);

    helpers.setValue({
      formattedAddress: value,
      address_line_1: plus_code
        ? plus_code
        : `${street_number ? street_number : ""}${street ? " " + street : ""}${
            subpremise ? " " + subpremise : ""
          }`,
      city,
      state,
      street,
      zip,
      country,
      place_id: details.place_id,
      title: details.name,
      lat: coords.lat,
      lng: coords.lng
    });

    setGeoLoading(false);
  };

  const setMarkersMap = map => {
    markersRef.current.forEach(marker => {
      marker.setMap(map);
    });
  };

  const handleMapClick = async ({ latLng }, map, google) => {
    setMarkersMap(null);

    const geo = await getGeocode({ location: latLng });

    const exactGeos = geo.filter(result => {
      if (result.geometry.location_type === "ROOFTOP") {
        return true;
      }

      return false;
    });

    const result = geo[0];
    const exactResult = exactGeos[0];

    const plusCode = await getPlusCode({
      location: {
        lat: latLng.lat(),
        lng: latLng.lng()
      }
    });

    const closeComponents = result
      ? getAddressComponents(result.address_components)
      : null;
    const exactComponents = exactResult
      ? getAddressComponents(exactResult.address_components)
      : null;

    const addrComponents = exactComponents ? exactComponents : closeComponents;

    if (!addrComponents) {
      helpers.setValue({
        formattedAddress: ""
      });
      return;
    }

    const formattedAddress = exactComponents
      ? exactResult.formatted_address
      : plusCode.local_code
      ? `${plusCode.local_code}, ${plusCode.locality.local_address}`
      : plusCode.global_code;

    const address_line_1 = addrComponents.plus_code
      ? addrComponents.plus_code
      : exactComponents
      ? `${exactComponents.street_number ? exactComponents.street_number : ""}${
          exactComponents.street ? " " + exactComponents.street : ""
        }${exactComponents.subpremise ? " " + exactComponents.subpremise : ""}`
      : plusCode.local_code | plusCode.global_code;

    helpers.setValue({
      formattedAddress: formattedAddress,
      address_line_1: address_line_1,
      city: addrComponents.city,
      state: addrComponents.state,
      street: addrComponents.street,
      zip: addrComponents.zip,
      country: addrComponents.country,
      place_id: addrComponents.place_id,
      title: exactResult ? exactResult.formatted_address : formattedAddress,
      lat:
        exactGeos.length > 0
          ? exactResult.geometry.location.lat()
          : plusCode.geometry.location.lat,
      lng:
        exactGeos.length > 0
          ? exactResult.geometry.location.lng()
          : plusCode.geometry.location.lng
    });
  };

  // fired when EmbedMap gets a different value passed
  const handleValueChange = useCallback((value, map, google) => {
    if (!map) return;
    if (!google) return;
    if (!value.lat && !value.lng) return;
    setMarkersMap(null);

    const latLng = {
      lat: Number(value.lat),
      lng: Number(value.lng)
    };

    const marker = new google.maps.Marker({
      map,
      position: latLng
    });

    map.panTo(latLng);

    markersRef.current.push(marker);
  }, []);

  const handleMapClickError = error => {
    console.warn(error);
  };

  const displayError = meta.touched && meta.error && !geoLoading;

  return (
    <div className={classes.root}>
      <Autocomplete
        freeSolo
        className={classes.input}
        filterOptions={options => options}
        onChange={handleAutoChange}
        clearOnBlur
        options={suggestions.data.map(suggestion => suggestion.description)}
        value={field.value.formattedAddress || ""}
        fullWidth
        disabled={geoLoading}
        renderInput={params => (
          <TextField
            {...params}
            {...field}
            error={displayError}
            fullWidth
            disabled={geoLoading}
            data-testid="input-text-address"
            onChange={handleInputChange}
            label={
              displayError
                ? String(meta.error)
                : required
                ? `${placeholder}*`
                : placeholder
            }
            margin="normal"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  <URLIcon
                    icon={MapIcon}
                    url={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                      field.value?.formattedAddress
                    )}`}
                    disabled={!field.value?.formattedAddress}
                  />
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
      <div className={classes.openContainer}>
        <HCButton
          type="button"
          variant="contained"
          color="primary"
          fullWidth={true}
          onClick={() => setMapOpen(!mapOpen)}
        >
          {mapOpen ? (
            <>
              CLOSE MAP
              <ArrowDropUpIcon />
            </>
          ) : (
            <>
              OPEN MAP
              <ArrowDropDownIcon />
            </>
          )}
        </HCButton>
      </div>

      {isScriptLoadSucceed && (
        <EmbedMap
          onClick={handleMapClick}
          onError={handleMapClickError}
          onValueChange={handleValueChange}
          fieldName={name}
          value={field.value}
          open={mapOpen}
        />
      )}
    </div>
  );
}

AddressField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool
};

export default scriptLoader(
  `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places,geocoder&callback=initMap`
)(AddressField);
